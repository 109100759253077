import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
    Anchor,
    Box,
    Button,
    DataTable,
    Heading,
    Menu,
    Notification,
    Page,
    PageContent,
    ResponsiveContext,
    Spinner,
    Text,
    ThemeContext,
    Tip,
} from "grommet";

import {
    Alert,
    CircleAlert,
    CircleInformation,
    FormAdd,
    Link as LinkIcon,
    More,
} from "grommet-icons";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";
import minMax from "dayjs/plugin/minMax";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { gql, useMutation, useQuery } from "@apollo/client";

import Modal from "./components/Modal";
import ErrorBox from "./components/ErrorBox";
import NotFound from "./components/NotFound";
import InfoBox from "./components/InfoBox";
import { useAuth } from "./components/auth/AuthProvider";
import GET_USAGE from "./graphql/getUsageQuery";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);

const GET_DOCUMENTS = gql`
    query GetDocuments($conceptSpaceId: ID!) {
        conceptSpaces(where: { id: $conceptSpaceId }) {
            documentsConnection(sort: [{ edge: { updated: DESC } }]) {
                edges {
                    status
                    error
                    errorText
                    updated
                    node {
                        path
                        title
                        date
                        wordCount
                        parsedWordCount
                        pageCount
                        parsedPageCount
                    }
                }
            }
        }
    }
`;

const REMOVE_DOCUMENT = gql`
    mutation RemoveDocument($conceptSpaceId: ID!, $name: String!) {
        removeDocument(conceptSpaceId: $conceptSpaceId, name: $name)
    }
`;

// function getPathFromUri(uri) {
//     return decodeURIComponent(uri.slice(5).split("/").slice(2).join("/"));
// }

function getAggregateStatus(documents) {
    const counts = documents.reduce((acc, { status }) => {
        if (!acc[status]) {
            acc[status] = 0;
        }
        acc[status] += 1;
        return acc;
    }, {});
    return counts;
}

function QuotaWarning({ wordCount, pageCount }) {
    const message = wordCount ? (
        <Text size="xsmall">
            <strong>Document exceeds word count limit</strong>
            <br />
            Only the first {wordCount.toLocaleString()} words have been
            processed
        </Text>
    ) : (
        <Text size="xsmall">
            <strong>Document exceeds page limit</strong>
            <br />
            Only the first {pageCount.toLocaleString()} pages have been
            processed
        </Text>
    );

    return (
        <Tip
            content={<Box flex="grow">{message}</Box>}
            dropProps={{
                width: {
                    max: "large",
                },
                background: "white",
                pad: "small",
                border: true,
                round: "xsmall",
                elevation: "small",
            }}
            plain
        >
            <Alert size="16px" />
        </Tip>
    );
}

function Documents() {
    const history = useHistory();
    const { conceptSpaceId } = useParams();

    const [quota, setQuota] = useState();
    const [quotaAlert, setQuotaAlert] = useState();

    const { plan, getQuota } = useAuth();

    const [showDeleteDialog, setShowDeleteDialog] = useState(null);

    const size = React.useContext(ResponsiveContext);

    useEffect(() => {
        getQuota().then(setQuota);
    }, [plan]);

    const { loading, error, data } = useQuery(GET_DOCUMENTS, {
        variables: { conceptSpaceId },
        pollInterval: 10000,
    });

    const [removeDocument, { loading: removeDocumentLoading }] = useMutation(
        REMOVE_DOCUMENT,
        {
            refetchQueries: [
                GET_DOCUMENTS,
                "GetAllBookmarks",
                "GetRecentBookmarks",
            ],
        }
    );

    if (data && data.conceptSpaces.length === 0) return <NotFound />;

    const {
        conceptSpaces: [{ documentsConnection: { edges: documents } = {} }] = [
            {},
        ],
    } = data || {};

    const highQueueingTimeThreshold = dayjs().subtract(30, "seconds");

    const { data: usage } = useQuery(GET_USAGE, {
        variables: {
            where: { updated_GT: quota?.usagePeriod[0] },
        },
        skip: !quota && !quota?.usagePeriod,
    });

    useEffect(() => {
        if (quota && usage && documents) {
            const wordsProcessed =
                (usage?.notTruncated?.wordCount?.sum || 0) +
                (usage?.truncated?.wordCount?.sum || 0);
            if (documents.length >= quota?.documentsPerProject) {
                setQuotaAlert({
                    name: "documents per project",
                    status: "critical",
                });
            } else if (
                quota.wordsPerMonth &&
                wordsProcessed > quota.wordsPerMonth
            ) {
                setQuotaAlert({
                    name: "processing",
                    status: "critical",
                });
            } else if (
                quota.wordsPerMonth &&
                wordsProcessed > quota.wordsPerMonth * 0.8
            ) {
                setQuotaAlert({
                    name: "processing",
                    status: "warning",
                });
            }
        }
    }, [quota, usage, documents]);

    return (
        <Page kind="wide" flex="grow">
            {quotaAlert && (
                <ThemeContext.Extend
                    value={{
                        notification: {
                            textContainer: {
                                fill: "horizontal",
                                pad: { top: "xsmall" },
                                margin: { top: "2px" },
                            },
                            iconContainer: { pad: { vertical: "xsmall" } },
                            critical: {
                                icon: CircleAlert,
                            },
                        },
                    }}
                >
                    <Notification
                        global
                        message={
                            <Box justify="between" direction="row">
                                <Text>
                                    {quotaAlert.message || (
                                        <Text>
                                            {quotaAlert.status === "critical"
                                                ? "You've reached "
                                                : "You're nearing "}
                                            your plan&apos;s limit for{" "}
                                            <b>{quotaAlert.name}</b>
                                        </Text>
                                    )}
                                    <Text>
                                        . Upgrade now to keep exploring with
                                        Archer.
                                    </Text>
                                </Text>
                                <Anchor
                                    label="Manage account"
                                    onClick={() => history.push("/account")}
                                />
                            </Box>
                        }
                        status={quotaAlert.status}
                    />
                </ThemeContext.Extend>
            )}
            <PageContent margin={{ top: "medium", bottom: "medium" }}>
                <Heading
                    level={2}
                    size={size}
                    margin={{ top: "large", bottom: "small" }}
                    pad="none"
                >
                    Manage documents
                </Heading>
                <Box width={{ max: "xlarge" }}>
                    <Text>
                        Use Archer’s integrated search to find articles and
                        other online content or you can add your own links and
                        upload PDFs and text files
                    </Text>
                    {quota?.documentsPerProject && (
                        <Text size="small">
                            (max {quota.documentsPerProject} documents on your
                            current plan)
                        </Text>
                    )}
                </Box>
            </PageContent>
            <PageContent>
                <Box
                    margin={{ vertical: "medium" }}
                    pad={{ bottom: "small" }}
                    gap="large"
                >
                    <Box direction="row">
                        <Button
                            icon={<FormAdd />}
                            label="Add documents"
                            onClick={() => history.push("import")}
                        />
                    </Box>
                </Box>

                {showDeleteDialog && (
                    <Modal
                        onClickOutside={() => setShowDeleteDialog(null)}
                        onEsc={() => setShowDeleteDialog(null)}
                    >
                        <Box
                            pad={{
                                top: "large",
                                bottom: "medium",
                                horizontal: "large",
                            }}
                        >
                            <Text>Delete this document and update themes?</Text>
                            <Box
                                direction="row"
                                justify="between"
                                margin={{ top: "medium" }}
                                fill
                                gap="large"
                            >
                                <Button
                                    secondary
                                    label="Cancel"
                                    onClick={() => setShowDeleteDialog(null)}
                                />
                                <Button
                                    label="Delete"
                                    onClick={async () => {
                                        removeDocument({
                                            variables: {
                                                conceptSpaceId,
                                                name: showDeleteDialog,
                                            },
                                        });
                                        setShowDeleteDialog(null);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Modal>
                )}
            </PageContent>
            {documents &&
                !documents.every(({ status }) =>
                    ["READY", "FAILED"].includes(status)
                ) &&
                !documents.find(({ status }) =>
                    ["PROCESSING", "DELETING"].includes(status)
                ) &&
                documents
                    .filter(({ status }) => status === "QUEUED")
                    .every(({ updated }) =>
                        dayjs(updated).isBefore(highQueueingTimeThreshold)
                    ) && (
                    <PageContent margin={{ bottom: "large" }}>
                        <InfoBox
                            title="High queuing times?"
                            text="Archer is currently experiencing high usage, so processing might take slightly longer."
                            action={null}
                        />
                    </PageContent>
                )}
            <PageContent>
                {(loading || removeDocumentLoading) && (
                    <Spinner size="small" alignSelf="center" />
                )}
                {error && <ErrorBox error={error} />}
                {documents &&
                    (documents.length > 0 ? (
                        <Box
                            // width={{ max: "xlarge" }}
                            margin={{
                                bottom: "large",
                                // left: "xlarge",
                                // right: "xlarge",
                            }}
                        >
                            <DataTable
                                paginate
                                step={12}
                                border="bottom"
                                fill
                                sortable
                                pad={{
                                    footer: {
                                        horizontal: "small",
                                        top: "medium",
                                        bottom: "small",
                                    },
                                }}
                                columns={[
                                    {
                                        property: "label",
                                        header: "Document",
                                        primary: true,
                                        search: true,
                                        footer: (
                                            <Text size="small">
                                                Total documents:{" "}
                                                {documents.length}
                                            </Text>
                                        ),
                                        render: ({
                                            title,
                                            path,
                                            date,
                                            wordCount,
                                            parsedWordCount,
                                            pageCount,
                                            parsedPageCount,
                                        }) => {
                                            return (
                                                <Box
                                                    gap="xxsmall"
                                                    onClick={() =>
                                                        history.push(
                                                            `/${conceptSpaceId}/docs/${path}`
                                                        )
                                                    }
                                                >
                                                    <Text wordBreak="break-word">
                                                        {title || path}
                                                    </Text>
                                                    <Box
                                                        direction="row"
                                                        align="center"
                                                        gap="xsmall"
                                                    >
                                                        {title && (
                                                            <LinkIcon size="10px" />
                                                        )}
                                                        <Text
                                                            size="xsmall"
                                                            color="secondaryText"
                                                            wordBreak="break-word"
                                                        >
                                                            {title ? path : ""}{" "}
                                                            {date
                                                                ? ` ▫ ${dayjs(
                                                                      date
                                                                  ).format(
                                                                      "D MMM YYYY"
                                                                  )}`
                                                                : ""}
                                                            {pageCount
                                                                ? ` ▫ ${pageCount} pages`
                                                                : ""}
                                                            {wordCount
                                                                ? ` ▫ ${wordCount.toLocaleString()} words`
                                                                : ""}
                                                        </Text>
                                                        {(parsedWordCount ||
                                                            parsedPageCount) && (
                                                            <QuotaWarning
                                                                wordCount={
                                                                    parsedWordCount
                                                                }
                                                                pageCount={
                                                                    parsedPageCount
                                                                }
                                                            />
                                                        )}
                                                    </Box>
                                                </Box>
                                            );
                                        },
                                    },
                                    {
                                        property: "status",
                                        header: "Status",
                                        footer: (
                                            <Text size="small">
                                                {Object.entries(
                                                    getAggregateStatus(
                                                        documents
                                                    )
                                                ).map(([k, v]) => (
                                                    <li
                                                        key={k}
                                                    >{`${k}: ${v}`}</li>
                                                ))}
                                            </Text>
                                        ),
                                        render: ({
                                            status,
                                            documentError,
                                            errorText,
                                        }) => {
                                            if (documentError) {
                                                const internalError = ![
                                                    "TimeoutError",
                                                    "ContentError",
                                                    "ScrapingError",
                                                    "QuotaError",
                                                ].includes(documentError);

                                                const message =
                                                    internalError ? (
                                                        <span>
                                                            <b>
                                                                Something went
                                                                wrong
                                                            </b>
                                                            <br />
                                                            Use the feedback
                                                            button on the right
                                                            hand side to report
                                                            the issue.
                                                        </span>
                                                    ) : (
                                                        errorText ||
                                                        `Document processing failed with ${documentError}`
                                                    );
                                                return (
                                                    <Tip
                                                        content={
                                                            <Text size="xsmall">
                                                                {message}
                                                            </Text>
                                                        }
                                                        dropProps={{
                                                            width: {
                                                                max: "large",
                                                            },
                                                            background: "white",
                                                            pad: "small",
                                                            border: true,
                                                            round: "xsmall",
                                                            elevation: "small",
                                                        }}
                                                        plain
                                                    >
                                                        <Box>
                                                            <Text>
                                                                {status}
                                                            </Text>
                                                            <Box
                                                                direction="row"
                                                                align="center"
                                                                gap="xsmall"
                                                            >
                                                                <Text size="xsmall">
                                                                    {internalError
                                                                        ? "ProcessingError"
                                                                        : documentError}{" "}
                                                                </Text>
                                                                <CircleInformation
                                                                    size="small"
                                                                    alignSelf="end"
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Tip>
                                                );
                                            }
                                            return status;
                                        },
                                    },
                                    {
                                        property: "updated",
                                        header: "Updated",
                                        footer: (
                                            <Text size="small">
                                                Last updated:{"\n"}
                                                {dayjs
                                                    .max(
                                                        documents.map(
                                                            ({ updated }) =>
                                                                dayjs(updated)
                                                        )
                                                    )
                                                    .format("lll")}
                                            </Text>
                                        ),
                                        render: ({ updated }) => {
                                            const ts = dayjs(updated);
                                            if (ts.isToday()) {
                                                return ts.fromNow();
                                                // return dayjs
                                                //     .duration(ts.diff(dayjs()))
                                                //     .humanize(true);
                                            }
                                            return ts.format("lll");
                                        },
                                    },
                                    {
                                        sortable: false,
                                        render: ({ path, status }) =>
                                            ["READY", "FAILED"].includes(
                                                status
                                            ) ? (
                                                <Menu
                                                    plain
                                                    key={path}
                                                    icon={<More />}
                                                    hoverIndicator
                                                    size="small"
                                                    items={[
                                                        {
                                                            reverse: true,
                                                            label: (
                                                                <Text size="small">
                                                                    View
                                                                </Text>
                                                            ),

                                                            onClick: () =>
                                                                history.push(
                                                                    `/${conceptSpaceId}/docs/${path}`
                                                                ),
                                                        },
                                                        {
                                                            reverse: true,
                                                            label: (
                                                                <Text size="small">
                                                                    Delete
                                                                </Text>
                                                            ),
                                                            onClick: () => {
                                                                setShowDeleteDialog(
                                                                    path
                                                                );
                                                            },
                                                        },
                                                    ]}
                                                />
                                            ) : null,
                                    },
                                ]}
                                data={documents.map(
                                    ({
                                        status,
                                        updated,
                                        error: documentError,
                                        errorText,
                                        node: {
                                            title,
                                            path,
                                            date,
                                            wordCount,
                                            parsedWordCount,
                                            pageCount,
                                            parsedPageCount,
                                        },
                                    }) => ({
                                        title,
                                        path,
                                        date,
                                        wordCount,
                                        parsedWordCount,
                                        pageCount,
                                        parsedPageCount,
                                        label: `${title} ${path}`,
                                        status,
                                        documentError,
                                        errorText,
                                        updated,
                                    })
                                )}
                                onClickRow={() => {}}
                            />
                        </Box>
                    ) : (
                        // </Box>
                        !loading && (
                            <InfoBox
                                title="Need ideas about what documents to use?"
                                text="Generate topical summaries, discover trends and research your ideas. You can create collections of news stories, research articles and other URLs and even upload PDFs and text files."
                                actionLabel="Learn more"
                                action={() =>
                                    window.open(
                                        "https://same-judo-f78.notion.site/Teamspace-Home-084c04136b934873b6cea96702621473",
                                        "_blank"
                                    )
                                }
                            />
                        )
                    ))}
            </PageContent>
        </Page>
    );
}

export default Documents;
