import React from "react";
import {
    Box,
    Diagram,
    Heading,
    ResponsiveContext,
    Spinner,
    Stack,
    ThemeContext,
} from "grommet";
import { Checkmark } from "grommet-icons";

function Dot({ id, filled, checked, active, size }) {
    return (
        <Box id={id} width="xsmall" justify="center" align="center">
            <Box
                round="large"
                {...(filled || checked || active
                    ? { background: active ? "transparent" : "concept" }
                    : {
                          border: {
                              size: "3px",
                              color: "concept",
                          },
                      })}
                width={size === "small" ? "30px" : "36px"}
                height={size === "small" ? "30px" : "36px"}
                justify="center"
                align="center"
            >
                {checked && <Checkmark size="20px" color="white" />}
                {active && (
                    <ThemeContext.Extend
                        value={{
                            spinner: {
                                container: {
                                    pad: "15px",
                                    // animation: "pulse",
                                },
                            },
                        }}
                    >
                        <Spinner
                            size="small"
                            border={[
                                {
                                    side: "all",
                                    color: "backgroundDarker",
                                    size: "3px",
                                },
                                {
                                    side: "top",
                                    color: "concept",
                                    size: "3px",
                                },
                            ]}
                        />
                    </ThemeContext.Extend>
                )}
            </Box>
        </Box>
    );
}

function StepsDiagram({ steps } = {}) {
    const [step1 = {}, step2 = {}, step3 = {}] = steps || [{}, {}, {}];
    const size = React.useContext(ResponsiveContext);
    return (
        <Stack guidingChild={1}>
            <Box>
                <Box direction="row" justify="between">
                    <Box fill align="start">
                        <Box
                            width={size === "small" ? "100px" : "180px"}
                            align="center"
                        >
                            <Dot id="1" {...step1} size={size} />
                            <Heading
                                level={4}
                                size={size === "small" ? "12px" : "medium"}
                                margin={{ top: "small" }}
                                textAlign="center"
                            >
                                Describe the project
                            </Heading>
                        </Box>
                    </Box>
                    <Box fill align="center">
                        <Box
                            width={size === "small" ? "100px" : "180px"}
                            align="center"
                        >
                            <Dot id="2" {...step2} size={size} />
                            <Heading
                                level={4}
                                size={size === "small" ? "12px" : "medium"}
                                margin={{ top: "small" }}
                                textAlign="center"
                            >
                                {step2.active
                                    ? "Archer is researching"
                                    : "Archer does the research"}
                            </Heading>
                        </Box>
                    </Box>
                    <Box borer fill align="end">
                        <Box
                            width={size === "small" ? "100px" : "180px"}
                            align="center"
                        >
                            <Dot id="3" {...step3} size={size} />
                            <Heading
                                level={4}
                                size={size === "small" ? "12px" : "medium"}
                                margin={{ top: "small" }}
                                textAlign="center"
                            >
                                Get your summary, explore and ask questions
                            </Heading>
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Diagram
                connections={[
                    {
                        fromTarget: "1",
                        toTarget: "2",
                        anchor: "horizontal",
                        thickness: "xxsmall",
                        color: "concept",
                    },
                    {
                        fromTarget: "2",
                        toTarget: "3",
                        anchor: "horizontal",
                        thickness: "xxsmall",
                        color: "concept",
                    },
                ]}
            />
        </Stack>
    );
}

export default StepsDiagram;
