import React from "react";

import { Layer, ThemeContext } from "grommet";

function Modal(props) {
    return (
        <ThemeContext.Extend
            value={{
                layer: {
                    border: {
                        radius: "xsmall",
                    },
                    overlay: {
                        background: "rgba(0, 0, 0, 0.3)",
                    },
                    container: {
                        elevation: "medium",
                        extend: "outline: 1px solid darkGrey",
                    },
                },
            }}
        >
            <Layer modal position="center" responsive={false} {...props} />
        </ThemeContext.Extend>
    );
}

export default Modal;
