import React, { Component, useRef, useState } from "react"; // , { useEffect, useRef, useState }
import PropTypes from "prop-types";

import { Anchor, Box, Drop, Paragraph, Text } from "grommet";
import { Link as LinkIcon } from "grommet-icons";

import ErrorBox from "./ErrorBox";

function AnnotatedSpan({ type, id, text }) {
    const [selected, setSelected] = useState(false);
    const targetRef = useRef();
    return (
        <>
            {selected && (
                <Drop
                    target={targetRef.current}
                    elevation="large"
                    margin={{ top: "medium" }}
                    onClickOutside={() => setSelected(false)}
                >
                    <Box pad="large" background="background">
                        Drop Contents with elevation and margin
                    </Box>
                </Drop>
            )}
            <Box
                key={`${type}-${id}`}
                as="span"
                ref={targetRef}
                style={{ display: "inline" }}
                // onClick={() => setSelected(true)} // TODO: enable this for concept menu
            >
                <span
                    id={`${type}-${id}`}
                    style={{
                        ...(type.startsWith("current")
                            ? {
                                  backgroundColor: "#febbd9",
                              }
                            : {
                                  textDecoration: "underline 3px #febbd9",
                                  textUnderlineOffset: "5%",
                              }),
                    }}
                >
                    {text}
                </span>
            </Box>
        </>
    );
}

class TextDocument extends Component {
    constructor(props) {
        super(props);
        this.state = { content: null };
    }

    async componentDidMount() {
        const { url, onError, onReady } = this.props;
        if (url) {
            try {
                const res = await fetch(url);
                const contentType = res.headers.get("Content-Type");
                if (contentType === "application/json") {
                    const { url: sourceUrl, content, error } = await res.json();
                    this.setState({
                        sourceUrl,
                        content,
                        error,
                    });
                } else {
                    this.setState({
                        content: await res.text(),
                    });
                }
                onReady();
            } catch (e) {
                onError(e);
            }
        }
    }

    // eslint-disable-next-line react/no-unused-class-component-methods, class-methods-use-this
    scrollViewerTo(target) {
        const { id, type } = target;
        const targetId = `${type}-${id}`;
        const targetEl = document.getElementById(targetId);
        if (targetEl) {
            targetEl.scrollIntoView({ block: "center", inline: "nearest" });
        }
    }

    render() {
        const { ref, layout, highlights } = this.props;
        const { sourceUrl, content, error } = this.state;

        const passageHighlights = highlights.reduce((acc, passage) => {
            const { id, type } = passage;
            if (["passage", "currentPassage"].includes(type)) {
                acc[id] = passage;
            }
            return acc;
        }, {});

        const conceptHighlights = highlights.reduce((acc, passage) => {
            const { type } = passage;
            if (["concept", "currentConcept"].includes(type)) {
                acc.push(passage);
            }
            return acc;
        }, []);

        let passages;
        if (content) {
            // need unicode aware string slicing here https://stackoverflow.com/questions/62341685/javascript-unicode-aware-string-slice
            const chars = [...content];
            if (layout && layout.length > 0) {
                passages = [];
                // for (let p = 0; p < layout.length; p += 1) {
                Object.keys(layout).forEach((passageId) => {
                    const p = parseInt(passageId, 10);
                    const passageLayout = layout[p];
                    let offset = Object.values(passageLayout)[0][0];
                    const sentences = passageLayout.map(([start, end], s) => {
                        const segments = [];

                        if (start > offset) {
                            segments.push(chars.slice(offset, start).join(""));
                        }
                        offset = end;

                        const sentenceChars = chars.slice(start, end);
                        const annotations = conceptHighlights.filter(
                            ({ position: [passage, sentence] }) =>
                                passage === p && sentence === s
                        );
                        if (annotations.length > 0) {
                            let pos = 0;
                            for (let i = 0; i < annotations.length; i += 1) {
                                const {
                                    id,
                                    type,
                                    position: [, , charStart, charEnd],
                                } = annotations[i];
                                if (charEnd !== pos) {
                                    segments.push(
                                        sentenceChars
                                            .slice(pos, charStart)
                                            .join("")
                                    );
                                    segments.push(
                                        <AnnotatedSpan
                                            type={type}
                                            id={id}
                                            text={sentenceChars
                                                .slice(charStart, charEnd)
                                                .join("")}
                                        />
                                    );
                                    pos = charEnd;
                                }
                            }
                            segments.push(sentenceChars.slice(pos).join(""));
                        } else {
                            segments.push(sentenceChars.join(""));
                        }
                        return segments;
                    });
                    passages.push({ id: p, content: sentences });
                });
            } else {
                passages = [{ id: 0, content: [content] }];
            }
        }

        return (
            <Box
                width={{ min: "medium" }}
                margin={{ horizontal: "auto", bottom: "xlarge" }}
                flex="grow"
                ref={ref}
            >
                {sourceUrl && (
                    <Box flex="grow" margin={{ bottom: "medium" }} gap="medium">
                        <Box direction="row" gap="small">
                            <LinkIcon />
                            <Anchor
                                href={sourceUrl}
                                target="_blank"
                                style={{ wordBreak: "break-all" }}
                                size="small"
                                // weight="normal"
                            >
                                {sourceUrl}
                            </Anchor>
                        </Box>
                        {error && <ErrorBox error={error} canRetry={false} />}
                        {!content && (
                            <Box>
                                <Text>Document content not yet available.</Text>
                            </Box>
                        )}
                    </Box>
                )}
                {content && (
                    <Box
                        background="white"
                        elevation="medium"
                        pad="large"
                        flex="grow"
                        width={{ max: "xxlarge" }}
                        direction="row"
                        overflow={{ horizontal: "hidden" }}
                    >
                        <Box flex="grow">
                            {passages.map(
                                ({ id: p, content: passageContent }) => (
                                    <Box key={p} id={`passage-${p}`}>
                                        <Paragraph
                                            fill
                                            style={{
                                                ...((passageHighlights[p] || {})
                                                    .type ===
                                                    "currentPassage" && {
                                                    boxShadow:
                                                        "-10px 0 0 0 white, -14px 0 0 0 rgb(157, 203, 251)",
                                                }),
                                                ...(Object.keys(
                                                    passageHighlights
                                                ).length > 1 &&
                                                    passageHighlights[p] && {
                                                        background:
                                                            "rgb(218, 234, 251)",
                                                    }),
                                            }}
                                        >
                                            {passageContent.map(
                                                (sentence, s) => (
                                                    <Box key={[p, s]}>
                                                        <Text
                                                            id={`passage-${p}/${s}`}
                                                            style={{
                                                                // border: `2px solid rgb(157, 203, 251)`,
                                                                borderLeft:
                                                                    "none",
                                                                whiteSpace:
                                                                    "pre-line",
                                                                ...((
                                                                    passageHighlights[
                                                                        `${p}.${s}`
                                                                    ] || {}
                                                                ).type ===
                                                                    "currentPassage" && {
                                                                    boxShadow:
                                                                        "-10px 0 0 0 white, -14px 0 0 0 rgb(157, 203, 251)",
                                                                }),
                                                            }}
                                                        >
                                                            {sentence}
                                                        </Text>{" "}
                                                    </Box>
                                                )
                                            )}
                                        </Paragraph>
                                    </Box>
                                )
                            )}
                            <Paragraph fill>
                                <Text
                                    style={{
                                        whiteSpace: "pre-line",
                                    }}
                                >
                                    {content.slice(30890)}
                                </Text>
                            </Paragraph>
                        </Box>
                    </Box>
                )}
            </Box>
        );
    }
}

TextDocument.propTypes = {
    url: PropTypes.string.isRequired,
    layout: PropTypes.arrayOf(PropTypes.objectOf()),
    highlights: PropTypes.arrayOf(PropTypes.objectOf()),
    onError: PropTypes.func,
    onReady: PropTypes.func,
    // onSelection: PropTypes.func,
};

TextDocument.defaultProps = {
    layout: [],
    highlights: [],
    onError: () => {},
    onReady: () => {},
    // onSelection: () => {},
};

export default TextDocument;
