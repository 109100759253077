import React, { useEffect, useRef, useState } from "react";
import {
    Anchor,
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Heading,
    List,
    Page,
    PageContent,
    Spinner,
    Text,
    // TextArea,
    TextInput,
} from "grommet";

import { Add, Descending, Radial, StatusGood, Trash } from "grommet-icons";

import { getApp } from "firebase/app";
import { arrayUnion, doc, getFirestore, updateDoc } from "firebase/firestore";

import { gql, useLazyQuery, useMutation } from "@apollo/client";
import { useHistory } from "react-router-dom";
import { useAuth } from "./components/auth/AuthProvider";
import ErrorBox from "./components/ErrorBox";
import ExpandingTextArea from "./components/ExpandingTextArea";
import StepsDiagram from "./components/StepsDiagram";

const CREATE_CONCEPT_SPACE = gql`
    mutation CreateConceptSpace($input: [ConceptSpaceCreateInput!]!) {
        createConceptSpaces(input: $input) {
            conceptSpaces {
                id
                name
                objective
            }
        }
    }
`;

const GENERATE_RESEARCH_PLAN = gql`
    query GenerateResearchPlan($topic: String!) {
        generateResearchPlan(topic: $topic) {
            topic
            name
            summary
            researchAreas {
                name
                searchQuery
                description
            }
        }
    }
`;

const GENERATE_RESEARCH_AREA = gql`
    query GenerateResearchArea($plan: ResearchPlanInput!, $suggestion: String) {
        generateResearchArea(plan: $plan, suggestion: $suggestion) {
            name
            description
            searchQuery
        }
    }
`;

const SOURCE_OWN = 1;
const SOURCE_ONLINE = 2;

function RadioCard({ label, description, selected, ...props }) {
    return (
        <Card fill {...props} focusIndicator={false}>
            <Box
                direction="row"
                justify="between"
                align="center"
                margin={{ vertical: "small", horizontal: "medium" }}
            >
                <Box>
                    <Heading
                        level={4}
                        margin={{ top: "none", bottom: "xsmall" }}
                    >
                        {label}
                    </Heading>
                    <Text size="small">{description}</Text>
                </Box>
                <Button
                    plain
                    icon={
                        selected ? (
                            <StatusGood size="medium" color="status-ok" />
                        ) : (
                            <Radial color="light-4" />
                        )
                    }
                />
            </Box>
        </Card>
    );
}

function NewProject() {
    const history = useHistory();

    const [step, setStep] = useState(0);
    const [topic, setTopic] = useState();
    const [objective, setObjective] = useState();
    const [source, setSource] = useState();
    const [researchAreas, setResearchAreas] = useState();
    const [newAreaSuggestion, setNewAreaSuggestion] = useState();

    const endRef = useRef();

    const { user } = useAuth();
    const firestore = getFirestore(getApp());

    const [
        generateResearchPlan,
        {
            loading: generating,
            error: generationError,
            data: { generateResearchPlan: researchPlan } = {},
        },
    ] = useLazyQuery(GENERATE_RESEARCH_PLAN);

    const [generateResearchArea, { loading: generatingArea }] = useLazyQuery(
        GENERATE_RESEARCH_AREA
    );

    const [createConceptSpace, { loading: creating, error: creationError }] =
        useMutation(CREATE_CONCEPT_SPACE, {
            variables: {
                input: [
                    {
                        name:
                            source === SOURCE_OWN
                                ? topic
                                : researchPlan && researchPlan.name,
                        description: source === SOURCE_OWN ? null : topic,
                        objective,
                        status: source === SOURCE_ONLINE ? "UPDATING" : null,
                        allowedUsers: {
                            connectOrCreate: [
                                {
                                    where: {
                                        node: {
                                            email: user.email,
                                        },
                                    },
                                    onCreate: {
                                        node: {
                                            email: user.email,
                                        },
                                    },
                                },
                            ],
                        },
                        ...(SOURCE_ONLINE &&
                            researchAreas && {
                                researchQueries: {
                                    create: researchAreas.map(
                                        ({
                                            name: question,
                                            description,
                                            searchQuery: searchTerm,
                                        }) => ({
                                            node: {
                                                question,
                                                description,
                                                searchTerm,
                                            },
                                        })
                                    ),
                                },
                            }),
                    },
                ],
            },
            refetchQueries: ["getConceptSpaces", "getConceptSpaceStatus"],
            onCompleted: ({
                createConceptSpaces: { conceptSpaces: [{ id }] } = [{}],
            }) => {
                history.push(
                    `${id}${source === SOURCE_OWN ? "/import" : "/log"}`,
                    {
                        researchPlan,
                    }
                );
                updateDoc(doc(firestore, "users", user.uid), {
                    recentProjects: arrayUnion(id),
                });
            },
        });

    useEffect(() => {
        if (researchAreas) {
            setResearchAreas(undefined);
        }
    }, [topic]);

    // go back if options changed
    useEffect(() => {
        if (step === 0) {
            if (source === SOURCE_ONLINE && researchAreas) {
                // show the cached research plan
                setStep(3);
            }
        } else {
            setStep(0);
        }
    }, [topic, source]);

    // update research area state when a new plan is generated
    useEffect(() => {
        if (researchPlan) {
            setResearchAreas(researchPlan.researchAreas);
            setStep(3);
        }
    }, [researchPlan]);

    useEffect(() => {
        if (endRef.current) {
            endRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [step]);

    if (creating) {
        return (
            <Page
                kind="narrow"
                gap="medium"
                flex="grow"
                pad={{ bottom: "large" }}
                fill
                align="center"
            >
                <Box fill justify="center" align="center" gap="small">
                    <Spinner size="small" />
                    <Text size="small">Setting up the project</Text>
                </Box>
            </Page>
        );
    }

    return (
        <Page
            kind="narrow"
            gap="medium"
            flex={{ vertical: "grow" }}
            pad={{ bottom: "large" }}
        >
            {creationError && (
                <ErrorBox
                    error={creationError}
                    retry={createConceptSpace}
                    canGoBack={false}
                />
            )}
            <PageContent margin={{ top: "large" }}>
                <Heading level={1}>Start a new project</Heading>
                <Box margin={{ top: "small" }} animation="fadeIn">
                    <StepsDiagram steps={[{ filled: true }]} />
                </Box>
            </PageContent>
            <PageContent
                animation={{ type: "fadeIn", delay: 200, duration: 200 }}
            >
                <Heading level={2} size="small">
                    What are you looking into?
                </Heading>
                <Card pad="small">
                    <ExpandingTextArea
                        plain
                        focusIndicator={false}
                        resize={false}
                        style={{ background: "white", maxHeight: "200px" }}
                        placeholder="e.g. Latest trends in..."
                        value={topic}
                        onChange={({ target: { value } }) =>
                            !generating && setTopic(value)
                        }
                    />
                </Card>
                <Box margin={{ top: "small", left: "small", bottom: "medium" }}>
                    <Text size="small">
                        Need some inspiration?
                        <br />
                        Try{" "}
                        <Anchor
                            onClick={() => {
                                setTopic("The future of sustainable fashion");
                                setSource(SOURCE_ONLINE);
                            }}
                        >
                            &quot;The future of sustainable fashion&quot;
                        </Anchor>{" "}
                        or{" "}
                        <Anchor
                            onClick={() => {
                                setTopic("How humans inherit memories");
                                setSource(SOURCE_ONLINE);
                            }}
                        >
                            &quot;How humans inherit memories&quot;
                        </Anchor>
                    </Text>
                </Box>
                <Heading level={2} size="small">
                    Whats the aim?
                </Heading>
                <Card pad="small">
                    <ExpandingTextArea
                        plain
                        focusIndicator={false}
                        resize={false}
                        style={{ background: "white", maxHeight: "200px" }}
                        placeholder="e.g. I'm exploring an idea..."
                        value={objective}
                        onChange={({ target: { value } }) =>
                            !generating && setObjective(value)
                        }
                    />
                </Card>
                <Box margin={{ top: "small", left: "small", bottom: "medium" }}>
                    <Text size="small">
                        <Anchor
                            onClick={() =>
                                setObjective("Need to discover insights")
                            }
                        >
                            &quot;Need to discover insights&quot;
                        </Anchor>{" "}
                        ,{" "}
                        <Anchor
                            onClick={() => setObjective("Creating a report")}
                        >
                            &quot;Creating a report&quot;
                        </Anchor>
                        ,{" "}
                        <Anchor onClick={() => setObjective("Just curious")}>
                            &quot;Just curious&quot;
                        </Anchor>
                    </Text>
                </Box>
            </PageContent>
            <PageContent
                animation={{ type: "fadeIn", delay: 200, duration: 200 }}
            >
                <Heading level={2} size="small">
                    Choose your sources
                </Heading>
                <Box
                    direction="row"
                    fill
                    gap="large"
                    justify="between"
                    align="center"
                >
                    <RadioCard
                        label="Own documents"
                        description="Upload your own PDFs or text files"
                        selected={source === SOURCE_OWN}
                        onClick={
                            generating ? null : () => setSource(SOURCE_OWN)
                        }
                    />
                    <RadioCard
                        disabled={generating}
                        label="Online sources"
                        description="Archer will find relevant content"
                        selected={source === SOURCE_ONLINE}
                        onClick={() => setSource(SOURCE_ONLINE)}
                    />
                </Box>
            </PageContent>
            {step === 0 && source === SOURCE_ONLINE && (
                <PageContent>
                    <Box
                        direction="row"
                        margin={{ top: "small" }}
                        justify="end"
                    >
                        <Button
                            reverse
                            label="Next"
                            icon={<Descending />}
                            disabled={!topic || source === undefined}
                            onClick={async () => {
                                if (source === SOURCE_OWN) {
                                    setStep(1);
                                } else {
                                    setStep(2);
                                    await generateResearchPlan({
                                        variables: { topic },
                                    });
                                }
                            }}
                        />
                    </Box>
                </PageContent>
            )}
            {step === 0 && source === SOURCE_OWN && (
                <PageContent>
                    <Heading level={2} size="small">
                        Next up, Archer will:
                    </Heading>
                    <Box>
                        <ul>
                            <li>
                                <Text>Upload and read your documents</Text>
                            </li>
                            <li>
                                <Text>Analyse and discover themes</Text>
                            </li>
                            <li>
                                <Text>Generate summaries and highlights</Text>
                            </li>
                            <li>
                                <Text>
                                    Prepare your project for exploration and Q&A
                                </Text>
                            </li>
                        </ul>
                    </Box>
                    <Box direction="row" justify="end" align="center">
                        <Box>
                            <Button
                                primary
                                label="Add documents"
                                reverse
                                flex="grow"
                                onClick={() => createConceptSpace()}
                                disabled={!topic || !source}
                            />
                        </Box>
                    </Box>
                </PageContent>
            )}
            {step === 2 && (
                <PageContent
                    margin={{ top: "medium" }}
                    animation={{
                        type: "fadeIn",
                        duration: 200,
                    }}
                    gap="none"
                >
                    <Box
                        animation={{
                            type: "fadeIn",
                            delay: 400,
                            duration: 200,
                        }}
                    >
                        <Heading level={2} size="small">
                            Archer is generating a plan with three recommended
                            research areas...
                        </Heading>
                        <Text size="small">
                            You&apos;ll be able to keep all of them, delete any
                            or add your own.
                        </Text>
                        {generating && (
                            <Box margin={{ top: "medium" }}>
                                <Spinner size="small" />
                            </Box>
                        )}
                    </Box>
                    {!generating && generationError && (
                        <Box
                            animation={{
                                type: "fadeIn",
                                delay: 400,
                                duration: 200,
                            }}
                            gap="small"
                            margin={{ top: "medium" }}
                        >
                            <Heading level={2} size="small">
                                Something went wrong with that.{" "}
                                <Button
                                    label="Try again"
                                    onClick={() => {
                                        generateResearchPlan({
                                            variables: { topic },
                                        });
                                    }}
                                />
                            </Heading>
                            <Text size="small">
                                If the issue persists, try going back up and
                                rewording or simplyfying your research
                                description.
                            </Text>
                        </Box>
                    )}
                </PageContent>
            )}
            {step > 2 && (
                <PageContent
                    margin={{ top: "medium" }}
                    animation={{ type: "fadeIn", delay: 400, duration: 200 }}
                >
                    <Heading level={2} size="small">
                        Archer will research these areas (but you can delete/add
                        too)
                    </Heading>
                    <Card pad="medium" gap="small">
                        <CardHeader margin="small">
                            <Box>
                                <Heading level={6} size="small" margin="none">
                                    Your project
                                </Heading>
                                <Heading
                                    margin={{ vertical: "xsmall" }}
                                    style={{ lineHeight: "1.2em" }}
                                >
                                    {researchPlan.name}
                                </Heading>
                            </Box>
                        </CardHeader>
                        <CardBody>
                            <Box
                                direction="row"
                                justify="between"
                                align="end"
                                pad={{ bottom: "xsmall" }}
                                // border={{ side: "bottom" }}
                            >
                                <Heading level={6} size="small" margin="none">
                                    Research areas
                                </Heading>
                                <Text size="small">
                                    Keep all, delete or add your own
                                </Text>
                            </Box>
                            <List
                                primaryKey="name"
                                // eslint-disable-next-line react/no-unstable-nested-components
                                action={(item, index) => (
                                    <Button
                                        plain
                                        icon={<Trash size="small" />}
                                        onClick={() =>
                                            setResearchAreas([
                                                ...researchAreas.slice(
                                                    0,
                                                    index
                                                ),
                                                ...researchAreas.slice(
                                                    index + 1
                                                ),
                                            ])
                                        }
                                    />
                                )}
                                data={researchAreas}
                                defaultItemProps={{
                                    pad: {
                                        left: "none",
                                        right: "xsmall",
                                        vertical: "medium",
                                    },
                                }}
                            >
                                {({ name, description }, i) => (
                                    <Box gap="xsmall">
                                        {i < researchAreas.length && name ? (
                                            <Text weight="bold">{name}</Text>
                                        ) : (
                                            <Box direction="row" width="large">
                                                <TextInput
                                                    maxLength={500}
                                                    weight="bold"
                                                    autoFocus
                                                    value={name}
                                                    placeholder="Enter a prompt to generate a new research area..."
                                                    onChange={({
                                                        target: { value },
                                                    }) =>
                                                        !generatingArea &&
                                                        setNewAreaSuggestion(
                                                            value
                                                        )
                                                    }
                                                />
                                            </Box>
                                        )}
                                        <Box flex="shrink" direction="row">
                                            {description && (
                                                <Text size="small">
                                                    {description}
                                                </Text>
                                            )}
                                            {!description &&
                                                i ===
                                                    researchAreas.length - 1 &&
                                                !generatingArea && (
                                                    <Button
                                                        label="Generate"
                                                        onClick={async () => {
                                                            const plan = {
                                                                name: researchPlan.name,
                                                                researchAreas:
                                                                    researchAreas
                                                                        .filter(
                                                                            ({
                                                                                name: areaName,
                                                                            }) =>
                                                                                areaName
                                                                        )
                                                                        .map(
                                                                            ({
                                                                                name: areaName,
                                                                                description:
                                                                                    areaDescription,
                                                                            }) => ({
                                                                                name: areaName,
                                                                                description:
                                                                                    areaDescription,
                                                                            })
                                                                        ),
                                                            };
                                                            const res =
                                                                await generateResearchArea(
                                                                    {
                                                                        variables:
                                                                            {
                                                                                plan,
                                                                                suggestion:
                                                                                    newAreaSuggestion ||
                                                                                    "",
                                                                            },
                                                                    }
                                                                );
                                                            const {
                                                                data: {
                                                                    generateResearchArea:
                                                                        newArea,
                                                                },
                                                            } = res;
                                                            setResearchAreas(
                                                                researchAreas.map(
                                                                    (r, j) =>
                                                                        i === j
                                                                            ? newArea
                                                                            : r
                                                                )
                                                            );
                                                        }}
                                                    />
                                                )}
                                            {!description &&
                                                i ===
                                                    researchAreas.length - 1 &&
                                                generatingArea && <Spinner />}
                                        </Box>
                                    </Box>
                                )}
                            </List>
                        </CardBody>
                        <CardFooter margin={{ top: "small" }}>
                            {researchAreas && researchAreas.length < 5 && (
                                <Button
                                    secondary
                                    label="Add an area"
                                    icon={<Add size="small" />}
                                    onClick={() => {
                                        setResearchAreas([
                                            ...researchAreas,
                                            {},
                                        ]);
                                        setNewAreaSuggestion(undefined);
                                    }}
                                />
                            )}
                        </CardFooter>
                    </Card>
                    <Box
                        direction="row"
                        margin={{ top: "large" }}
                        justify="between"
                        align="center"
                    >
                        <Box margin={{ right: "large" }}>
                            <Text size="xsmall">
                                Edit the plan if you’d like to change any of the
                                research areas before continuing.
                                <br />
                                Or go back and change the research topic to
                                start again.
                            </Text>
                        </Box>
                        <Box width={{ min: "160px" }}>
                            <Button
                                primary
                                label="Start research"
                                flex="grow"
                                disabled={
                                    !researchAreas ||
                                    researchAreas.length === 0 ||
                                    !researchAreas.reduce(
                                        (len, { name }) =>
                                            name ? len + name.length : len,
                                        0
                                    )
                                }
                                onClick={() => createConceptSpace()}
                            />
                        </Box>
                    </Box>
                </PageContent>
            )}
            <div ref={endRef} />
        </Page>
    );
}

export default NewProject;
