/* eslint-disable no-param-reassign */
import React from "react";
import PropTypes from "prop-types";

import { useParams } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import * as d3 from "d3";

import { Spinner } from "grommet";
import ErrorBox from "./ErrorBox";

const GET_THEMES = gql`
    query ThemeMap($where: ThemeWhere, $options: ThemeOptions) {
        themes(where: $where, options: $options) {
            name
            size
            importance
            distancesConnection {
                edges {
                    euclidean
                    node {
                        name
                    }
                }
            }
        }
    }
`;

function ThemeMap({ onSelectTheme }) {
    const { conceptSpaceId } = useParams();
    const svgRef = React.useRef(null);

    const { loading, error, data } = useQuery(GET_THEMES, {
        variables: {
            where: {
                conceptSpace: { id: conceptSpaceId },
                OR: [{ hidden: null }, { hidden_NOT: true }],
            },
            options: {
                sort: [
                    {
                        size: "DESC",
                    },
                ],
            },
        },
        fetchPolicy: "network-only",
    });

    const context = document.createElement("canvas").getContext("2d");
    const measureWidth = (text) => context.measureText(text).width;

    function wrap(text, targetWidth) {
        const words = text.split(/\s+/g); // To hyphenate: /\s+|(?<=-)/
        if (!words[words.length - 1]) words.pop();
        if (!words[0]) words.shift();

        let line;
        let lineWidth0 = Infinity;
        const lines = [];
        for (let i = 0, n = words.length; i < n; i += 1) {
            const lineText1 = (line ? `${line.text} ` : "") + words[i];
            const lineWidth1 = measureWidth(lineText1);
            if ((lineWidth0 + lineWidth1) / 2 < targetWidth) {
                lineWidth0 = lineWidth1;
                line.width = lineWidth0;
                line.text = lineText1;
            } else {
                lineWidth0 = measureWidth(words[i]);
                line = { width: lineWidth0, text: words[i] };
                lines.push(line);
            }
        }

        return lines;
    }

    React.useEffect(() => {
        if (!data) return;

        // Create root container where we will append all other chart elements
        const svgEl = d3.select(svgRef.current);
        if (!svgEl.node()) return;

        const width = svgEl.node().clientWidth;
        const height = svgEl.node().clientHeight;

        const { themes } = data;
        const nodeData = themes.map(({ name, size, importance }) => ({
            name,
            size,
            importance,
        }));
        const themeIds = Object.fromEntries(
            themes.map(({ name }, i) => [name, i])
        );
        const edgeData = themes.reduce(
            (prev, { name: source, distancesConnection: { edges } }) => [
                ...prev,
                ...edges
                    .filter(({ node: { name: target } }) => themeIds[target])
                    .map(({ node: { name: target }, euclidean }) => ({
                        source: themeIds[source],
                        target: themeIds[target],
                        weight: euclidean,
                    })),
            ],
            []
        );

        svgEl.selectAll("*").remove(); // Clear svg content before adding new elements

        const themeSizes = nodeData.map(({ size }) => size);
        const scaleSize = d3
            // .scaleSqrt()
            .scaleLog()
            .domain([Math.min(...themeSizes), Math.max(...themeSizes)])
            .range([25, 75]);

        const nodes = nodeData.map((d, i) => ({
            ...d,
            id: i,
            size: scaleSize(d.size),
        }));
        const edges = edgeData
            // .filter(({ source, target }) => source < target)
            .map((e) => ({ ...e }));

        //   const svg = d3.select(DOM.svg(width, height));

        svgEl.attr("opacity", "0");

        const importances = nodes.map(({ importance }) => importance);
        const color = d3
            .scaleSqrt()
            .domain([Math.min(...importances), Math.max(...importances)])
            .range(["#82C9F9", "#F2AFC3"]);

        const weights = edges.map(({ weight }) => weight);
        const distance = d3
            .scalePow()
            .exponent(1)
            .domain([Math.min(...weights), Math.max(...weights)])
            .range([0, Math.sqrt(width ** 2 + height ** 2)]);

        const node = svgEl
            .selectAll(".node")
            .data(nodes)
            .enter()
            .append("g")
            .classed("node", true)
            .style("cursor", "pointer");

        node.append("circle")
            .attr("r", (d) => d.size)
            .style("fill", (d) => color(d.importance));

        const text = node
            .append("text")
            .style("font-size", "18px")
            .style("font-weight", "800")
            .style("line-height", "20px")
            .style("font-family", "'Lato', sans-serif")
            .style("fill", "black")
            .style("opacity", 0.6)
            .style("text-anchor", "middle");

        text.selectAll("tspan")
            .data((d) => wrap(d.name, Math.max(d.size * 0.8, 40)))
            .enter()
            .append("tspan")
            .attr("x", 0)
            .attr("y", (d, i, n) => {
                return (
                    (i - n.length / 2 + 0.8) *
                    n[i].parentNode.style["line-height"].match(/\d+/)
                );
            })
            .text((line) => line.text);

        node.on("mouseenter", function () {
            const g = d3.select(this);
            if (g.select(".hover").empty()) {
                if (g.select("text").attr("transform") === "scale(0)") {
                    g.select("text")
                        .clone(true)
                        .classed("hover", true)
                        .attr("transform", "scale(0.7)");
                }
                d3.selectAll("g").each((_, i, n) => {
                    if (n[i] !== this) {
                        d3.select(n[i])
                            .attr("opacity", 1)
                            .transition()
                            .attr("opacity", 0.7);
                    }
                });
                // bring node to front
                // eslint-disable-next-line react/no-this-in-sfc
                this.parentNode.appendChild(this);
            }
        })
            .on("mouseleave", function () {
                d3.selectAll("g").each((g, i, n) => {
                    if (n[i] !== this) {
                        d3.select(n[i])
                            .transition()
                            .duration(100)
                            .attr("opacity", 1);
                    }
                });
                const g = d3.select(this);
                if (!g.select(".hover").empty()) {
                    g.select(".hover").remove();
                }
            })
            .on("click", function (_, { name }) {
                onSelectTheme(name);
            });

        text.attr("transform", (d, i, n) => {
            const textBox = n[i].getBBox();
            const textRadius = Math.sqrt(
                (textBox.width / 2) ** 2 + (textBox.height / 2) ** 2
            );
            const scale = (d.size * 0.8) / textRadius;
            return `scale(${scale > 0.6 ? scale : 0.6})`;
        });

        const sim = d3
            .forceSimulation(nodes)
            // .velocityDecay(0.5)
            // .alphaDecay(0.01)
            .force(
                "link",
                d3.forceLink(edges).distance((e) => distance(e.weight))
            )
            .force(
                "center",
                d3.forceCenter(width / 2, height / 2).strength(1.25)
            )
            .force("bounding-box", () => {
                const pad = 30;
                nodes.forEach((n) => {
                    if (n.x < n.size + pad) {
                        n.x = n.size + pad;
                    } else if (n.x > width - n.size - pad) {
                        n.x = width - n.size - pad;
                    }
                    if (n.y < n.size + pad) {
                        n.y = n.size + pad;
                    } else if (n.y > height - n.size - pad) {
                        n.y = height - n.size - pad;
                    }
                });
            })
            .force("Y", d3.forceY(height / 2).strength(0.25))
            .force(
                "collide",
                d3.forceCollide().radius((d) => d.size + 5)
            )
            .stop();

        sim.tick(300);

        node.attr("transform", (d) => `translate(${d.x},${d.y})`);

        svgEl.transition().delay(100).duration(400).attr("opacity", "1");
    }, [data, svgRef.current]);

    if (error) {
        return <ErrorBox error={error} />;
    }
    return (
        <>
            {loading && <Spinner alignSelf="center" />}
            <svg ref={svgRef} height="500" />
        </>
    );
}

ThemeMap.propTypes = {
    onSelectTheme: PropTypes.func,
};

ThemeMap.defaultProps = {
    onSelectTheme: () => {},
};

export default ThemeMap;
