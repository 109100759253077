import React, { useEffect, useRef, useState } from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";

import {
    Box,
    Button,
    CheckBox,
    Collapsible,
    Heading,
    Layer,
    ResponsiveContext,
    Spinner,
    Text,
} from "grommet";

import { CaretLeftFill, CaretRightFill, FormDown, FormUp } from "grommet-icons";

import { gql, useQuery } from "@apollo/client";

import { capitalize } from "lodash";
import useQueryString from "./utils/useQueryString";

import NotFound from "./components/NotFound";
import ConceptList from "./components/ConceptList";
import PDF from "./components/PDF";
import TextDocument from "./components/TextDocument";
import ErrorBox from "./components/ErrorBox";
import BookmarkButton from "./components/BookmarkButton";
// import BookmarkButton from "./components/BookmarkButton";

const DocConceptFragment = `
    id
    name
    entityType
    source
    mentionsConnection(where: { node: { document: $where } }) {
        totalCount
        edges {
            node {
                documentConnection {
                  edges {
                    passage
                    sentence
                  }
                }
            }
            startOffset
            endOffset
            layout
        }
    }
    children {
        id
        mentionsConnection(where: { node: { document: $where } }) {
            totalCount
            edges {
                node {
                    documentConnection {
                      edges {
                        passage
                        sentence
                      }
                    }
                }
                startOffset
                endOffset
                layout
            }
        }
    }
`;

const DOCUMENT_QUERY = gql`
    query GetDocument($where: DocumentWhere) {
        documents(where: $where) {
            title
            contentType
            uri
            url
        }
    }
`;

const ANNOTATIONS_QUERY = gql`
    query GetDocumentContents($where: DocumentWhere) {
        documents(where: $where) {
            layout
            contentsConnection(
                sort: [{ edge: { passage: ASC } }, { edge: { sentence: ASC } }]
                first: 999999
            ) {
                edges {
                    sentence
                    passage
                    layout
                }
            }
        }
    }
`;

const CONCEPTS_QUERY = gql`
    query GetDocumentContents($where: DocumentWhere) {
        org: documents(where: $where) {
            orgConcepts: concepts(where: { entityType_IN: ["ORG"] }) {
                ${DocConceptFragment}
            }
        }
        per: documents(where: $where) {
           
            personConcepts: concepts(where: { entityType_IN: ["PER", "PERSON"] }) {
                ${DocConceptFragment}
            }
        }
        loc: documents(where: $where) {
         
            locConcepts: concepts(where: { entityType_IN: ["LOC", "GPE"] }) {
                ${DocConceptFragment}
            }
        }
        misc: documents(where: $where) {
            miscConcepts: concepts(
                where: {
                    entityType_NOT_IN: [
                        "ORG"
                        "PER",
                        "PERSON"
                        "LOC"
                        "GPE"
                        "DATE"
                        "MONEY"
                        "QUANTITY"
                        "PERCENT"
                    ]
                }
            ) {
                ${DocConceptFragment}
            }
        }
    }
`;

const mapCoords = (coordString, docLayout) => {
    if (docLayout) {
        const [page, lines] = JSON.parse(coordString);
        const rects = lines.map((line) => ({
            x1: line[0],
            y1: line[1],
            x2: line[2],
            y2: line[3],
            width: docLayout[page].width,
            height: docLayout[page].height,
        }));
        return {
            boundingRect: {
                x1: Math.min(...rects.map((r) => r.x1)),
                y1: Math.min(...rects.map((r) => r.y1)),
                x2: Math.max(...rects.map((r) => r.x2)),
                y2: Math.max(...rects.map((r) => r.y2)),
                width: docLayout[page].width,
                height: docLayout[page].height,
            },
            rects,
            pageNumber: page + 1,
        };
    }

    return JSON.parse(coordString);
};

const mapConceptData = (conceptData) => {
    return conceptData
        ? conceptData.map(
              ({
                  id,
                  name,
                  entityType: type,
                  mentionsConnection: { totalCount },
                  children,
              }) => ({
                  id,
                  name,
                  type,
                  count:
                      totalCount +
                      children.reduce(
                          (
                              sum,
                              { mentionsConnection: { totalCount: childCount } }
                          ) => sum + childCount,
                          0
                      ),
              })
          )
        : null;
};

const mapTextLayout = (contentsConnection) => {
    if (!contentsConnection || !contentsConnection.edges) return null;

    const passages = contentsConnection.edges.reduce(
        (passageAcc, { passage: passageId, sentence: sentenceId, layout }) => {
            if (!layout) {
                return passageAcc;
            }
            if (passageId >= passageAcc.length) {
                // eslint-disable-next-line no-param-reassign
                passageAcc[passageId] = [];
            }
            const passage = passageAcc[passageId];
            passage[sentenceId] = JSON.parse(layout);

            return passageAcc;
        },
        []
    );

    return passages;
};

function CollapsibleSidebar({ open, children }) {
    const size = React.useContext(ResponsiveContext);

    if (size === "large") {
        return (
            <Box width={{ max: "25%" }} fill>
                {children}
            </Box>
        );
    }
    return (
        <Collapsible
            direction="horizontal"
            width={{ max: size === "small" ? "65%" : "40%" }}
            open={open}
        >
            {children}
        </Collapsible>
    );
}

function DocumentView() {
    const history = useHistory();
    const { conceptSpaceId } = useParams();
    const { state: { context, passages: contextPassages = [] } = {} } =
        useLocation();

    const size = React.useContext(ResponsiveContext);

    const [showSidebar, setShowSidebar] = useState(true);

    const [scrollToPassage, setScrollToPassage] = useState();
    const [highlightPassages, setHighlightPassages] = useState(false);
    const [selectedAnnotation, setSelectedAnnotation] = useState();
    const [selectedConcept, setSelectedConcept] = useState();
    const [currentMention, setCurrentMention] = useState();
    // eslint-disable-next-line no-unused-vars
    const [passageHighlights, setPassageHighlights] = useState([]);
    const [conceptHighlights, setConceptHighlights] = useState([]);

    const [viewerReady, setViewerReady] = useState(false);
    const [viewerError, setViewerError] = useState();

    const mainRef = useRef();
    const viewerRef = useRef();

    const { uri: path } = useParams();
    const urlQuery = useQueryString();

    const {
        loading: metadataLoading,
        error: metadataError,
        data: metadata = {},
    } = useQuery(DOCUMENT_QUERY, {
        variables: {
            where: {
                conceptSpace: { id: conceptSpaceId },
                OR: [
                    { uri_ENDS_WITH: encodeURI(path) },
                    { uri_ENDS_WITH: path },
                ],
            },
        },
        fetchPolicy: "network-only",
    });

    const { documents: [{ title, contentType, uri, url } = {}] = [{}] } =
        metadata;

    const {
        loading: annotationsLoading,
        error: annotationsError,
        data = {},
    } = useQuery(ANNOTATIONS_QUERY, {
        variables: {
            where: {
                conceptSpace: { id: conceptSpaceId },
                uri,
            },
        },
        skip: !uri,
    });

    const {
        documents: [{ layout: docLayoutString, contentsConnection } = {}] = [
            {},
        ],
    } = data;

    const {
        loading: conceptsLoading,
        error: conceptsError,
        data: conceptsData = {},
    } = useQuery(CONCEPTS_QUERY, {
        variables: {
            where: {
                conceptSpace: { id: conceptSpaceId },
                uri,
            },
        },
        skip: !uri,
    });

    const error = metadataError || annotationsError || conceptsError;

    const {
        per: [{ personConcepts = [] } = {}] = [{}],
        org: [{ orgConcepts = [] } = {}] = [{}],
        loc: [{ locConcepts = [] } = {}] = [{}],
        misc: [{ miscConcepts = [] } = {}] = [{}],
    } = conceptsData;

    const docLayout =
        docLayoutString &&
        JSON.parse(docLayoutString).reduce((acc, { index, width, height }) => {
            acc[index] = { width, height };
            return acc;
        }, {});

    useEffect(() => {
        setShowSidebar(size !== "small");
    }, [size]);

    // on first mount set highlight state based on hash params
    useEffect(() => {
        const hashParams = new URLSearchParams(history.location.hash.slice(1));
        if (hashParams.has("c")) {
            const conceptId = hashParams.get("c");

            const mention = hashParams.has("m")
                ? parseInt(hashParams.get("m"), 10)
                : 0;

            setSelectedConcept(conceptId);
            setCurrentMention(mention);
        }
        if (hashParams.has("p")) {
            const passageId = parseInt(hashParams.get("p"), 10);
            const sentenceId = parseInt(hashParams.get("s", undefined), 10);

            setSelectedAnnotation({
                passage: passageId,
                sentence: sentenceId || null,
            });
            setScrollToPassage({
                index: contextPassages.findIndex(
                    ({ passage }) => passage === passageId
                ),
            });
        }
    }, []);

    // when highlight state changes update hash params
    useEffect(() => {
        const hashParams = new URLSearchParams(history.location.hash.slice(1));

        if (selectedConcept) {
            hashParams.set("c", selectedConcept);
        } else if (selectedConcept === null) {
            hashParams.delete("c");
            hashParams.delete("m");
        }

        if (currentMention >= 0) {
            hashParams.set("m", currentMention);
        } else if (currentMention === null) {
            hashParams.delete("m");
        }

        if (selectedAnnotation) {
            hashParams.set("p", selectedAnnotation.passage);
            if (selectedAnnotation.sentence) {
                hashParams.set("s", selectedAnnotation.sentence);
            }
        } else if (selectedAnnotation !== undefined) {
            hashParams.delete("p");
            hashParams.delete("s");
        }

        history.replace({
            ...history.location,
            hash: hashParams.toString(),
        });
    }, [selectedConcept, currentMention, selectedAnnotation]);

    // change highlighted annotation for next/prev action
    useEffect(() => {
        if (scrollToPassage) {
            const annotation = contextPassages[scrollToPassage.index];
            if (annotation) {
                setSelectedAnnotation({
                    passage: annotation.passage,
                });
            }
        }
    }, [scrollToPassage]);

    // update passage highlights when selection changes
    useEffect(() => {
        if (!contentsConnection) return;

        const passageLayouts = contentsConnection.edges
            .filter(({ passage, sentence }) => {
                return (
                    urlQuery.get("debug") ||
                    contextPassages.find(
                        ({
                            passage: annotatedPassage,
                            sentence: annotatedSentence,
                        }) =>
                            passage === annotatedPassage &&
                            (!annotatedSentence ||
                                sentence === annotatedSentence)
                    )
                );
            })
            .reduce((acc, { passage, sentence, layout }) => {
                const passageId = contextPassages.find(
                    ({ sentence: annotatedSentence }) =>
                        annotatedSentence !== undefined
                )
                    ? `${passage}.${sentence}`
                    : `${passage}`;
                if (!acc[passageId]) {
                    acc[passageId] = {};
                }
                try {
                    layout.forEach((l) => {
                        if (docLayout) {
                            // sentence layout per page (for sentences split over pages)
                            const sentenceLayout = mapCoords(l, docLayout);

                            if (!acc[passageId][sentenceLayout.pageNumber]) {
                                acc[passageId][sentenceLayout.pageNumber] =
                                    sentenceLayout;
                            } else {
                                const currentLayout =
                                    acc[passageId][sentenceLayout.pageNumber];
                                const extendedLayout = {
                                    boundingRect: {
                                        x1: Math.min(
                                            currentLayout.boundingRect.x1,
                                            sentenceLayout.boundingRect.x1
                                        ),
                                        y1: Math.min(
                                            currentLayout.boundingRect.y1,
                                            sentenceLayout.boundingRect.y1
                                        ),
                                        x2: Math.max(
                                            currentLayout.boundingRect.x2,
                                            sentenceLayout.boundingRect.x2
                                        ),
                                        y2: Math.max(
                                            currentLayout.boundingRect.y2,
                                            sentenceLayout.boundingRect.y2
                                        ),
                                        width: sentenceLayout.boundingRect
                                            .width,
                                        height: sentenceLayout.boundingRect
                                            .height,
                                    },
                                    rects: [
                                        ...currentLayout.rects,
                                        ...sentenceLayout.rects,
                                    ],
                                    pageNumber: sentenceLayout.pageNumber,
                                };
                                acc[passageId][sentenceLayout.pageNumber] =
                                    extendedLayout;
                            }
                        } else {
                            acc[passageId] = [JSON.parse(l)];
                        }
                    });
                } catch (e) {
                    // ignore
                }
                return acc;
            }, {});

        const highlights = Object.entries(passageLayouts).reduce(
            // (acc, [id, layout], i) => {
            (acc, [id, layout]) => {
                Object.values(layout).forEach((position) => {
                    const isSelected =
                        selectedAnnotation &&
                        selectedAnnotation.passage !== undefined &&
                        id ===
                            `${selectedAnnotation.passage}${
                                selectedAnnotation.sentence !== undefined
                                    ? `.${selectedAnnotation.sentence}`
                                    : ""
                            }`;
                    if (
                        isSelected ||
                        highlightPassages ||
                        urlQuery.get("debug")
                    ) {
                        acc.push({
                            id,
                            position,
                            type: isSelected ? "currentPassage" : "passage",
                        });
                    }
                });

                return acc;
            },
            []
        );

        if (highlights.length > 0) {
            setPassageHighlights(highlights);
        }
    }, [
        selectedAnnotation,
        contentsConnection,
        highlightPassages,
        urlQuery.get("debug"),
    ]);

    // update concept highlights when selection changes
    useEffect(() => {
        const concepts = [
            ...personConcepts,
            ...locConcepts,
            ...orgConcepts,
            ...miscConcepts,
        ];

        if (!contentsConnection) return;

        const highlights = concepts
            .filter(({ id }) => id === selectedConcept)
            .reduce((acc, { id, mentionsConnection, children }) => {
                const edges = [
                    ...mentionsConnection.edges,
                    ...children.reduce(
                        (
                            prev,
                            { mentionsConnection: { edges: childEdges } }
                        ) => [...prev, ...childEdges],
                        []
                    ),
                ];

                if (docLayout) {
                    edges
                        .map(({ layout }) =>
                            layout.reduce(
                                (prevPages, layoutOnPage) => [
                                    ...prevPages,
                                    mapCoords(layoutOnPage, docLayout),
                                ],
                                []
                            )
                        )
                        .sort(([l1], [l2]) =>
                            l1.pageNumber === l2.pageNumber
                                ? l1.boundingRect.y1 - l2.boundingRect.y1
                                : l1.pageNumber - l2.pageNumber
                        )
                        .forEach((mention, i) => {
                            // sentence layout per page (for sentences split over pages)
                            mention.forEach((position) =>
                                acc.push({
                                    id: `${id}.${i}`,
                                    position,
                                    type:
                                        i === currentMention
                                            ? "currentConcept"
                                            : "concept",
                                })
                            );
                        });
                } else {
                    edges
                        .sort(
                            (
                                {
                                    node: {
                                        documentConnection: {
                                            edges: [
                                                { passage: p1, sentence: s1 },
                                            ],
                                        },
                                    },
                                    startOffset: o1,
                                },
                                {
                                    node: {
                                        documentConnection: {
                                            edges: [
                                                { passage: p2, sentence: s2 },
                                            ],
                                        },
                                    },
                                    startOffset: o2,
                                }
                            ) => p1 - p2 || s1 - s2 || o1 - o2
                        )
                        .forEach(
                            (
                                {
                                    node: {
                                        documentConnection: {
                                            edges: [{ passage, sentence }],
                                        },
                                    },
                                    startOffset,
                                    endOffset,
                                },
                                i
                            ) => {
                                acc.push({
                                    id: `${id}.${i}`,
                                    position: [
                                        passage,
                                        sentence,
                                        startOffset,
                                        endOffset,
                                    ],
                                    type:
                                        i === currentMention
                                            ? "currentConcept"
                                            : "concept",
                                });
                            }
                        );
                }
                return acc;
            }, []);
        setConceptHighlights(highlights);
    }, [selectedConcept, currentMention, contentsConnection]);

    // scroll to concept
    useEffect(() => {
        if (!viewerReady || !viewerRef.current) return;

        const target = conceptHighlights.find(
            ({ id }) => id === `${selectedConcept}.${currentMention}`
        );
        if (target) {
            viewerRef.current.scrollViewerTo(target);
        }
    }, [viewerReady, viewerRef.current, conceptHighlights]);

    // scroll to passage
    useEffect(() => {
        if (
            !viewerReady ||
            !viewerRef.current ||
            !passageHighlights ||
            !selectedAnnotation
        )
            return;

        let target;
        if (contentsConnection && selectedAnnotation.sentence !== undefined) {
            const foundSentence = contentsConnection.edges.find(
                ({ passage, sentence }) =>
                    passage === selectedAnnotation.passage &&
                    sentence === selectedAnnotation.sentence
            );
            if (foundSentence) {
                const position = foundSentence.layout.map((l) =>
                    mapCoords(l, docLayout)
                );
                target = {
                    id: `${selectedAnnotation.passage}/${selectedAnnotation.sentence}`,
                    position: position[0],
                };
                viewerRef.current.scrollViewerTo(target);
            }
        } else {
            target = passageHighlights.find(
                ({ id }) => id === `${selectedAnnotation.passage}`
            );
        }
        if (target) {
            viewerRef.current.scrollViewerTo({ ...target, type: "passage" });
        }
    }, [viewerReady, viewerRef.current, selectedAnnotation, passageHighlights]);

    if (
        !metadataLoading &&
        !error &&
        (!metadata || !metadata.documents || metadata.documents.length === 0)
    ) {
        return <NotFound />;
    }

    return (
        <Box direction="row" overflow="hidden" fill ref={mainRef}>
            {size !== "large" && (
                <Layer
                    plain
                    position={showSidebar ? "top-right" : "top-left"}
                    modal={false}
                    responsive={false}
                    target={mainRef.current}
                    animation="fadeIn"
                >
                    <Box
                        pad="small"
                        fill
                        margin={{ top: "60px" }}
                        elevation="medium"
                        background="background"
                        onClick={() => setShowSidebar(!showSidebar)}
                        focusIndicator={false}
                    >
                        <Button
                            plain
                            icon={
                                showSidebar ? (
                                    <CaretLeftFill />
                                ) : (
                                    <CaretRightFill />
                                )
                            }
                        />
                    </Box>
                </Layer>
            )}
            {!metadataLoading && (
                <CollapsibleSidebar open={showSidebar}>
                    <Box overflow={{ vertical: "auto" }}>
                        <Box
                            border={
                                size === "small"
                                    ? { color: "lightGrey", side: "right" }
                                    : false
                            }
                            pad={{ horizontal: size }}
                            flex="grow"
                            width={{ max: "420px" }}
                        >
                            <Box pad={{ top: "small", bottom: "large" }}>
                                {!metadataLoading && (
                                    <Box
                                        flex="grow"
                                        animation="fadeIn"
                                        gap="small"
                                    >
                                        <Box margin={{ top: "small" }}>
                                            <Heading
                                                level={4}
                                                margin={{
                                                    bottom: "xsmall",
                                                }}
                                            >
                                                Document
                                            </Heading>
                                            <Text>
                                                {title ||
                                                    decodeURIComponent(path)
                                                        .split("/")
                                                        .pop()}
                                            </Text>
                                        </Box>
                                        <Box width="small" pad="none">
                                            <BookmarkButton
                                                conceptSpaceId={conceptSpaceId}
                                                targetType="Document"
                                                targetNode={{
                                                    conceptSpace: {
                                                        id: conceptSpaceId,
                                                    },
                                                    uri,
                                                }}
                                            />
                                        </Box>
                                    </Box>
                                )}
                                {!annotationsLoading && !conceptsLoading && (
                                    <Box
                                        margin={{ top: "medium" }}
                                        align="start"
                                    >
                                        {context && context.name && (
                                            <Box
                                                fill="horizontal"
                                                gap="small"
                                                margin={{
                                                    top: "medium",
                                                    bottom: "large",
                                                }}
                                                flex="grow"
                                                // width={{ max: "medium" }}
                                            >
                                                <Box gap="small">
                                                    <Heading
                                                        level={4}
                                                        margin={{
                                                            bottom: "none",
                                                        }}
                                                    >
                                                        {capitalize(
                                                            context.type
                                                        )}
                                                    </Heading>
                                                    <Box flex="grow">
                                                        <Text
                                                            truncate
                                                        >{`${context.name}`}</Text>
                                                    </Box>
                                                    <Box
                                                        fill
                                                        border={{
                                                            color: "light-5",
                                                        }}
                                                        background="white"
                                                        round="xxsmall"
                                                        pad={{
                                                            horizontal:
                                                                "medium",
                                                            top: "medium",
                                                            bottom: "small",
                                                        }}
                                                    >
                                                        <Box>
                                                            <Text>
                                                                {
                                                                    contextPassages.length
                                                                }{" "}
                                                                relevant passage
                                                                {contextPassages.length >
                                                                1
                                                                    ? "s"
                                                                    : ""}
                                                            </Text>
                                                        </Box>
                                                        <Box
                                                            direction="row-responsive"
                                                            justify="between"
                                                            align="center"
                                                            margin={{
                                                                top: "xsmall",
                                                            }}
                                                        >
                                                            {contextPassages.length >
                                                            1 ? (
                                                                <Box
                                                                    direction="row"
                                                                    align="center"
                                                                    gap="small"
                                                                    margin={{
                                                                        right: "small",
                                                                    }}
                                                                >
                                                                    <Box
                                                                        margin={{
                                                                            right: "xsmall",
                                                                        }}
                                                                        // background="rgb(218, 234, 251)"
                                                                    >
                                                                        <Text
                                                                            size="small"
                                                                            style={{
                                                                                whiteSpace:
                                                                                    "nowrap",
                                                                            }}
                                                                        >
                                                                            Highlight
                                                                            all
                                                                        </Text>
                                                                    </Box>
                                                                    <CheckBox
                                                                        size="small"
                                                                        color="primary"
                                                                        onChange={() =>
                                                                            setHighlightPassages(
                                                                                !highlightPassages
                                                                            )
                                                                        }
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                <Box />
                                                            )}
                                                            <Box
                                                                direction="row"
                                                                align="center"
                                                                margin={{
                                                                    left: "small",
                                                                }}
                                                            >
                                                                <Box
                                                                    align="end"
                                                                    wrap={false}
                                                                >
                                                                    <Text
                                                                        // size="xsmall"
                                                                        color="brand"
                                                                        style={{
                                                                            whiteSpace:
                                                                                "nowrap",
                                                                        }}
                                                                    >
                                                                        {`${
                                                                            scrollToPassage.index +
                                                                            1
                                                                        } / ${
                                                                            contextPassages.length
                                                                        }`}
                                                                    </Text>
                                                                </Box>
                                                                <Button
                                                                    plain
                                                                    icon={
                                                                        <FormUp color="brand" />
                                                                    }
                                                                    onClick={() => {
                                                                        setScrollToPassage(
                                                                            {
                                                                                index:
                                                                                    scrollToPassage.index ===
                                                                                    0
                                                                                        ? contextPassages.length -
                                                                                          1
                                                                                        : scrollToPassage.index -
                                                                                          1,
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                                <Button
                                                                    plain
                                                                    icon={
                                                                        <FormDown color="brand" />
                                                                    }
                                                                    onClick={() => {
                                                                        setScrollToPassage(
                                                                            {
                                                                                index:
                                                                                    scrollToPassage.index +
                                                                                        1 ===
                                                                                    contextPassages.length
                                                                                        ? 0
                                                                                        : scrollToPassage.index +
                                                                                          1,
                                                                            }
                                                                        );
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        )}
                                        {(
                                            personConcepts +
                                            orgConcepts +
                                            locConcepts +
                                            miscConcepts
                                        ).length > 0 && (
                                            <Box
                                                fill="horizontal"
                                                animation="fadeIn"
                                                margin={{
                                                    top: "medium",
                                                }}
                                            >
                                                <Heading
                                                    level={4}
                                                    margin={{
                                                        bottom: "small",
                                                    }}
                                                >
                                                    In this document
                                                </Heading>
                                                <ConceptList
                                                    concepts={{
                                                        people: mapConceptData(
                                                            personConcepts
                                                        ),
                                                        organisations:
                                                            mapConceptData(
                                                                orgConcepts
                                                            ),
                                                        places: mapConceptData(
                                                            locConcepts
                                                        ),
                                                        miscConcepts:
                                                            mapConceptData(
                                                                miscConcepts
                                                            ),
                                                    }}
                                                    selectedConcept={
                                                        selectedConcept
                                                    }
                                                    currentMention={
                                                        currentMention
                                                    }
                                                    onChange={(
                                                        concept,
                                                        mention
                                                    ) => {
                                                        if (concept) {
                                                            const { id } =
                                                                concept;
                                                            setSelectedConcept(
                                                                id
                                                            );
                                                        } else {
                                                            setSelectedConcept(
                                                                null
                                                            );
                                                        }
                                                        setCurrentMention(
                                                            mention
                                                        );
                                                    }}
                                                />
                                            </Box>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </Box>
                    </Box>
                </CollapsibleSidebar>
            )}
            {(error || viewerError) && (
                <ErrorBox error={error || viewerError} />
            )}

            {contentType === "application/pdf" ? (
                <Box flex="grow" pad={{ horizontal: size }}>
                    {(metadataLoading || (!viewerReady && !error)) && (
                        <Box fill flex="grow" justify="center">
                            <Spinner alignSelf="center" />
                        </Box>
                    )}
                    {!metadataLoading && url && (
                        <PDF
                            ref={viewerRef}
                            url={url}
                            highlights={[
                                ...passageHighlights,
                                ...conceptHighlights,
                            ]}
                            onReady={() => setViewerReady(true)}
                            onSelection={() =>
                                history.replace({
                                    ...history.location,
                                    hash: null,
                                })
                            }
                            onError={(e) => setViewerError(e)}
                        />
                    )}
                </Box>
            ) : (
                <Box
                    fill="horizontal"
                    overflow={{ vertical: "scroll" }}
                    pad={size}
                >
                    {(metadataLoading || (!viewerReady && !error)) && (
                        <Box fill flex="grow" justify="center">
                            <Spinner alignSelf="center" />
                        </Box>
                    )}
                    {!metadataLoading && url && (
                        <TextDocument
                            ref={viewerRef}
                            url={url}
                            layout={mapTextLayout(contentsConnection)}
                            highlights={[
                                ...passageHighlights,
                                ...conceptHighlights,
                            ]}
                            onReady={() => {
                                setViewerReady(true);
                            }}
                            onSelection={() =>
                                history.replace({
                                    ...history.location,
                                    hash: null,
                                })
                            }
                            onError={(e) => setViewerError(e)}
                        />
                    )}
                </Box>
            )}
        </Box>
    );
}

export default DocumentView;
