import React, { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import { Box, Heading, Page, PageContent, ResponsiveContext } from "grommet";

import { gql, useQuery } from "@apollo/client";
import ErrorBox from "./components/ErrorBox";
import SearchBar from "./components/SearchBar";
import SkipNav from "./components/SkipNav";
import ConceptMentions from "./components/ConceptMentions";
import NotFound from "./components/NotFound";
import Highlights from "./components/Highlights";
import Notes from "./components/Notes";
import NoteContext from "./components/NoteContext";
import BookmarkButton from "./components/BookmarkButton";

const GET_THEME = gql`
    query GetTheme(
        $where: ThemeWhere
        $conceptsWhere: ConceptsWhere
        $conceptsOptions: PaginationOptions
        $sentencesWhere: ThemeSentencesConnectionWhere
        $sentencesSort: [ThemeSentencesConnectionSort!]
        $sentencesFirst: Int = 300
    ) {
        themes(where: $where) {
            name
            highlights
            concepts(where: $conceptsWhere, options: $conceptsOptions) {
                id
                name
                entityType
                source
            }
            sentencesConnection(
                sort: $sentencesSort
                where: $sentencesWhere
                first: $sentencesFirst
            ) {
                edges {
                    probability
                    relevance
                    node {
                        text
                        documentConnection {
                            edges {
                                sentence
                                paragraph
                                passage
                                node {
                                    title
                                    path
                                    uri
                                    date
                                    sitename
                                }
                            }
                        }
                        mentionsConnection {
                            edges {
                                startOffset
                                endOffset
                                node {
                                    id
                                    entityType
                                    parent {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const conceptFilters = {
    "All key concepts": null,
    People: ["PER", "PERSON"],
    Places: ["LOC", "GPE"],
    Organisations: ["ORG"],
};

function Theme() {
    const history = useHistory();
    const { conceptSpaceId, themeName: themeId } = useParams();
    const themeName = decodeURIComponent(themeId);

    const size = React.useContext(ResponsiveContext);

    const [selectedConcept, setSelectedConcept] = useState(
        history.location.state && history.location.state.selectedConcept
    );
    const [selectedFilter, setSelectedFilter] = useState(
        (history.location.state && history.location.state.selectedFilter) ||
            Object.keys(conceptFilters)[0]
    );

    const [query, setQuery] = useState();

    const noteContext = useMemo(() => ({
        conceptSpaceId,
    }));

    // default sentences filter
    const sentencesWhere = {
        // edge: {
        //     relevance_GT: 0.3,
        // },
    };

    const {
        loading,
        error,
        data,
        // refetch
    } = useQuery(GET_THEME, {
        variables: {
            where: {
                conceptSpace: { id: conceptSpaceId },
                name: themeName,
            },
            sentencesWhere,
            conceptsWhere: {
                entityType_NOT_IN: [
                    "DATE",
                    "TIME",
                    "MONEY",
                    "PERCENT",
                    "QUANTITY",
                ],
            },
            ...(conceptFilters[selectedFilter] && {
                conceptsWhere: {
                    entityType_IN: conceptFilters[selectedFilter],
                },
                sentencesWhere: {
                    ...sentencesWhere,
                    node: {
                        mentions: {
                            OR: [
                                {
                                    entityType_IN:
                                        conceptFilters[selectedFilter],
                                },
                                {
                                    parent: {
                                        entityType_IN:
                                            conceptFilters[selectedFilter],
                                    },
                                },
                            ],
                        },
                    },
                },
            }),
            ...(selectedConcept && {
                sentencesWhere: {
                    ...sentencesWhere,
                    node: {
                        mentions: {
                            OR: [
                                { id: selectedConcept.id },
                                {
                                    parent: {
                                        id: selectedConcept.id,
                                    },
                                },
                            ],
                        },
                    },
                },
            }),
            sentencesSort: [
                {
                    edge: {
                        relevance: "DESC",
                    },
                },
            ],
            conceptsOptions: {
                limit: 20,
            },
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        // preserve state for back navigation
        history.replace({
            ...history.location,
            state: { selectedConcept, selectedFilter },
        });
    }, [selectedConcept, selectedFilter]);

    if (error)
        return (
            <Page kind="narrow">
                <PageContent margin={{ top: "large", bottom: "medium" }}>
                    <ErrorBox error={error} />
                </PageContent>
            </Page>
        );

    if (!loading && (!data || !data.themes || data.themes.length === 0)) {
        return <NotFound />;
    }

    const {
        themes: [
            {
                highlights = [],
                concepts = [],
                sentencesConnection: { edges } = { edges: [] },
            },
        ] = [{}],
    } = data || {};

    const passageRelevances = edges.reduce(
        (
            acc,
            {
                relevance,
                node: {
                    documentConnection: {
                        edges: [
                            {
                                passage: passageId,
                                node: { path },
                            },
                        ],
                    },
                },
            }
        ) => {
            const passagePath = `${path}#${passageId}`;
            const passageRelevance = acc[passagePath] || 0;
            acc[passagePath] = Math.max(passageRelevance, relevance);
            return acc;
        },
        {}
    );
    const sentences = edges
        .map(({ node }) => node)
        .sort(
            (
                {
                    documentConnection: {
                        edges: [
                            {
                                passage: p1,
                                sentence: s1,
                                node: { path: d1 },
                            },
                        ],
                    },
                },
                {
                    documentConnection: {
                        edges: [
                            {
                                passage: p2,
                                sentence: s2,
                                node: { path: d2 },
                            },
                        ],
                    },
                }
            ) => {
                const r1 = passageRelevances[`${d1}#${p1}`];
                const r2 = passageRelevances[`${d2}#${p2}`];
                if (r1 === r2) {
                    if (d1 === d2) {
                        if (p1 === p2) {
                            return s1 - s2;
                        }
                        return p1 - p2;
                    }
                    return d1.localeCompare(d2);
                }
                return r2 - r1;
            }
        );

    return (
        <Page kind="wide" flex="grow">
            <NoteContext.Provider value={noteContext}>
                <PageContent margin={{ top: "large" }}>
                    <SearchBar
                        text={query}
                        size="small"
                        onChange={(value) => setQuery(value)}
                        onSearch={(value) => {
                            if (value instanceof Object) {
                                history.push(
                                    `/${conceptSpaceId}/${
                                        value.type
                                    }s/${encodeURIComponent(
                                        value.id || value.name
                                    )}`
                                );
                            } else {
                                history.push({
                                    pathname: `/${conceptSpaceId}/answers`,
                                    search: `q=${value}`,
                                });
                            }
                        }}
                    />
                </PageContent>
                <PageContent margin={{ top: "large", bottom: "small" }}>
                    <Heading
                        level="2"
                        size={size === "small" ? "xsmall" : "small"}
                        color="primaryLabel"
                        margin="none"
                    >
                        Theme
                    </Heading>
                    <Heading
                        level={size === "small" ? 2 : 3}
                        size={size === "small" ? "medium" : "large"}
                        margin={{ top: "none", bottom: "medium" }}
                        fill
                    >
                        {themeName}
                    </Heading>
                    <Box width="small" pad="none">
                        <BookmarkButton
                            conceptSpaceId={conceptSpaceId}
                            targetType="Theme"
                            targetNode={{
                                name: themeName,
                                conceptSpace: {
                                    id: conceptSpaceId,
                                },
                            }}
                        />
                    </Box>
                </PageContent>
                <PageContent>
                    <Box margin={{ left: "22px" }}>
                        <SkipNav
                            items={[
                                { id: "highlights", label: "Highlights" },
                                { id: "concepts", label: "Concepts" },
                                { id: "mentions", label: "Mentions" },
                            ]}
                        />
                        <Box direction="row" justify="between" gap="large">
                            <Box margin={{ bottom: "small" }} fill>
                                <Highlights
                                    loading={loading}
                                    highlights={highlights}
                                />
                                <ConceptMentions
                                    conceptsLabel="In this theme"
                                    concepts={concepts}
                                    conceptsLoading={loading}
                                    selectedConcept={selectedConcept}
                                    filters={conceptFilters}
                                    selectedFilter={selectedFilter}
                                    mentions={sentences}
                                    mentionContext={{
                                        type: "theme",
                                        name: themeName,
                                    }}
                                    mentionsLoading={loading}
                                    onSelectConcept={(concept) =>
                                        setSelectedConcept(
                                            selectedConcept &&
                                                concept &&
                                                selectedConcept.id ===
                                                    concept.id
                                                ? null
                                                : concept
                                        )
                                    }
                                    onChangeFilter={(filter) => {
                                        setSelectedConcept(null);
                                        setSelectedFilter(filter);
                                    }}
                                />
                            </Box>
                            {size !== "small" && (
                                <Notes conceptSpaceId={conceptSpaceId} />
                            )}
                        </Box>
                    </Box>
                </PageContent>
            </NoteContext.Provider>
        </Page>
    );
}

export default Theme;
