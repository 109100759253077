// import React, { useEffect, useState } from 'react';
import React from "react";

import { useLocation } from "react-router-dom";

function useQueryString() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default useQueryString;
