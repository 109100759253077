import { Box, Spinner, Text } from "grommet";
import React, { useEffect, useState } from "react";
import TypingText from "./TypingText";

function TypingLines({ lines, delay = 2000 }) {
    const [ready, setReady] = useState(false);
    const [displayedLines, setDisplayedLines] = useState([]);

    useEffect(() => {
        if (!ready) {
            const delayTimer = setTimeout(() => {
                setReady(true);
            }, delay); // Randomized delay between 50ms and 250ms

            return () => clearTimeout(delayTimer);
        }
        return () => {};
    }, [ready]);

    if (!ready) {
        return <Spinner size="xsmall" />;
    }

    if (lines.length === displayedLines.length) {
        return lines.map((line, n) => (
            <Box direction="row">
                <Text>{n + 1}.&nbsp;&nbsp;&nbsp;</Text>
                <Text color="black">
                    {line}
                    <br />
                    <br />
                </Text>
            </Box>
        ));
    }

    return (
        <>
            {displayedLines.map((line, n) => (
                <Box direction="row">
                    <Text>{n + 1}.&nbsp;&nbsp;&nbsp;</Text>
                    <Text color="black">
                        {line}
                        <br />
                        <br />
                    </Text>
                </Box>
            ))}
            <Box direction="row">
                <Text>{displayedLines.length + 1}.&nbsp;&nbsp;&nbsp;</Text>
                <TypingText
                    text={lines[displayedLines.length]}
                    onDone={(text) => {
                        setDisplayedLines((prevLines) => [...prevLines, text]);
                    }}
                />
            </Box>
        </>
    );
}

export default TypingLines;
