import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getApp } from "firebase/app";
import {
    createUserWithEmailAndPassword,
    getAuth,
    getRedirectResult,
} from "firebase/auth";

import {
    Anchor,
    Box,
    Button,
    Form,
    FormField,
    Heading,
    Image,
    Page,
    Spinner,
    Text,
    TextInput,
    ThemeContext,
} from "grommet";

import { useAuth } from "./components/auth/AuthProvider";
import PasswordInput from "./components/auth/PasswordInput";
import GoogleLoginButton from "./components/auth/GoogleLoginButton";
import AuthErrorNotification from "./components/auth/AuthErrorNotification";

function SignUp() {
    const history = useHistory();
    const { hash } = useLocation();
    const { register, handleSubmit, formState } = useForm();
    const [signupError, setSignupError] = useState();
    const [signingUp, setSigningUp] = useState(false);
    const { loading } = useAuth();

    const { errors } = formState;

    useEffect(() => {
        getRedirectResult(getAuth(getApp()))
            .then((result) => {
                if (result) {
                    history.replace(hash ? hash.slice(1) : "/");
                }
            })
            .catch((error) => {
                setSignupError(error);
            });
    }, []);

    return (
        <Page
            kind="narrow"
            pad="medium"
            flex="grow"
            align="center"
            justify="center"
        >
            <Box pad={{ bottom: "xlarge" }} align="center">
                <Image
                    height="100px"
                    alt="Archer logo"
                    src="/logo/archer-secondary-logo.png"
                />
                <Box direction="row" margin={{ bottom: "large" }}>
                    <Heading
                        level="2"
                        pad={{ bottom: "large" }}
                        margin={{ top: "14px", right: "2px" }}
                    >
                        Sign up to Archer
                    </Heading>
                </Box>
                {/* <Box width={{ max: "400px" }}>
                    <Text size="small" textAlign="center">
                        Archer is a simple AI research tool that helps you
                        summarise topics or make sense of a bunch of documents
                        in minutes.
                    </Text>
                </Box> */}
                <Box width={{ max: "300px" }} margin={{ bottom: "small" }}>
                    <ThemeContext.Extend
                        value={{
                            global: {
                                control: {
                                    border: { radius: "10px", color: "white" },
                                },
                                input: {
                                    padding: { left: "small", vertical: "8px" },
                                },
                            },
                            formField: {
                                border: null,
                            },
                            textInput: {
                                container: {
                                    extend: `
                                    background: white;
                                    border-radius: 6px;
                                    filter: drop-shadow(0px 8px 10px rgba(41, 70, 152, 0.05));`,
                                },
                            },
                        }}
                    >
                        <Form
                            onSubmit={handleSubmit(
                                async ({ email, password }) => {
                                    setSignupError(undefined);
                                    setSigningUp(true);
                                    try {
                                        await createUserWithEmailAndPassword(
                                            getAuth(getApp()),
                                            email,
                                            password
                                        );
                                        // Signed up

                                        history.replace({
                                            ...history.location,
                                            pathname: history.location.state
                                                ? history.location.state
                                                      .pathname
                                                : "/",
                                        });
                                    } catch (error) {
                                        setSignupError(error);
                                    } finally {
                                        setSigningUp(false);
                                    }
                                }
                            )}
                        >
                            <FormField>
                                <GoogleLoginButton
                                    disabled={loading || signingUp}
                                />
                            </FormField>
                            <Box
                                direction="row"
                                margin={{ top: "large", bottom: "small" }}
                                align="center"
                                gap="small"
                            >
                                <Box
                                    fill
                                    border={{
                                        side: "bottom",
                                        color: "light-5",
                                        size: "1px",
                                    }}
                                />
                                <Box flex="grow">
                                    <Text size="xsmall" wordBreak="keep-all">
                                        or continue with email
                                    </Text>
                                </Box>
                                <Box
                                    fill
                                    border={{
                                        side: "bottom",
                                        color: "light-5",
                                        size: "1px",
                                    }}
                                />
                            </Box>
                            <FormField name="email" label="EMAIL">
                                <TextInput
                                    plain
                                    style={{
                                        border: 1,
                                        round: "xsmall",
                                        background: "white",
                                    }}
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: "Email is required",
                                        },
                                        pattern: {
                                            value: /^\S+@\S+$/i,
                                            message: "Invalid email address",
                                        },
                                    })}
                                    disabled={loading || signingUp}
                                />
                                {errors.email && (
                                    <Box
                                        pad={{
                                            top: "xsmall",
                                            horizontal: "small",
                                        }}
                                    >
                                        <Text size="small" color="status-error">
                                            {errors.email.message}
                                        </Text>
                                    </Box>
                                )}
                            </FormField>
                            <FormField name="password" label="PASSWORD">
                                <PasswordInput
                                    style={{
                                        border: 1,
                                        round: "xsmall",
                                        background: "white",
                                    }}
                                    {...register("password", {
                                        required: {
                                            value: true,
                                            message: "Password is required",
                                        },
                                        minLength: {
                                            value: 8,
                                            message:
                                                "Password must have at least 8 characters",
                                        },
                                        pattern: {
                                            value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/,
                                            message:
                                                "Password must have at least one lowercase or uppercase letter and at least one number",
                                        },
                                    })}
                                    disabled={loading || signingUp}
                                />
                                {errors.password && (
                                    <Box
                                        pad={{
                                            top: "xsmall",
                                            horizontal: "small",
                                        }}
                                    >
                                        <Text size="small" color="status-error">
                                            {errors.password.message}
                                        </Text>
                                    </Box>
                                )}
                            </FormField>
                            <FormField
                                margin={{ vertical: "medium" }}
                                direction="row"
                                justify="center"
                            >
                                <Button
                                    type="submit"
                                    label="Create Account"
                                    disabled={loading || signingUp}
                                />
                            </FormField>
                            <Box margin="medium">
                                <Text size="xsmall" textAlign="center">
                                    By creating an account you are agreeing to
                                    our{" "}
                                    {/* <Anchor
                                        color="primaryLabel"
                                        href="https://www.getarcher.ai/terms"
                                        target="_blank"
                                    >
                                        Terms of Service
                                    </Anchor>{" "}
                                    and{" "} */}
                                    <Anchor
                                        color="primaryLabel"
                                        href="https://www.getarcher.ai/privacy-policy"
                                        target="_blank"
                                        style={{ whiteSpace: "nowrap" }}
                                    >
                                        Privacy Policy
                                    </Anchor>
                                    .
                                </Text>
                            </Box>
                            {(loading || signingUp) && (
                                <Box align="center">
                                    <Spinner />
                                </Box>
                            )}
                        </Form>
                    </ThemeContext.Extend>
                </Box>
                {signupError && (
                    <AuthErrorNotification
                        title="Sign up failed"
                        error={signupError}
                    />
                )}
                <Box align="center" margin={{ top: "xlarge" }}>
                    <Text size="small">
                        Already have an account?{" "}
                        <Anchor
                            color="primaryLabel"
                            onClick={() =>
                                history.push({
                                    ...history.location,
                                    pathname: "/login",
                                })
                            }
                        >
                            Login
                        </Anchor>
                    </Text>
                </Box>
            </Box>
        </Page>
    );
}

export default SignUp;
