import React, { useEffect } from "react";

import { useHistory } from "react-router-dom";

import { Box, Spinner } from "grommet";
import { useAuth } from "./AuthProvider";

function Protected({ children }) {
    const history = useHistory();
    const { user, loading } = useAuth();

    useEffect(() => {
        if (!loading && !user) {
            history.replace("/login", { ...history.location });
        }
    }, [loading, user]);

    if (loading || !user) {
        return (
            <Box fill justify="center">
                <Box align="center">
                    <Spinner />
                </Box>
            </Box>
        );
    }

    if (user) {
        return children;
    }
}

export default Protected;
