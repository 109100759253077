import React, { useEffect, useMemo, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import {
    Box,
    Heading,
    Page,
    PageContent,
    ResponsiveContext,
    Spinner,
} from "grommet";

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import ErrorBox from "./components/ErrorBox";
import SearchBar from "./components/SearchBar";
import SkipNav from "./components/SkipNav";
import ConceptMentions from "./components/ConceptMentions";
import NotFound from "./components/NotFound";
import Notes from "./components/Notes";
import BookmarkButton from "./components/BookmarkButton";

import entityTypeToLabel from "./utils/entityUtils";
import NoteContext from "./components/NoteContext";

const GET_CONCEPT = gql`
    query GetConcept(
        $conceptSpacesWhere: ConceptSpaceWhere
        $conceptWhere: ConceptsWhere
        $associationsWhere: ConceptsWhere
        $associationsOptions: PaginationOptions
    ) {
        conceptSpaces(where: $conceptSpacesWhere) {
            id
            concepts(where: $conceptWhere) {
                id
                name
                entityType
                associations(
                    where: $associationsWhere
                    options: $associationsOptions
                ) {
                    id
                    name
                    entityType
                    source
                }
            }
        }
    }
`;

const GET_CONCEPT_MENTIONS = gql`
    query GetConcept(
        $conceptSpacesWhere: ConceptSpaceWhere
        $conceptWhere: ConceptsWhere
        $sentencesWhere: SentenceWhere
        $sentencesOptions: SentenceOptions
    ) {
        conceptSpaces(where: $conceptSpacesWhere) {
            id
            concepts(where: $conceptWhere) {
                sentences(where: $sentencesWhere, options: $sentencesOptions) {
                    text
                    documentConnection {
                        edges {
                            sentence
                            paragraph
                            passage
                            node {
                                title
                                path
                                uri
                                date
                                sitename
                            }
                        }
                    }
                    mentionsConnection {
                        edges {
                            startOffset
                            endOffset
                            node {
                                id
                                entityType
                                parent {
                                    id
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const conceptFilters = {
    "All Concepts": null,
    People: ["PER", "PERSON"],
    Places: ["LOC", "GPE"],
    Organisations: ["ORG"],
};

function Concept() {
    const history = useHistory();
    const { conceptSpaceId, conceptId: encodedConceptId } = useParams();
    const conceptId =
        encodedConceptId &&
        encodedConceptId.replaceAll("%2F", "/").replaceAll("%2C", ",");

    const size = React.useContext(ResponsiveContext);

    const [selectedConcept, setSelectedConcept] = useState(
        history.location.state && history.location.state.selectedConcept
    );
    const [selectedFilter, setSelectedFilter] = useState(
        Object.keys(conceptFilters)[0]
    );

    const [query, setQuery] = useState();

    const noteContext = useMemo(() => ({
        conceptSpaceId,
    }));

    useEffect(() => {
        // preserve state for back navigation
        history.replace({ state: { selectedConcept } });
    }, [selectedConcept]);

    const { loading, error, data, previousData } = useQuery(GET_CONCEPT, {
        variables: {
            conceptSpacesWhere: {
                id: conceptSpaceId,
            },
            conceptWhere: {
                // name_MATCHES: `(?i)${conceptId}`,
                id: conceptId,
            },
            associationsWhere: {
                entityType_NOT_IN: [
                    "DATE",
                    "TIME",
                    "MONEY",
                    "PERCENT",
                    "QUANTITY",
                ],
            },
            ...(conceptFilters[selectedFilter] && {
                associationsWhere: {
                    entityType_IN: conceptFilters[selectedFilter],
                },
            }),
            associationsOptions: {
                limit: 20,
            },
        },
    });

    const [
        getMentions,
        { loading: mentionsLoading, error: mentionsError, data: mentionsData },
    ] = useLazyQuery(GET_CONCEPT_MENTIONS, {
        variables: {
            conceptSpacesWhere: {
                id: conceptSpaceId,
            },
            conceptWhere: {
                id: conceptId,
            },
            ...(conceptFilters[selectedFilter] && {
                sentencesWhere: {
                    mentions: {
                        OR: [
                            { entityType_IN: conceptFilters[selectedFilter] },
                            {
                                parent: {
                                    entityType_IN:
                                        conceptFilters[selectedFilter],
                                },
                            },
                        ],
                    },
                },
            }),
            ...(selectedConcept && {
                sentencesWhere: {
                    mentions: {
                        OR: [
                            { id: selectedConcept.id },
                            {
                                parent: {
                                    id: selectedConcept.id,
                                },
                            },
                        ],
                    },
                },
            }),
            sentencesOptions: {
                limit: 100,
            },
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    useEffect(() => {
        if (!loading && !error && data) {
            getMentions();
        }
    }, [loading, error, data, selectedFilter, selectedConcept]);

    if (error)
        return (
            <Page kind="narrow">
                <PageContent margin={{ top: "large", bottom: "medium" }}>
                    <ErrorBox error={error} />
                </PageContent>
            </Page>
        );

    if (
        !loading &&
        !error &&
        (!data ||
            !data.conceptSpaces ||
            data.conceptSpaces.length === 0 ||
            !data.conceptSpaces[0].concepts ||
            data.conceptSpaces[0].concepts.length === 0)
    ) {
        return <NotFound />;
    }

    const {
        conceptSpaces: [
            {
                concepts: [
                    {
                        name: conceptName,
                        entityType,
                        associations: concepts = [],
                    } = {},
                ] = [],
            },
        ] = [{}],
    } = data || previousData || {};

    const {
        conceptSpaces: [{ concepts: [{ sentences = [] } = {}] = [] }] = [{}],
    } = mentionsData || {};

    return (
        <Page kind="wide" flex="grow">
            <NoteContext.Provider value={noteContext}>
                <PageContent margin={{ top: "large" }}>
                    <SearchBar
                        text={query}
                        size="small"
                        onChange={(value) => setQuery(value)}
                        onSearch={(value) => {
                            if (value instanceof Object) {
                                history.push(
                                    `/${conceptSpaceId}/${
                                        value.type
                                    }s/${encodeURIComponent(
                                        value.id || value.name
                                    )}`
                                );
                            } else {
                                history.push({
                                    pathname: `/${conceptSpaceId}/answers`,
                                    search: `q=${value}`,
                                });
                            }
                        }}
                    />
                </PageContent>
                <PageContent margin={{ top: "large", bottom: "small" }}>
                    {!conceptName ? (
                        <Spinner />
                    ) : (
                        <>
                            <Heading
                                level="2"
                                size={size === "small" ? "xsmall" : "small"}
                                color="primaryLabel"
                                margin="none"
                            >
                                {entityTypeToLabel(entityType).toUpperCase()}
                            </Heading>

                            <Heading
                                level={size === "small" ? 2 : 3}
                                size={size === "small" ? "medium" : "large"}
                                margin={{ top: "none", bottom: "medium" }}
                                fill
                            >
                                {conceptName}
                            </Heading>
                            <Box width="small" pad="none">
                                <BookmarkButton
                                    conceptSpaceId={conceptSpaceId}
                                    targetType="Concept"
                                    targetNode={{
                                        id: conceptId,
                                        OR: [
                                            {
                                                mentions_SOME: {
                                                    document: {
                                                        conceptSpace: {
                                                            id: conceptSpaceId,
                                                        },
                                                    },
                                                },
                                            },
                                            {
                                                children_SOME: {
                                                    mentions_SOME: {
                                                        document: {
                                                            conceptSpace: {
                                                                id: conceptSpaceId,
                                                            },
                                                        },
                                                    },
                                                },
                                            },
                                        ],
                                    }}
                                />
                            </Box>
                        </>
                    )}
                </PageContent>
                <PageContent>
                    <Box margin={{ left: "22px" }}>
                        <SkipNav
                            items={[
                                { id: "concepts", label: "Concepts" },
                                { id: "mentions", label: "Mentions" },
                            ]}
                        />
                        {mentionsError && <ErrorBox error={mentionsError} />}
                        <Box direction="row" justify="between" gap="large">
                            <Box fill>
                                <ConceptMentions
                                    concepts={concepts}
                                    conceptsLoading={loading}
                                    highlightedConcepts={[{ id: conceptId }]}
                                    selectedConcept={selectedConcept}
                                    filters={conceptFilters}
                                    selectedFilter={selectedFilter}
                                    mentions={sentences}
                                    mentionContext={{
                                        type: "concept",
                                        id: conceptId,
                                    }}
                                    mentionsLoading={mentionsLoading}
                                    onSelectConcept={(concept) =>
                                        setSelectedConcept(
                                            selectedConcept === concept
                                                ? null
                                                : concept
                                        )
                                    }
                                    onChangeFilter={(filter) => {
                                        setSelectedConcept(null);
                                        setSelectedFilter(filter);
                                    }}
                                />
                            </Box>
                            {size !== "small" && (
                                <Notes conceptSpaceId={conceptSpaceId} />
                            )}
                        </Box>
                    </Box>
                </PageContent>
            </NoteContext.Provider>
        </Page>
    );
}

export default Concept;
