import React from "react";

import { Heading, Page, PageContent, Text } from "grommet";
import InfoBox from "./components/InfoBox";

function Maintenance() {
    return (
        <Page flex="grow">
            <PageContent margin={{ top: "large", bottom: "medium" }}>
                <Heading
                    level={2}
                    size="large"
                    margin={{ vertical: "large", left: "small" }}
                >
                    We&apos;ll be right back
                </Heading>
                <Text margin={{ left: "small" }}>
                    Archer is down for maintenance right now but should be back
                    soon.
                </Text>
            </PageContent>
            <PageContent margin="large">
                <InfoBox
                    size="large"
                    title="Want to know more about what you can do with Archer?"
                    text="Find out how other hungry minds are using Archer to
                    discover insights, develop strategies, research their
                    ideas and feed their curiosity"
                    actionLabel="Learn more"
                    action={() =>
                        window.open(
                            "https://same-judo-f78.notion.site/Archer-Help-084c04136b934873b6cea96702621473#2b824219a9e842e39fcd6eb7ec2a9a03",
                            "_blank"
                        )
                    }
                />
            </PageContent>
        </Page>
    );
}

export default Maintenance;
