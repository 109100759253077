function capitalize(lcString) {
    // converting first character to uppercase and join it to the final string
    return lcString.charAt(0).toUpperCase() + lcString.slice(1);
}

function camelCaseToLabel(camelCaseString) {
    // adding space between strings
    const result = camelCaseString.replace(/([A-Z])/g, " $1");

    return capitalize(result);
}

function idToLabel(idString) {
    // adding space between strings
    const result = idString.replace(/-/g, " ");

    return capitalize(result);
}

export { camelCaseToLabel, idToLabel, capitalize };
