import React from "react";
import { createRoot } from "react-dom/client";

import { hotjar } from "react-hotjar";

import {
    ApolloClient,
    ApolloProvider,
    InMemoryCache,
    createHttpLink,
    defaultDataIdFromObject,
    from,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";

import { RetryLink } from "@apollo/client/link/retry";

import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";

import { AuthProvider } from "./components/auth/AuthProvider";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

if (process.env.NODE_ENV !== "development") {
    hotjar.initialize(3250263, 6);
}

const httpLink = createHttpLink({
    uri:
        process.env.REACT_APP_GRAPHQL_URI ||
        (process.env.NODE_ENV === "development"
            ? "http://localhost:4000" //   "https://archer-dev-380811.firebaseapp.com/api"
            : "/api"),
});

const authLink = setContext(async (_, { headers }) => {
    // get the authentication token from local storage if it exists
    const user = getAuth(getApp()).currentUser;
    let session = null;
    if (user) {
        session = await user.getIdToken();
    }

    // return the headers to the context so httpLink can read them
    const context = {
        headers: {
            ...headers,
            ...(session && {
                authorization: `Bearer ${session}`,
            }),
        },
    };
    return context;
});

const retryLink = new RetryLink({
    delay: {
        initial: 300,
        max: Infinity,
        jitter: true,
    },
    attempts: {
        max: 5,
        retryIf: (error) => {
            if (error.statusCode === 500) {
                return true;
            }
            return false;
        },
    },
});

const client = new ApolloClient({
    link: from([authLink, retryLink, httpLink]),
    cache: new InMemoryCache({
        dataIdFromObject(responseObject) {
            return (
                defaultDataIdFromObject(responseObject) || responseObject.name
            );
        },
    }),
});

// TODO: react-pdf-highlighter needs to be updated to React 18 first

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
    <AuthProvider>
        <ApolloProvider client={client}>
            <App />
        </ApolloProvider>
    </AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
