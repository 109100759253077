import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import {
    Anchor,
    Box,
    Button,
    Card,
    FileInput,
    Heading,
    List,
    Page,
    PageContent,
    ResponsiveContext,
    Spinner,
    Tab,
    Tabs,
    Text,
    TextArea,
    TextInput,
    ThemeContext,
} from "grommet";

import { Trash } from "grommet-icons";

import asyncBatch from "async-batch";

import { gql, useMutation, useQuery } from "@apollo/client";

import { useAuth } from "./components/auth/AuthProvider";
import GoogleSearch from "./components/GoogleSearch";
import Modal from "./components/Modal";
import ErrorBox from "./components/ErrorBox";

const MAX_FILE_SIZE_MB = 5;
const MAX_PAGES = 100;

let addedDocumentsCount = 0;

const GET_DOCUMENTS = gql`
    query GetDocuments($conceptSpaceId: ID!) {
        conceptSpaces(where: { id: $conceptSpaceId }) {
            documentsConnection(sort: [{ edge: { updated: DESC } }]) {
                edges {
                    status
                    error
                    errorText
                    updated
                    node {
                        path
                        title
                        date
                        wordCount
                        pageCount
                    }
                }
            }
        }
    }
`;

const CREATE_UPLOAD_URL = gql`
    mutation CreateUploadUrl($conceptSpaceId: ID!, $name: String!) {
        createUploadUrl(conceptSpaceId: $conceptSpaceId, name: $name) {
            name
            url
        }
    }
`;

const ADD_DOCUMENT = gql`
    mutation AddDocument(
        $conceptSpaceId: ID!
        $name: String!
        $contentType: String
    ) {
        addDocument(
            conceptSpaceId: $conceptSpaceId
            name: $name
            contentType: $contentType
        ) {
            title
        }
    }
`;

function readFileAsync(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = reject;

        reader.readAsText(file);
    });
}

function QuotaAlert({ allowedDocs, maxDocs }) {
    const history = useHistory();

    return (
        <Box gap="small" align="end">
            {allowedDocs === 0 ? (
                <Text size="small" color="red">
                    Quota reached. You cannot add any more documents to this
                    project.
                </Text>
            ) : (
                <Text size="small" color="red">
                    You can only add {allowedDocs}{" "}
                    {allowedDocs === maxDocs ? "" : "more "}
                    document
                    {allowedDocs > 1 ? "s" : ""} to this project.
                </Text>
            )}
            <Text size="xsmall">
                Upgrade to increase your quota.{" "}
                <Anchor onClick={() => history.push("/account")}>
                    Manage account
                </Anchor>
            </Text>
        </Box>
    );
}

function AddDocuments() {
    const history = useHistory();
    const { conceptSpaceId } = useParams();

    const size = React.useContext(ResponsiveContext);

    const [newDocuments, setNewDocuments] = useState();
    const [pastedURLs, setPastedURLs] = useState();
    const [textFilename, setTextFilename] = useState();
    const [addedDocument, setAddedDocument] = useState();
    const [newDocumentsError, setNewDocumentsError] = useState();
    const [uploading, setUploading] = useState();

    const [maxDocs, setMaxDocs] = useState();
    const { plan, getQuota } = useAuth();

    useEffect(() => {
        if (plan !== undefined) {
            getQuota().then((quota) =>
                setMaxDocs(quota?.documentsPerProject || null)
            );
        }
    }, [plan]);

    const { loading, data } = useQuery(GET_DOCUMENTS, {
        variables: { conceptSpaceId },
    });

    const {
        conceptSpaces: [{ documentsConnection: { edges: documents } = {} }] = [
            {},
        ],
    } = data || {};

    const allowedDocs = Math.max(
        (maxDocs || Number.POSITIVE_INFINITY) - (documents?.length || 0),
        0
    );

    const [addDocument, { loading: addDocumentLoading }] = useMutation(
        ADD_DOCUMENT,
        { refetchQueries: ["GetDocuments"] }
    );

    const [createUploadUrl, { loading: uploadUrlLoading }] =
        useMutation(CREATE_UPLOAD_URL);

    const onAddURLs = useCallback(async (urls) => {
        setUploading(true);
        addedDocumentsCount = 0;

        await Promise.all(
            urls.map(async (urlString) => {
                try {
                    const url = new URL(urlString);
                    // remove trailing slash to create an s3 path for the file
                    const {
                        data: {
                            createUploadUrl: { name, url: uploadUrl },
                        },
                    } = await createUploadUrl({
                        variables: {
                            conceptSpaceId,
                            name: `${url.hostname}${url.pathname.replace(
                                /\/$/,
                                ""
                            )}`,
                        },
                    });

                    const contentType = "application/json";
                    const requestOptions = {
                        method: "PUT",
                        headers: {
                            "Content-Type": contentType,
                        },
                        body: JSON.stringify({
                            url,
                        }),
                    };

                    const response = await fetch(uploadUrl, requestOptions);
                    if (response.ok) {
                        await addDocument({
                            variables: {
                                conceptSpaceId,
                                name,
                                contentType,
                            },
                        });
                        setAddedDocument(name);
                    } else {
                        throw new Error(response.status);
                    }
                    addedDocumentsCount += 1;
                } catch (e) {
                    setNewDocumentsError(
                        new Error(`Failed to add "${urlString}"`, e)
                    );
                }
            })
        );
        setUploading(false);
    });

    useEffect(() => {
        if (uploading) {
            window.onbeforeunload = () => {
                return true;
            };
        } else {
            window.onbeforeunload = null;
        }

        if (uploading === false && !newDocumentsError) {
            history.replace("docs");
        }
    }, [uploading, newDocumentsError]);

    if (loading || maxDocs === undefined) {
        return (
            <Page
                kind="narrow"
                gap="medium"
                flex="grow"
                pad={{ bottom: "large" }}
                fill
                align="center"
            >
                <Box fill justify="center" align="center" gap="small">
                    <Spinner size="small" />
                </Box>
            </Page>
        );
    }

    return (
        <Page kind="wide" flex="grow">
            <PageContent margin={{ top: "medium", bottom: "small" }}>
                <Heading
                    level={2}
                    size={size}
                    margin={{ top: "large", bottom: "none" }}
                    pad="none"
                >
                    Add documents
                </Heading>
            </PageContent>
            <PageContent>
                {newDocumentsError && (
                    <ErrorBox error={newDocumentsError} canRetry={false} />
                )}
                {(uploadUrlLoading || uploading || addDocumentLoading) && (
                    <Modal margin="small">
                        <Card
                            pad="small"
                            width="medium"
                            height="small"
                            align="center"
                        >
                            <Heading level={4} size="small">
                                Adding{" "}
                                {newDocuments.length - addedDocumentsCount} of{" "}
                                {newDocuments.length} documents
                            </Heading>
                            <Spinner />
                            <Box pad="small">
                                <Text size="xsmall" wordBreak="break-all">
                                    {addedDocument && `Added ${addedDocument}`}
                                </Text>
                            </Box>
                        </Card>
                    </Modal>
                )}
                <ThemeContext.Extend
                    value={{
                        tab: {
                            pad: { bottom: "xsmall" },
                            extend: `font-weight: 600 !important;
                        font-size: 10px !important;`,
                        },
                    }}
                >
                    <Tabs
                        pad="medium"
                        justify="start"
                        overflow="auto"
                        onActive={() => setNewDocuments(undefined)}
                    >
                        <Tab title="Add Files" margin={{ top: "small" }}>
                            <Box
                                fill
                                width="large"
                                gap="medium"
                                margin={{ top: "medium" }}
                            >
                                <Text
                                    color="grey"
                                    margin={{ bottom: "medium" }}
                                >
                                    Add PDFs or text files below (the file size
                                    limit is {MAX_FILE_SIZE_MB}mb, maximum of{" "}
                                    {MAX_PAGES} pages per document)
                                </Text>
                                <ThemeContext.Extend
                                    value={{
                                        fileInput: {
                                            extend: `                                                
                                                & button {
                                                    background: transparent;
                                                    border: none;
                                                    filter: none;
                                                }
                                                `,
                                        },
                                        button: {
                                            primary: null,
                                        },
                                    }}
                                >
                                    <FileInput
                                        multiple
                                        onChange={(event, { files }) => {
                                            const fileList = files;
                                            if (fileList) {
                                                setNewDocuments(fileList);
                                            }
                                        }}
                                        messages={{
                                            dropPromptMultiple:
                                                "Drop files here or click to browse...",
                                        }}
                                    />
                                </ThemeContext.Extend>
                                <Box direction="row" justify="end" gap="medium">
                                    <Button
                                        secondary
                                        label="Cancel"
                                        onClick={() => history.replace("docs")}
                                    />
                                    <Button
                                        label="Add"
                                        disabled={
                                            !newDocuments ||
                                            newDocuments.length === 0 ||
                                            newDocuments.length > allowedDocs
                                        }
                                        onClick={async () => {
                                            const files =
                                                Array.from(newDocuments);
                                            const unsupported = files.filter(
                                                ({ type, name }) =>
                                                    ![
                                                        "text/plain",
                                                        "application/pdf",
                                                    ].includes(type) &&
                                                    !name.endsWith(".webloc")
                                            );
                                            if (unsupported.length > 0) {
                                                setNewDocumentsError(
                                                    new Error(
                                                        `Unsupported file type: ${unsupported
                                                            .map(
                                                                ({ name }) =>
                                                                    name
                                                            )
                                                            .join(", ")}`
                                                    )
                                                );
                                                return;
                                            }

                                            const toobig = files.filter(
                                                ({ size: fileSize }) =>
                                                    fileSize >
                                                    { MAX_FILE_SIZE_MB } * 1e6
                                            );
                                            if (toobig.length > 0) {
                                                setNewDocumentsError(
                                                    new Error(
                                                        `File exceeds maximum size of ${MAX_FILE_SIZE_MB}MB: ${toobig
                                                            .map(
                                                                ({ name }) =>
                                                                    name
                                                            )
                                                            .join(", ")}`
                                                    )
                                                );
                                                return;
                                            }

                                            setUploading(true);
                                            addedDocumentsCount = 0;
                                            await asyncBatch(
                                                files,
                                                async (file) => {
                                                    try {
                                                        let fileName;
                                                        let contentType;
                                                        const requestOptions = {
                                                            method: "PUT",
                                                        };

                                                        if (
                                                            file.name.endsWith(
                                                                ".webloc"
                                                            )
                                                        ) {
                                                            contentType =
                                                                "application/json";

                                                            const weblocText =
                                                                await readFileAsync(
                                                                    file
                                                                );
                                                            const parser =
                                                                new DOMParser();
                                                            const webloc =
                                                                parser.parseFromString(
                                                                    weblocText,
                                                                    "text/xml"
                                                                );
                                                            const url = new URL(
                                                                webloc.getElementsByTagName(
                                                                    "string"
                                                                )[0].textContent
                                                            );
                                                            // remove trailing slash to create an s3 path for the file
                                                            fileName = `${
                                                                url.hostname
                                                            }${url.pathname.replace(
                                                                /\/$/,
                                                                ""
                                                            )}`;

                                                            requestOptions.headers =
                                                                {
                                                                    "Content-Type":
                                                                        contentType,
                                                                };
                                                            requestOptions.body =
                                                                JSON.stringify({
                                                                    url,
                                                                });
                                                        } else {
                                                            fileName =
                                                                file.name;
                                                            contentType =
                                                                file.type;

                                                            requestOptions.contentType =
                                                                contentType;
                                                            requestOptions.body =
                                                                file;
                                                        }

                                                        const {
                                                            data: {
                                                                createUploadUrl:
                                                                    {
                                                                        url: uploadUrl,
                                                                        name,
                                                                    },
                                                            },
                                                        } = await createUploadUrl(
                                                            {
                                                                variables: {
                                                                    conceptSpaceId,
                                                                    name: fileName,
                                                                },
                                                            }
                                                        );

                                                        const response =
                                                            await fetch(
                                                                uploadUrl,
                                                                requestOptions
                                                            );
                                                        if (response.ok) {
                                                            await addDocument({
                                                                variables: {
                                                                    conceptSpaceId,
                                                                    name,
                                                                    contentType,
                                                                },
                                                            });
                                                            setAddedDocument(
                                                                name
                                                            );
                                                        } else {
                                                            throw new Error(
                                                                response.status
                                                            );
                                                        }

                                                        addedDocumentsCount += 1;
                                                    } catch (e) {
                                                        setNewDocumentsError(
                                                            new Error(
                                                                `Failed to add ${file.name}`,
                                                                e
                                                            )
                                                        );
                                                    }
                                                },
                                                5
                                            );
                                            setUploading(false);
                                        }}
                                    />
                                </Box>
                                <Box
                                    direction="row"
                                    justify="end"
                                    pad={{ bottom: "xlarge" }}
                                >
                                    {!uploading &&
                                        newDocuments &&
                                        newDocuments.length > allowedDocs && (
                                            <QuotaAlert
                                                allowedDocs={allowedDocs}
                                                maxDocs={maxDocs}
                                            />
                                        )}
                                </Box>
                            </Box>
                        </Tab>
                        <Tab title="Add a URL" margin={{ top: "small" }}>
                            <Box fill gap="medium" margin={{ top: "medium" }}>
                                <Text
                                    color="grey"
                                    margin={{ bottom: "medium" }}
                                >
                                    One or more URLs can be dragged or pasted
                                    into the box below
                                </Text>
                                <TextInput
                                    style={{
                                        border: "2px dashed rgba(0, 0, 0, 0.33)",
                                    }}
                                    placeholder={
                                        <Text color="placeholder">
                                            Drop or paste a URL here
                                        </Text>
                                    }
                                    onChange={async ({ target: { value } }) => {
                                        setNewDocuments([value]);
                                    }}
                                    onPaste={(e) => {
                                        const pastedHTML =
                                            e.clipboardData.getData(
                                                "text/html"
                                            );
                                        if (pastedHTML) {
                                            const parser = new DOMParser();
                                            const webloc =
                                                parser.parseFromString(
                                                    pastedHTML,
                                                    "text/html"
                                                );
                                            const links =
                                                webloc.getElementsByTagName(
                                                    "a"
                                                );
                                            const urls = Array.from(links)
                                                .filter((a, i, all) => {
                                                    if (!a.href) return false;
                                                    const { hostname } =
                                                        new URL(a.href);

                                                    // dedup
                                                    if (
                                                        all
                                                            .slice(0, i)
                                                            .find((other) => {
                                                                const res =
                                                                    other.href &&
                                                                    other.href.startsWith(
                                                                        a.href.split(
                                                                            "#"
                                                                        )[0]
                                                                    );
                                                                if (res) {
                                                                    return true;
                                                                }
                                                                return false;
                                                            })
                                                    ) {
                                                        return false;
                                                    }

                                                    return (
                                                        !hostname.includes(
                                                            ".google."
                                                        ) &&
                                                        !hostname.includes(
                                                            "youtube."
                                                        )
                                                    );
                                                })
                                                .map((a) => a.href);

                                            if (urls && urls.length > 0) {
                                                setPastedURLs(urls);
                                                return;
                                            }
                                        }
                                        setPastedURLs(undefined);
                                    }}
                                />
                                {pastedURLs && (
                                    <Box
                                        margin="small"
                                        // height="medium"
                                    >
                                        <List
                                            data={pastedURLs}
                                            paginate
                                            step={10}
                                            // eslint-disable-next-line react/no-unstable-nested-components
                                            action={(trashURL) => (
                                                <Button
                                                    plain
                                                    icon={
                                                        <Trash size="small" />
                                                    }
                                                    onClick={() =>
                                                        setPastedURLs(
                                                            pastedURLs.filter(
                                                                (url) =>
                                                                    url !==
                                                                    trashURL
                                                            )
                                                        )
                                                    }
                                                />
                                            )}
                                            defaultItemProps={{
                                                size: "xsmall",
                                            }}
                                        >
                                            {(url) => (
                                                <Box
                                                    onClick={() => {
                                                        window.open(
                                                            url,
                                                            "_blank",
                                                            "noopener,noreferrer"
                                                        );
                                                    }}
                                                >
                                                    <Text
                                                        size="xsmall"
                                                        truncate
                                                    >
                                                        {url}
                                                    </Text>
                                                </Box>
                                            )}
                                        </List>
                                    </Box>
                                )}
                                <Box direction="row" justify="end" gap="medium">
                                    <Button
                                        secondary
                                        label="Cancel"
                                        onClick={() => history.replace("docs")}
                                    />
                                    <Button
                                        label={
                                            (pastedURLs || newDocuments) &&
                                            (pastedURLs || newDocuments)
                                                .length > 0
                                                ? `Add ${
                                                      (
                                                          pastedURLs ||
                                                          newDocuments
                                                      ).length
                                                  } URLs`
                                                : "Add"
                                        }
                                        disabled={
                                            !newDocuments ||
                                            (pastedURLs || newDocuments)
                                                .length > allowedDocs
                                        }
                                        onClick={async () => {
                                            setUploading(true);
                                            if (pastedURLs) {
                                                setNewDocuments(pastedURLs);
                                            }
                                            await onAddURLs(
                                                pastedURLs || newDocuments
                                            );
                                            setUploading(false);
                                        }}
                                    />
                                </Box>
                                <Box
                                    direction="row"
                                    justify="end"
                                    pad={{ bottom: "xlarge" }}
                                >
                                    {!uploading &&
                                        (pastedURLs || newDocuments) &&
                                        (pastedURLs || newDocuments).length >
                                            allowedDocs && (
                                            <QuotaAlert
                                                allowedDocs={allowedDocs}
                                                maxDocs={maxDocs}
                                            />
                                        )}
                                </Box>
                            </Box>
                        </Tab>

                        <Tab title="Add Web Results" margin={{ top: "small" }}>
                            <Box gap="medium" margin={{ top: "medium" }}>
                                <Text color="grey">
                                    Use web search to find articles and content.
                                    Check and uncheck the boxes next to the
                                    results to manage what content you add to
                                    your project
                                </Text>
                                <GoogleSearch
                                    autoAddCount={Math.min(10, allowedDocs)}
                                    onResults={(urls) => {
                                        setNewDocuments(urls);
                                    }}
                                />
                            </Box>
                            <Box
                                margin={{ top: "medium" }}
                                pad={{ top: "medium", bottom: "medium" }}
                                direction="row"
                                justify="end"
                                gap="medium"
                                // border={newDocuments"top"
                            >
                                <Button
                                    secondary
                                    label="Cancel"
                                    onClick={() => history.replace("docs")}
                                />

                                <Button
                                    disabled={
                                        !newDocuments ||
                                        newDocuments.length === 0 ||
                                        newDocuments.length > allowedDocs
                                    }
                                    label={
                                        newDocuments && newDocuments.length > 0
                                            ? `Add ${newDocuments.length} URLs`
                                            : "Add"
                                    }
                                    onClick={() => onAddURLs(newDocuments)}
                                />
                            </Box>
                            <Box
                                direction="row"
                                justify="end"
                                pad={{ bottom: "xlarge" }}
                            >
                                {!uploading &&
                                    newDocuments &&
                                    newDocuments.length > allowedDocs && (
                                        <QuotaAlert
                                            allowedDocs={allowedDocs}
                                            maxDocs={maxDocs}
                                        />
                                    )}
                            </Box>
                        </Tab>

                        <Tab title="Add Text" margin={{ top: "small" }}>
                            <Box
                                fill
                                width="large"
                                height="medium"
                                gap="medium"
                                margin={{ vertical: "medium" }}
                            >
                                <Text
                                    color="grey"
                                    margin={{ bottom: "medium" }}
                                >
                                    Enter or paste plain text below and give the
                                    snippet a name
                                </Text>
                                <TextInput
                                    placeholder="Enter a name"
                                    value={textFilename}
                                    onChange={({ target: { value } }) =>
                                        setTextFilename(value)
                                    }
                                />
                                <Box height={{ min: "medium" }}>
                                    <TextArea
                                        fill
                                        resize="vertical"
                                        placeholder="Type or paste some text here"
                                        onChange={({ target: { value } }) =>
                                            setNewDocuments([value])
                                        }
                                    />
                                </Box>
                                <Box
                                    direction="row"
                                    justify="end"
                                    gap="medium"
                                    pad={{ right: "medium" }}
                                >
                                    <Button
                                        secondary
                                        label="Cancel"
                                        onClick={() => history.replace("docs")}
                                    />
                                    <Button
                                        label="Add"
                                        disabled={
                                            !newDocuments ||
                                            newDocuments.length > allowedDocs
                                        }
                                        onClick={async () => {
                                            setUploading(true);
                                            addedDocumentsCount = 0;

                                            try {
                                                const filename =
                                                    textFilename ||
                                                    `text-${Date.now()}.txt`;
                                                const {
                                                    data: {
                                                        createUploadUrl: {
                                                            name,
                                                            url,
                                                        },
                                                    },
                                                } = await createUploadUrl({
                                                    variables: {
                                                        conceptSpaceId,
                                                        name: filename,
                                                    },
                                                });

                                                const contentType =
                                                    "text/plain";
                                                const requestOptions = {
                                                    method: "PUT",
                                                    headers: {
                                                        "Content-Type":
                                                            contentType,
                                                    },
                                                    body: newDocuments[0],
                                                };

                                                const response = await fetch(
                                                    url,
                                                    requestOptions
                                                );
                                                if (response.ok) {
                                                    await addDocument({
                                                        variables: {
                                                            conceptSpaceId,
                                                            name,
                                                            contentType,
                                                        },
                                                    });
                                                    setAddedDocument(name);
                                                } else {
                                                    throw new Error(
                                                        response.status
                                                    );
                                                }
                                                addedDocumentsCount += 1;
                                            } catch (e) {
                                                setNewDocumentsError(e);
                                            } finally {
                                                setUploading(false);
                                            }
                                        }}
                                    />
                                </Box>
                                <Box
                                    direction="row"
                                    justify="end"
                                    pad={{ bottom: "xlarge" }}
                                >
                                    {!uploading &&
                                        newDocuments &&
                                        newDocuments.length > allowedDocs && (
                                            <QuotaAlert
                                                allowedDocs={allowedDocs}
                                                maxDocs={maxDocs}
                                            />
                                        )}
                                </Box>
                            </Box>
                        </Tab>
                    </Tabs>
                </ThemeContext.Extend>
            </PageContent>
        </Page>
    );
}

export default AddDocuments;
