import React from "react";

import { Box, Heading, Image, Spinner, Text } from "grommet";
import ErrorBox from "./ErrorBox";
import ContentCard from "./ContentCard";
import { HighlightsIcon } from "../icons";
// import SelectableText from "./SelectableText";

function Highlights({ loading, error, highlights }) {
    return (
        <ContentCard
            icon={<Image src={HighlightsIcon} width="20px" height="20px" />}
            header={
                <Heading
                    id="highlights"
                    level={4}
                    color="primaryLabel"
                    margin="none"
                >
                    Highlights
                </Heading>
            }
            content={
                <Box
                    as="ul"
                    margin={{ left: "20px" }}
                    pad={{
                        left: "medium",
                        right: "medium",
                        bottom: "medium",
                    }}
                    gap="small"
                >
                    {loading && !error && <Spinner alignSelf="center" />}
                    {error && <ErrorBox error={error} />}
                    {highlights &&
                        highlights.map((highlight) => (
                            <Text color="grey" as="li" key={highlight}>
                                {/* <SelectableText canBookmark={false}> */}
                                {highlight}
                                {/* </SelectableText> */}
                            </Text>
                        ))}
                </Box>
            }
        />
    );
}

export default Highlights;
