import React, { useEffect } from "react";
import PropTypes from "prop-types";

import Bugsnag from "@bugsnag/js";

import { useHistory } from "react-router-dom";

import { Box, Notification, Text } from "grommet";

function getTitle(error) {
    if (error) {
        if (error.networkError) {
            return "Network Error";
        }
        if (error.graphQLErrors) {
            return "API Error";
        }
    }
    return "Internal Error";
}

function getMessage(error) {
    const { cause, networkError } = error || {};
    if (networkError) {
        if (networkError.name === "ServerParseError") {
            return `${networkError.response.statusText} (${networkError.statusCode})`;
        }
        return networkError.message || error.message;
    }
    if (cause) {
        return cause.message;
    }
    return "Something went wrong with that request";
}

function ErrorBox({
    error,
    canRetry = true,
    retry = () => window.location.reload(),
    canGoBack = true,
}) {
    const history = useHistory();

    useEffect(() => {
        if (error) {
            Bugsnag.notify(error);
        }
    }, []);

    const actions = canGoBack
        ? [
              {
                  onClick: () => {
                      history.goBack();
                      window.location.reload();
                  },
                  label: "Go Back",
              },
          ]
        : [];

    if (canRetry) {
        actions.unshift({
            onClick: retry,
            label: "Retry",
        });
    }

    return (
        <Notification
            toast={{ autoClose: false }}
            status="critical"
            title={getTitle(error)}
            message={
                <Box margin={{ vertical: "small" }}>
                    <Text>{getMessage(error)}</Text>
                    {error && error.cause && (
                        <Text size="small">{error.cause.message}</Text>
                    )}
                </Box>
            }
            onClose={() => {}}
            actions={actions}
        />
    );
}
ErrorBox.propTypes = {
    error: PropTypes.instanceOf(Error).isRequired,
};

export default ErrorBox;
