import { getApp } from "firebase/app";
import {
    collection,
    doc,
    getDocs,
    getFirestore,
    query,
    where,
} from "firebase/firestore";
import {
    getProducts,
    getStripePayments,
} from "@stripe/firestore-stripe-payments";
import dayjs from "dayjs";

const getPlans = async () => {
    const payments = getStripePayments(getApp(), {
        productsCollection: "products",
        customersCollection: "users",
    });
    const products = await getProducts(payments, {
        includePrices: true,
        activeOnly: true,
    });
    return products.sort(
        ({ metadata: { tier: a = 10 } }, { metadata: { tier: b = 10 } = {} }) =>
            a - b
    );
};

const getSubscription = async (user) => {
    const db = getFirestore();

    const customersCollection = collection(db, "users");
    const currentUserDoc = doc(customersCollection, user.uid);
    const subscriptionsQuery = query(
        collection(currentUserDoc, "subscriptions"),
        where("status", "in", ["trialing", "active"])
    );

    const subscriptionsSnapshot = await getDocs(subscriptionsQuery);

    if (!subscriptionsSnapshot.empty) {
        return subscriptionsSnapshot.docs[0].data();
    }
    // No matching documents, so do something else.
    return null;
};

const getQuota = async (planId) => {
    const plans = await getPlans();
    const plan = plans.find(({ role }) => !planId || role === planId);
    return plan?.metadata;
};

const getUsagePeriod = async (user) => {
    const subscription = await getSubscription(user);

    if (subscription) {
        //
        return [
            dayjs.unix(subscription.current_period_start.seconds),
            dayjs.unix(subscription.current_period_end.seconds),
        ];
    }
    const startDate = user.metadata.creationTime;
    const subscriptionPeriod = 1;
    const signupDate = dayjs(startDate);

    // Get the current date
    const currentDate = dayjs();
    const monthsSinceSignup = currentDate.diff(signupDate, "month");

    const subscriptionPeriodsSinceSignup = Math.floor(
        monthsSinceSignup / subscriptionPeriod
    );

    const currentPeriodStartDate = signupDate.add(
        subscriptionPeriodsSinceSignup * subscriptionPeriod,
        "month"
    );
    const currentPeriodEndDate = currentPeriodStartDate.add(
        subscriptionPeriod,
        "month"
    );

    return [currentPeriodStartDate, currentPeriodEndDate];
};

export { getPlans, getSubscription, getQuota, getUsagePeriod };
