import React from "react";

import { Button, ThemeContext } from "grommet";

function Pill({ ...props }) {
    return (
        <ThemeContext.Extend
            value={{
                button: {
                    default: {
                        height: "30px",
                        color: "grey",
                        border: {
                            width: 1,
                            color: "pillBorder",
                            radius: "large",
                        },
                        extend: `
                            font-size: 14px;
                            font-weight: 700;
                            filter: none;
                            `,
                    },
                    active: {
                        color: "primaryLabel",
                        border: { color: "primaryLabel" },
                        background: { color: "inherit" },
                    },
                    hover: {
                        default: {
                            extend: "filter: none;",
                        },
                    },
                },
            }}
        >
            <Button margin={{ right: "10px", bottom: "10px" }} {...props} />
        </ThemeContext.Extend>
    );
}

export default Pill;
