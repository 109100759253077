import React from "react";

import { Anchor, Box, Button, Heading, Text } from "grommet";

function InfoBox({
    size = "small",
    title,
    text,
    actionLabel = "Learn more",
    action,
}) {
    if (size === "small") {
        return (
            <Box
                background="white"
                round="small"
                pad={{ vertical: "small", horizontal: "medium" }}
                width={{ max: "large" }}
            >
                <Text margin={{ top: "xsmall" }}>
                    <Text size="small" weight={700}>
                        {title}
                    </Text>{" "}
                    <Text size="small">{text}</Text>{" "}
                    {action && (
                        <Anchor
                            size="small"
                            label={actionLabel}
                            color="text"
                            style={{
                                whiteSpace: "nowrap",
                                textDecoration: "underline",
                                textDecorationThickness: "0.5px",
                                textUnderlineOffset: "2px",
                            }}
                            onClick={action}
                        />
                    )}
                </Text>
            </Box>
        );
    }

    return (
        <Box
            background="white"
            round="small"
            pad={{ horizontal: "medium" }}
            width={{ max: "large" }}
        >
            <Heading level={2}>{title}</Heading>
            <Text size="small">{text}</Text>
            <Box direction="row" margin={{ vertical: "medium" }}>
                <Button secondary label={actionLabel} onClick={action} />
            </Box>
        </Box>
    );
}

export default InfoBox;
