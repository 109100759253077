import React, { useState } from "react";

import { Button } from "grommet";
import { Checkmark, Trash } from "grommet-icons";

function DeleteButton({ onDelete, size, ...props }) {
    const [confirmed, setConfirmed] = useState(false);

    const handleButtonClick = () => {
        if (confirmed) {
            onDelete();
            setConfirmed(false);
        } else {
            setConfirmed(true);
        }
    };

    const handleBlur = () => {
        if (confirmed) {
            setConfirmed(false);
        }
    };

    return (
        <Button
            icon={confirmed ? <Checkmark size={size} /> : <Trash size={size} />}
            onClick={handleButtonClick}
            onBlur={handleBlur}
            {...props}
        />
    );
}

export default DeleteButton;
