import React from "react";
import PropTypes from "prop-types";
import { css } from "styled-components";

import { Box, Button, Paragraph, Text, ThemeContext } from "grommet";
import { normalizeColor } from "grommet/utils";

import { MdOutlineViewDay } from "react-icons/md";

import SelectableText from "./SelectableText";

function HighlightedText({ props, children }) {
    return (
        <ThemeContext.Extend
            value={{
                text: {
                    extend: ({ theme: styleTheme }) => css`
                        background-color: ${normalizeColor(
                            "highlight",
                            styleTheme
                        )};
                    `,
                },
            }}
        >
            <Text {...props}>{children}</Text>
        </ThemeContext.Extend>
    );
}

function getAnnotatedText(sentence, annotations) {
    if (!annotations || annotations.length === 0) {
        return sentence;
    }
    // ensure annotations are applied in order
    const spanOffsets = [...annotations].sort((a, b) => a[0] - b[0]);

    // remove duplicate spans
    const uniqueSpanOffsets = spanOffsets.filter(
        (s, index) =>
            spanOffsets.map(([start]) => start).indexOf(s[0]) === index
    );

    const textSpans = [];
    let offset = 0;
    for (let i = 0; i < uniqueSpanOffsets.length; i += 1) {
        const [start, end] = uniqueSpanOffsets[i];
        if (
            i > 0 &&
            start - 1 === uniqueSpanOffsets[i - 1][1] &&
            sentence.slice(offset, start) === " "
        ) {
            // merge adjacent spans
            textSpans.push(
                <HighlightedText>{sentence.slice(offset, end)}</HighlightedText>
            );
        } else {
            textSpans.push(sentence.slice(offset, start));
            textSpans.push(
                <HighlightedText>{sentence.slice(start, end)}</HighlightedText>
            );
        }
        offset = end;
    }
    textSpans.push(sentence.slice(offset));
    return textSpans;
}

function Mention({ sentence, annotations, highlighted, onViewDocument }) {
    return (
        <Box
            margin={{ left: "small" }}
            pad={{ left: "small", right: "medium" }}
            background={highlighted ? "rgb(218, 234, 251)" : null}
        >
            <ThemeContext.Extend
                value={{
                    paragraph: {
                        extend: ({ theme }) => `
                            color: ${theme.global.colors.grey};
                            font-size: 16px;        
                            line-height: 19px;
                        `,
                    },
                }}
            >
                <Paragraph fill margin={{ vertical: "xsmall" }}>
                    <SelectableText
                        extraMenuItems={[
                            {
                                label: (
                                    <Button
                                        alignSelf="start"
                                        plain
                                        icon={<MdOutlineViewDay size={25} />}
                                        label={
                                            <Box>
                                                <Text
                                                    size="small"
                                                    color="dark-3"
                                                >
                                                    View in document
                                                </Text>
                                            </Box>
                                        }
                                        onClick={onViewDocument}
                                    />
                                ),
                            },
                        ]}
                    >
                        {getAnnotatedText(sentence, annotations)}
                    </SelectableText>
                </Paragraph>
            </ThemeContext.Extend>
        </Box>
    );
}
Mention.propTypes = {
    sentence: PropTypes.string.isRequired,
    annotations: PropTypes.arrayOf(
        PropTypes.arrayOf(PropTypes.number.isRequired)
    ),
    highlighted: PropTypes.bool,
    onViewDocument: PropTypes.func,
};
Mention.defaultProps = {
    annotations: [],
    highlighted: false,
    onViewDocument: () => {},
};

export default Mention;
