import React from "react";

import { Box, Card, CardBody, CardHeader, ResponsiveContext } from "grommet";

function ContentCard({ icon, header, content }) {
    const size = React.useContext(ResponsiveContext);
    return (
        <Box direction="row" align="start" margin={{ bottom: "large" }}>
            {size !== "small" && (
                <Box
                    width="20px"
                    height="20px"
                    margin={{
                        top: "20px",
                        right: "15px",
                    }}
                >
                    {icon}
                </Box>
            )}
            <Card background="white" round="xsmall" pad="20px" fill>
                <CardHeader margin="none">{header}</CardHeader>
                <CardBody pad="none">{content}</CardBody>
            </Card>
        </Box>
    );
}

export default ContentCard;
