import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { debounce, escapeRegExp } from "lodash";

import { useParams } from "react-router-dom";

import {
    Box,
    Drop,
    Keyboard,
    Spinner,
    Text,
    TextInput,
    ThemeContext,
} from "grommet";
import { gql, useLazyQuery } from "@apollo/client";
import { Search } from "grommet-icons";

const GET_SEARCH_SUGGESTIONS = gql`
    query SearchSuggestions(
        $conceptSpacesWhere: ConceptSpaceWhere
        $themesWhere: ThemeWhere
        $conceptsWhere: ConceptsWhere
    ) {
        conceptSpaces(where: $conceptSpacesWhere) {
            themes(where: $themesWhere) {
                name
                importance
            }
            concepts(where: $conceptsWhere) {
                id
                name
                entityType
                source
            }
        }
    }
`;

function SearchTextInput({
    text,
    size,
    suggestions,
    suggestionsLoading,
    onSearch,
    onChange,
}) {
    const inputRef = useRef();

    const debouncedChangeHandler = useCallback(
        debounce(() => {
            onChange(inputRef.current.value);
        }, 300),
        [inputRef.current]
    );

    useEffect(() => {
        if (text && inputRef.current) inputRef.current.value = text;
    }, [inputRef.current]);

    return (
        <Keyboard
            onEnter={({ target: { value } }) => {
                if (suggestions) {
                    const match = suggestions.find(
                        ({ value: { name } }) => name === value
                    );
                    if (match) {
                        onSearch(match.value);
                        return;
                    }
                }

                onSearch(value);
            }}
        >
            <TextInput
                size={size}
                placeholder="Tips: Search for concepts and ideas, ask questions, look for evidence"
                type="search"
                ref={inputRef}
                onChange={debouncedChangeHandler}
                suggestions={suggestions}
                onSuggestionSelect={({ suggestion }) => {
                    inputRef.current.value = suggestion.value.name;
                    onChange(suggestion.value.name);
                }}
                icon={
                    <Box
                        pad={size === "small" ? "8px" : "18px"}
                        // background="#EFEDEC"
                        // round="6px"
                    >
                        {suggestionsLoading ? (
                            <Spinner />
                        ) : (
                            <Search size={size === "small" ? "18px" : "30px"} />
                        )}
                    </Box>
                }
                reverse
            />
        </Keyboard>
    );
}

function SearchBar({ text, size, onChange, onSearch }) {
    const { conceptSpaceId } = useParams();
    const [suggestions, setSuggestions] = useState([]);
    const targetRef = useRef();

    const [suggestionsQuery, { loading: suggestionsLoading }] = useLazyQuery(
        GET_SEARCH_SUGGESTIONS,
        {
            onCompleted: ({
                conceptSpaces: [{ themes = [], concepts = [] }],
            }) => {
                setSuggestions([
                    ...themes.map((theme) => ({
                        label: (
                            <Box pad="small">
                                <Text
                                    size="xsmall"
                                    weight={600}
                                    color="dark-2"
                                    margin={{ right: "xsmall" }}
                                >
                                    THEME
                                </Text>
                                {theme.name}
                            </Box>
                        ),
                        value: { type: "theme", name: theme.name },
                    })),
                    ...concepts.map((concept) => ({
                        label: concept.name,
                        value: {
                            type: "concept",
                            id: concept.id,
                            name: concept.name,
                        },
                    })),
                ]);
            },
        }
    );

    useEffect(() => {
        if (text) {
            suggestionsQuery({
                variables: {
                    conceptSpacesWhere: {
                        id: conceptSpaceId,
                    },
                    themesWhere: {
                        OR: [{ hidden: null }, { hidden_NOT: true }],
                        name_MATCHES: `(?i)${
                            text.length > 2 ? ".*" : ""
                        }${escapeRegExp(text)}.*`,
                    },
                    conceptsWhere: {
                        name_MATCHES: `(?i)${
                            text.length > 3 ? ".*" : ""
                        }${escapeRegExp(text)}.*`,
                    },
                },
            });
        } else {
            setSuggestions([]);
        }
    }, [text]);

    return (
        <ThemeContext.Extend
            value={{
                global: {
                    control: {
                        border: { radius: "10px", color: "white" },
                    },
                    input: {
                        ...(size !== "small" && {
                            padding: {
                                right: "6px",
                            },
                        }),
                        ...(size === "small" && {
                            padding: {
                                right: "5px",
                            },
                        }),
                        font: {
                            size: size === "large" && text ? "32px" : "16px",
                            weight: 400,
                        },
                    },
                },
                textInput: {
                    extend: `
                        height: ${size === "small" ? 44 : 78}px;
                        padding-left: ${size === "small" ? 20 : 24}px;
                        padding-right: ${size === "small" ? 60 : 100}px;
                        `,
                    container: {
                        extend: `
                        background: white;
                        border-radius: 10px;
                        filter: drop-shadow(0px 8px 10px rgba(41, 70, 152, 0.05));`,
                    },
                },
            }}
        >
            <Box ref={targetRef}>
                <SearchTextInput
                    size={size}
                    text={text}
                    suggestions={suggestions}
                    onChange={(value) => {
                        onChange(value);
                    }}
                    onSearch={onSearch}
                />
            </Box>
            {targetRef.current && (
                <Drop
                    stretch={false}
                    plain
                    overflow="unset"
                    target={targetRef.current}
                    align={{ right: "left" }}
                >
                    <Box
                        pad={{ horizontal: "medium", vertical: "xsmall" }}
                        justify="end"
                        flex="shrink"
                    >
                        {suggestionsLoading && <Spinner size="xsmall" />}
                    </Box>
                </Drop>
            )}
        </ThemeContext.Extend>
    );
}
SearchBar.propTypes = {
    text: PropTypes.string,
    size: PropTypes.string,
    onChange: PropTypes.func,
    onSearch: PropTypes.func.isRequired,
};
SearchBar.defaultProps = {
    text: undefined,
    size: null,
    onChange: () => {},
};

export default SearchBar;
