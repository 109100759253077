import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import { useHistory, useParams } from "react-router-dom";

import { Anchor, Box, Heading, Image, Select, Spinner, Text } from "grommet";
import { DocumentText } from "grommet-icons";

import dayjs from "dayjs";

import Pill from "./Pill";
import ContentCard from "./ContentCard";

import { capitalize } from "../utils/textUtils";
import { ConceptsIcon } from "../icons";
import Mention from "./Mention";
import NoteContext from "./NoteContext";

function group(mentions) {
    const passages = mentions.reduce(
        (
            acc,
            {
                text,
                documentConnection: {
                    edges: [{ passage, sentence, node }],
                },
                mentionsConnection: { edges: annotations },
            }
        ) => {
            const current = acc.slice(-1).pop() || {};
            if (
                current.document &&
                current.document.path === node.path &&
                current.passage === passage
            ) {
                current.sentences.push({ sentence, text, annotations });
            } else {
                acc.push({
                    document: node,
                    passage,
                    sentences: [{ sentence, text, annotations }],
                });
            }
            return acc;
        },
        []
    );
    return passages.map((passage) => {
        const { sentences } = passage;
        return {
            ...passage,
            sentences: sentences.sort(
                ({ sentence: a }, { sentence: b }) => a - b
            ),
        };
    });
}

function getDocumentContext(document, passages, mentionContext) {
    const relevantPassages = passages
        .reduce((acc, passage) => {
            const {
                passage: passageId,
                document: { path: passageDocPath },
            } = passage;
            return passageDocPath === document.path &&
                !acc.find(
                    ({ passage: addedPassage }) => passageId === addedPassage
                )
                ? [...acc, passage]
                : acc;
        }, [])

        .sort(({ passage: a }, { passage: b }) => a - b);
    return {
        context: mentionContext,
        passages: relevantPassages,
    };
}

function getDocumentLabel({ title, path, date, sitename }) {
    const label = title || path;
    if (date && sitename) {
        return `${label} (${sitename}, ${dayjs(date).format("D MMM YYYY")})`;
    }
    if (date || sitename) {
        return `${label} (${sitename || dayjs(date).format("D MMM YYYY")})`;
    }
    return label;
}

function ConceptMentions({
    conceptsLabel,
    concepts,
    conceptsLoading,
    highlightedConcepts,
    selectedConcept,
    filters,
    selectedFilter,
    mentions,
    mentionContext,
    selectedMention,
    mentionsLoading,
    onSelectConcept,
    onChangeFilter,
}) {
    const history = useHistory();
    const { conceptSpaceId } = useParams();

    const [highlightStyle] = useState("highlight");
    const mentionRefs = useRef({});

    useEffect(() => {
        const target = mentionRefs.current[selectedMention];
        if (target) {
            target.scrollIntoView({
                behavior: "auto",
                block: "center",
                inline: "center",
            });
        }
    }, [selectedMention]);

    return (
        <>
            <ContentCard
                icon={<Image src={ConceptsIcon} height="20px" />}
                header={
                    <Box
                        direction="row-responsive"
                        justify="between"
                        gap="medium"
                        align="center"
                        fill
                    >
                        <Box direction="column" gap="xsmall">
                            <Heading
                                id="concepts"
                                level={4}
                                color="primaryLabel"
                                margin="none"
                            >
                                {conceptsLabel}
                            </Heading>
                            <Text size="xsmall">
                                Sorted by the most important concepts, select a
                                concept to see how it is referenced in the
                                source documents
                            </Text>
                        </Box>
                        {conceptsLoading && <Spinner alignSelf="center" />}
                        <Box flex="grow">
                            <Select
                                plain
                                value={selectedFilter}
                                options={Object.keys(filters)}
                                onChange={({ option }) => {
                                    onChangeFilter(option);
                                }}
                            />
                        </Box>
                    </Box>
                }
                content={
                    <Box direction="row" overflow="hidden" wrap>
                        {!conceptsLoading && concepts.length === 0 ? (
                            <Text>None found</Text>
                        ) : (
                            concepts.map((concept) => (
                                <Pill
                                    key={concept.id}
                                    active={
                                        selectedConcept &&
                                        selectedConcept.id === concept.id
                                    }
                                    label={capitalize(concept.name)}
                                    onClick={() => onSelectConcept(concept)}
                                />
                            ))
                        )}
                    </Box>
                }
            />
            <ContentCard
                id="mentions"
                header={
                    <Heading
                        id="mentions"
                        level={4}
                        color="primaryLabel"
                        margin="none"
                    >
                        {selectedConcept ? (
                            <span>
                                Mentions of{" "}
                                <Anchor
                                    weight={900}
                                    onClick={() =>
                                        history.push(
                                            `/${conceptSpaceId}/concepts/${encodeURIComponent(
                                                selectedConcept.id.replace(
                                                    "%",
                                                    "%25"
                                                )
                                            )}`
                                        )
                                    }
                                >
                                    {capitalize(selectedConcept.name)}
                                </Anchor>
                            </span>
                        ) : (
                            `All Mentions ${
                                filters[selectedFilter]
                                    ? ` of ${selectedFilter}`
                                    : ""
                            }`
                        )}
                    </Heading>
                }
                content={
                    <Box gap="small">
                        {mentionsLoading && <Spinner alignSelf="center" />}
                        {!mentionsLoading &&
                        !conceptsLoading &&
                        (!mentions || mentions.length === 0) ? (
                            <Text>None found</Text>
                        ) : (
                            mentions &&
                            group(mentions).map(
                                (
                                    { document, passage, sentences },
                                    p,
                                    passages
                                ) => {
                                    return (
                                        <NoteContext.Consumer>
                                            {(value) => (
                                                <Box
                                                    direction="row"
                                                    id={p + 1}
                                                    ref={(el) => {
                                                        mentionRefs.current[
                                                            p + 1
                                                        ] = el;
                                                    }}
                                                >
                                                    <Box
                                                        margin={{
                                                            top: "xsmall",
                                                        }}
                                                    >
                                                        <Text
                                                            color="silver"
                                                            size="xsmall"
                                                        >
                                                            {p + 1}.{" "}
                                                        </Text>
                                                    </Box>
                                                    <Box>
                                                        {sentences.map(
                                                            ({
                                                                sentence,
                                                                text,
                                                                annotations,
                                                            }) => (
                                                                <NoteContext.Provider
                                                                    // eslint-disable-next-line react/jsx-no-constructed-context-values
                                                                    value={{
                                                                        ...value,
                                                                        targetNode:
                                                                            {
                                                                                documentConnection:
                                                                                    {
                                                                                        edge: {
                                                                                            sentence,
                                                                                            passage,
                                                                                        },
                                                                                        node: {
                                                                                            uri: document.uri,
                                                                                        },
                                                                                    },
                                                                            },
                                                                    }}
                                                                >
                                                                    <Mention
                                                                        key={`${document.path}#${passage}.${sentence}`}
                                                                        sentence={
                                                                            text
                                                                        }
                                                                        source={
                                                                            document
                                                                        }
                                                                        highlighted={
                                                                            selectedMention ===
                                                                            p +
                                                                                1
                                                                        }
                                                                        annotations={annotations
                                                                            .filter(
                                                                                ({
                                                                                    node: {
                                                                                        id,
                                                                                        parent,
                                                                                    },
                                                                                }) =>
                                                                                    [
                                                                                        ...highlightedConcepts,
                                                                                        ...(selectedConcept
                                                                                            ? [
                                                                                                  selectedConcept,
                                                                                              ]
                                                                                            : []),
                                                                                    ].find(
                                                                                        ({
                                                                                            id: highlightedId,
                                                                                        }) =>
                                                                                            id ===
                                                                                                highlightedId ||
                                                                                            (parent &&
                                                                                                parent.id ===
                                                                                                    highlightedId)
                                                                                    )
                                                                            )
                                                                            .map(
                                                                                ({
                                                                                    startOffset,
                                                                                    endOffset,
                                                                                }) => [
                                                                                    startOffset,
                                                                                    endOffset,
                                                                                ]
                                                                            )}
                                                                        highlightStyle={
                                                                            highlightStyle
                                                                        }
                                                                        onViewDocument={() =>
                                                                            history.push(
                                                                                {
                                                                                    pathname: `/${conceptSpaceId}/docs/${document.path}`,
                                                                                    hash: `p=${passage}&s=${sentence}${
                                                                                        selectedConcept
                                                                                            ? `&c=${selectedConcept.id}`
                                                                                            : ""
                                                                                    }`,
                                                                                },
                                                                                getDocumentContext(
                                                                                    document,
                                                                                    passages,
                                                                                    mentionContext
                                                                                )
                                                                            )
                                                                        }
                                                                    />
                                                                </NoteContext.Provider>
                                                            )
                                                        )}
                                                        <Box
                                                            margin={{
                                                                left: "medium",
                                                                top: "small",
                                                                bottom: "large",
                                                            }}
                                                        >
                                                            <Text
                                                                margin={{
                                                                    left: "2px",
                                                                }}
                                                                wordBreak="break-all"
                                                            >
                                                                <Anchor
                                                                    plain
                                                                    color="grey"
                                                                    size="xsmall"
                                                                    weight={400}
                                                                    label={
                                                                        <Box
                                                                            direction="row"
                                                                            gap="small"
                                                                        >
                                                                            <Box
                                                                                margin={{
                                                                                    top: "4px",
                                                                                }}
                                                                            >
                                                                                <DocumentText
                                                                                    size="small"
                                                                                    color="silver"
                                                                                />
                                                                            </Box>
                                                                            <Text
                                                                                wordBreak="break-word"
                                                                                size="xsmall"
                                                                            >
                                                                                {getDocumentLabel(
                                                                                    document
                                                                                )}{" "}
                                                                                /
                                                                                #
                                                                                {passage +
                                                                                    1}
                                                                            </Text>
                                                                        </Box>
                                                                    }
                                                                    onClick={() =>
                                                                        history.push(
                                                                            {
                                                                                pathname: `/${conceptSpaceId}/docs/${document.path}`,
                                                                                hash: `p=${passage}${
                                                                                    mentionContext.type ===
                                                                                        "concept" &&
                                                                                    selectedConcept
                                                                                        ? `&c=${selectedConcept.id}`
                                                                                        : ""
                                                                                }`,
                                                                            },
                                                                            getDocumentContext(
                                                                                document,
                                                                                passages,
                                                                                mentionContext
                                                                            )
                                                                        )
                                                                    }
                                                                    target="_blank"
                                                                />
                                                            </Text>
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            )}
                                        </NoteContext.Consumer>
                                    );
                                }
                            )
                        )}
                    </Box>
                }
            />
        </>
    );
}
ConceptMentions.propTypes = {
    conceptsLabel: PropTypes.string,
    concepts: PropTypes.arrayOf(PropTypes.objectOf()).isRequired,
    conceptsLoading: PropTypes.bool.isRequired,
    highlightedConcepts: PropTypes.arrayOf(),
    selectedConcept: PropTypes.objectOf().isRequired,
    filters: PropTypes.objectOf().isRequired,
    selectedFilter: PropTypes.string.isRequired,
    mentions: PropTypes.arrayOf(PropTypes.objectOf()).isRequired,
    mentionsLoading: PropTypes.bool.isRequired,
    onSelectConcept: PropTypes.func.isRequired,
    onChangeFilter: PropTypes.func.isRequired,
};
ConceptMentions.defaultProps = {
    conceptsLabel: "Connected to this concept",
    highlightedConcepts: [],
};

export default ConceptMentions;
