import React, { Component } from "react"; // , { useEffect, useRef, useState }
import PropTypes from "prop-types";

import styled from "styled-components";

// import { Box, Spinner } from "grommet";
import {
    AreaHighlight,
    Highlight,
    PdfHighlighter,
    PdfLoader,
    // Popup,
    // Tip,
} from "react-pdf-highlighter";

// const HighlightPopup = ({ comment: { text, emoji } }) =>
//   text ? (
//     <div className="Highlight__popup">
//       {emoji} {text}
//     </div>
//   ) : null;

const PdfContainer = styled.div`
    width: 100%;
    height: 100%;
    position: relative;

    .PdfHighlighter {
        padding-left: 20px;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    .pdfViewer.removePageBorders {
        margin-top: 50px;
        margin-bottom: 50px;
    }

    .pdfViewer.removePageBorders .page {
        box-shadow: 0px 2px 4px rgb(0 0 0 / 40%);
        margin-bottom: 20px;
    }

    .concept .Highlight__part {
        background-color: transparent !important;
        border-bottom: 2px solid #febbd9;
    }

    .currentConcept .Highlight__part {
        background-color: #febbd9 !important;
        border-bottom: 2px solid #febbd9;
    }

    // .currentPassage .Highlight__part {
    //     background-color: rgb(157, 203, 251) !important;
    // }
`;

class PDF extends Component {
    constructor(props) {
        super(props);
        this.pdfRef = React.createRef();
    }

    render() {
        const { url, highlights, onError, onReady, onSelection } = this.props;
        return (
            <PdfContainer>
                <PdfLoader url={url} onError={onError}>
                    {(pdfDocument) => (
                        <PdfHighlighter
                            ref={this.pdfRef}
                            pdfDocument={pdfDocument}
                            enableAreaSelection={(event) => event.altKey}
                            onScrollChange={() => {}}
                            pdfScaleValue="auto"
                            scrollRef={(scrollTo) => {
                                // eslint-disable-next-line react/no-unused-class-component-methods
                                this.scrollViewerTo = (...args) => {
                                    if (
                                        this.pdfRef.current.viewer
                                            .pageViewsReady
                                    ) {
                                        setTimeout(() => {
                                            scrollTo(...args);
                                        }, 1000);
                                    } else {
                                        setTimeout(() => {
                                            this.scrollViewerTo(...args);
                                        }, 500);
                                    }
                                };
                                onReady();
                            }}
                            onSelectionFinished={onSelection}
                            highlightTransform={(
                                highlight,
                                index,
                                setTip,
                                hideTip,
                                viewportToScaled,
                                screenshot,
                                isScrolledTo
                            ) => {
                                const isTextHighlight = !(
                                    highlight.content && highlight.content.image
                                );

                                const component = isTextHighlight ? (
                                    <div className={highlight.type}>
                                        <Highlight
                                            isScrolledTo={isScrolledTo}
                                            position={highlight.position}
                                            comment={highlight.comment}
                                            onClick={() => {
                                                // ignore
                                            }}
                                        />
                                    </div>
                                ) : (
                                    <AreaHighlight
                                        isScrolledTo={isScrolledTo}
                                        highlight={highlight}
                                        onChange={() => {
                                            // this.updateHighlight(
                                            //   highlight.id,
                                            //   { boundingRect: viewportToScaled(boundingRect) },
                                            //   { image: screenshot(boundingRect) }
                                            // );
                                        }}
                                    />
                                );

                                return component;
                                // return (
                                //   <Popup
                                //     popupContent={<HighlightPopup {...highlight} />}
                                //     onMouseOver={(popupContent) => {
                                //       console.log(highlight);
                                //       console.log(popupContent);
                                //       //   setTip(highlight, (highlight) => popupContent);
                                //     }}
                                //     onMouseOut={hideTip}
                                //     key={index}
                                //   >
                                //     {component}
                                //   </Popup>
                                // );
                            }}
                            highlights={highlights}
                        />
                    )}
                </PdfLoader>
            </PdfContainer>
        );
    }
}

PDF.propTypes = {
    url: PropTypes.string.isRequired,
    highlights: PropTypes.arrayOf(PropTypes.objectOf({})),
    onError: PropTypes.func,
    onReady: PropTypes.func,
    onSelection: PropTypes.func,
};

PDF.defaultProps = {
    highlights: [],
    onError: () => {},
    onReady: () => {},
    onSelection: () => {},
};

export default PDF;
