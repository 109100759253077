import React, { useRef, useState } from "react";

import { Box, Button, Drop, List } from "grommet";
import { ThemeContext } from "styled-components";
import NoteContext from "./NoteContext";
import BookmarkButton from "./BookmarkButton";

function SelectableText({ extraMenuItems = [], children }) {
    const [selected, setSelected] = useState(false);
    const targetRef = useRef();

    return (
        <ThemeContext.Consumer>
            {(theme) => (
                <>
                    <Button
                        plain
                        // onBlur={() => setSelected(false)}
                        focusIndicator={false}
                        onClick={() => {
                            setSelected(true);
                        }}
                        style={{
                            background:
                                selected && theme.global.colors.highlight,
                        }}
                        ref={targetRef}
                    >
                        {children}
                    </Button>
                    {targetRef.current && selected && (
                        <ThemeContext.Extend
                            value={{
                                global: {
                                    drop: {
                                        background: "red",
                                        extend: `filter: drop-shadow(0px 4px 4px rgba(118, 127, 173, 0.3));`,
                                    },
                                },
                            }}
                        >
                            <Drop
                                stretch={false}
                                target={targetRef.current}
                                align={{ top: "bottom", right: "right" }}
                                margin={{ vertical: "xsmall", right: "xsmall" }}
                                background="backgroundPopover"
                                elevation="none"
                                round="xsmall"
                                onClickOutside={() => setSelected(false)}
                                onEsc={() => setSelected(false)}
                            >
                                <NoteContext.Consumer>
                                    {({ targetNode, conceptSpaceId }) => {
                                        const menuItems = [
                                            {
                                                label: (
                                                    <BookmarkButton
                                                        conceptSpaceId={
                                                            conceptSpaceId
                                                        }
                                                        targetType="Sentence"
                                                        targetNode={targetNode}
                                                    />
                                                ),
                                                onClick: () => {},
                                            },
                                            ...extraMenuItems,
                                        ];

                                        return (
                                            <Box overflow="auto">
                                                <List
                                                    plain
                                                    border={false}
                                                    pad={{
                                                        left: "xsmall",
                                                        right: "medium",
                                                        vertical: "small",
                                                    }}
                                                    defaultItemProps={{
                                                        size: "xsmall",
                                                    }}
                                                    data={menuItems}
                                                    onClickItem={({
                                                        item: { onClick },
                                                    }) => onClick()}
                                                />
                                            </Box>
                                        );
                                    }}
                                </NoteContext.Consumer>
                            </Drop>
                        </ThemeContext.Extend>
                    )}
                </>
            )}
        </ThemeContext.Consumer>
    );
}

export default SelectableText;
