import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import {
    Box,
    Button,
    DataTable,
    Heading,
    Page,
    PageContent,
    ResponsiveContext,
    Spinner,
    Text,
} from "grommet";
import { Trash } from "grommet-icons";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import isToday from "dayjs/plugin/isToday";
import minMax from "dayjs/plugin/minMax";
import localizedFormat from "dayjs/plugin/localizedFormat";

import { gql, useMutation, useQuery } from "@apollo/client";

import ErrorBox from "./components/ErrorBox";

import { getLabel, getLocation, getTypeLabel } from "./utils/bookmarkUtils";
import Modal from "./components/Modal";
import InfoBox from "./components/InfoBox";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(isToday);
dayjs.extend(minMax);
dayjs.extend(localizedFormat);

const GET_BOOKMARKS = gql`
    query GetAllBookmarks($where: BookmarkWhere, $options: BookmarkOptions) {
        bookmarks(where: $where, options: $options) {
            id
            created
            targetConnection {
                edges {
                    node {
                        __typename
                        ... on Theme {
                            name
                        }
                        ... on Concept {
                            id
                            name
                        }
                        ... on QueryResult {
                            query
                        }
                        ... on Sentence {
                            text
                            documentConnection {
                                edges {
                                    passage
                                    sentence
                                    node {
                                        path
                                        uri
                                        title
                                    }
                                }
                            }
                        }
                        ... on Document {
                            path
                            uri
                            title
                        }
                    }
                }
            }
        }
        bookmarksAggregate(where: $where) {
            count
        }
    }
`;

const DELETE_BOOKMARKS = gql`
    mutation DeleteBookmarks($where: BookmarkWhere) {
        deleteBookmarks(where: $where) {
            nodesDeleted
        }
    }
`;

function UserNotes() {
    const history = useHistory();
    const { conceptSpaceId } = useParams();

    const size = React.useContext(ResponsiveContext);

    const [showDeleteDialog, setShowDeleteDialog] = useState(false);
    const [selected, setSelected] = useState([]);

    const { loading, error, data } = useQuery(GET_BOOKMARKS, {
        variables: {
            where: {
                conceptSpace: {
                    id: conceptSpaceId,
                },
            },
            options: {
                sort: [
                    {
                        created: "DESC",
                    },
                ],
            },
        },
    });

    const [deleteNotes, { loading: deleting, error: deleteError }] =
        useMutation(DELETE_BOOKMARKS, {
            refetchQueries: ["GetAllBookmarks", "GetRecentBookmarks"],
        });

    const { bookmarks = [] } = data || {};

    return (
        <Page kind="wide" flex="grow">
            <PageContent margin={{ top: "medium", bottom: "medium" }}>
                <Heading
                    level={2}
                    size={size}
                    margin={{ top: "large", bottom: "small" }}
                    pad="none"
                >
                    My notes
                </Heading>
                <Text fill>View and manage notes in this project.</Text>
            </PageContent>
            <PageContent>
                {deleteError && <ErrorBox error={deleteError} />}
                {deleting && <Spinner />}
                {showDeleteDialog && (
                    <Modal
                        onClickOutside={() => setShowDeleteDialog(false)}
                        onEsc={() => setShowDeleteDialog(false)}
                    >
                        <Box fill pad="medium">
                            <Heading
                                level={2}
                                size="small"
                                margin={{ horizontal: "large" }}
                            >
                                Delete {selected.length}{" "}
                                {selected.length > 1 ? "notes" : "note"}?
                            </Heading>
                            <Box
                                direction="row"
                                justify="between"
                                fill
                                gap="large"
                            >
                                <Button
                                    secondary
                                    label="Cancel"
                                    onClick={() => setShowDeleteDialog(false)}
                                />
                                <Button
                                    label="Delete"
                                    onClick={() => {
                                        deleteNotes({
                                            variables: {
                                                where: {
                                                    conceptSpace: {
                                                        id: conceptSpaceId,
                                                    },
                                                    id_IN: selected,
                                                },
                                            },
                                        });
                                        setShowDeleteDialog(false);
                                    }}
                                />
                            </Box>
                        </Box>
                    </Modal>
                )}
            </PageContent>
            <PageContent>
                {loading && <Spinner size="small" alignSelf="center" />}
                {error && <ErrorBox error={error} />}
                {bookmarks && bookmarks.length > 0 ? (
                    <Box
                        margin={{
                            bottom: "large",
                        }}
                    >
                        <DataTable
                            paginate
                            step={20}
                            border="bottom"
                            fill
                            sortable
                            columns={[
                                {
                                    property: "targetType",
                                    header: "Type",
                                    size: "10%",
                                    render: ({ targetType }) => (
                                        <Text size="small">{targetType}</Text>
                                    ),
                                },
                                {
                                    property: "label",
                                    header: "Note",
                                    search: true,
                                    render: ({
                                        label,
                                        location: { path, state },
                                    }) => (
                                        <Box
                                            pad={{ vertical: "small" }}
                                            onClick={() =>
                                                history.push(
                                                    `/${conceptSpaceId}${path}`,
                                                    state
                                                )
                                            }
                                        >
                                            <Text>{label}</Text>
                                        </Box>
                                    ),
                                },

                                {
                                    property: "created",
                                    header: "Created",
                                    render: ({ created }) => {
                                        const ts = dayjs(created);
                                        if (ts.isToday()) {
                                            return ts.fromNow();
                                        }
                                        return ts.format("lll");
                                    },
                                    size: "small",
                                },
                            ]}
                            data={bookmarks.map(
                                ({
                                    id,
                                    created,
                                    targetConnection: {
                                        edges: [{ node } = {}],
                                    },
                                }) => {
                                    if (!node)
                                        return {
                                            id,
                                            created,
                                            targetType: "?",
                                            label: (
                                                <i>
                                                    Linked item no longer exists
                                                </i>
                                            ),
                                            location: {},
                                        };
                                    const typeLabel = getTypeLabel(node);
                                    const label = getLabel(node);
                                    return {
                                        id,
                                        created,
                                        targetType: typeLabel,
                                        label,
                                        location: getLocation(node),
                                    };
                                }
                            )}
                            primaryKey="id"
                            select={selected}
                            onSelect={setSelected}
                        />
                        <Box margin="small">
                            {selected && selected.length > 0 && (
                                <Button
                                    plain
                                    icon={<Trash />}
                                    onClick={() => setShowDeleteDialog(true)}
                                />
                            )}
                        </Box>
                    </Box>
                ) : (
                    // </Box>
                    !loading && (
                        <Box
                            width={{ max: "large" }}
                            margin={{ vertical: "large" }}
                            // align="center"
                            gap="small"
                        >
                            <InfoBox
                                title="You do not have any notes in this project."
                                text="Save themes, concepts and mentions as notes to
                                have them appear in your summary."
                                actionLabel="Learn more"
                                action={() =>
                                    window.open(
                                        "https://same-judo-f78.notion.site/Archer-Help-084c04136b934873b6cea96702621473#e7a42c5374d74f27b1906260e9b8c49c",
                                        "_blank"
                                    )
                                }
                            />
                        </Box>
                    )
                )}
            </PageContent>
        </Page>
    );
}

export default UserNotes;
