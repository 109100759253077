import React from "react";

import { Box, Button, Spinner, Text } from "grommet";
import { BsBookmarkFill, BsBookmarkPlus } from "react-icons/bs";

import { gql, useMutation, useQuery } from "@apollo/client";
import { useAuth } from "./auth/AuthProvider";

const GET_BOOKMARK = gql`
    query Bookmarks($where: BookmarkWhere) {
        bookmarks(where: $where) {
            id
            created
        }
    }
`;

const ADD_BOOKMARK = gql`
    mutation AddBookmark($input: [BookmarkCreateInput!]!) {
        createBookmarks(input: $input) {
            bookmarks {
                id
                created
            }
        }
    }
`;

const REMOVE_BOOKMARK = gql`
    mutation RemoveBookmark($where: BookmarkWhere) {
        deleteBookmarks(where: $where) {
            nodesDeleted
        }
    }
`;

function BookmarkButton({
    conceptSpaceId,
    targetType,
    targetNode,
    label = "Save to notes",
}) {
    const {
        user: { email },
    } = useAuth();

    const { loading: bookmarkLoading, data: bookmarkData } = useQuery(
        GET_BOOKMARK,
        {
            variables: {
                where: {
                    conceptSpace: {
                        id: conceptSpaceId,
                    },
                    owner: { email },
                    targetConnection: {
                        [targetType]: {
                            node: targetNode,
                        },
                    },
                },
            },
            notifyOnNetworkStatusChange: true,
        }
    );

    const [addBookmark, { loading: addingBookmark }] = useMutation(
        ADD_BOOKMARK,
        {
            variables: {
                input: [
                    {
                        conceptSpace: {
                            connect: {
                                where: {
                                    node: {
                                        id: conceptSpaceId,
                                    },
                                },
                            },
                        },
                        owner: {
                            connect: {
                                where: {
                                    node: {
                                        email,
                                    },
                                },
                            },
                        },
                        target: {
                            [targetType]: {
                                connect: {
                                    where: {
                                        node: targetNode,
                                    },
                                },
                            },
                        },
                    },
                ],
            },
            refetchQueries: [
                GET_BOOKMARK,
                "GetRecentBookmarks",
                "GetAllBookmarks",
            ],
        }
    );

    const [removeBookmark, { loading: removingBookmark }] = useMutation(
        REMOVE_BOOKMARK,
        {
            refetchQueries: [
                GET_BOOKMARK,
                "GetRecentBookmarks",
                "GetAllBookmarks",
            ],
        }
    );

    const { bookmarks: [bookmark] = [null] } = bookmarkData || {};

    return (
        <Button
            alignSelf="start"
            plain
            icon={
                bookmark ? (
                    <BsBookmarkFill size={25} color="#003186" />
                ) : (
                    <BsBookmarkPlus size={25} />
                )
            }
            label={
                <Box>
                    {bookmarkLoading || addingBookmark || removingBookmark ? (
                        <Spinner size="xsmall" />
                    ) : (
                        <Text size="small" color="dark-3">
                            {bookmark ? "" : label}
                        </Text>
                    )}
                </Box>
            }
            onClick={() => {
                if (bookmark) {
                    removeBookmark({
                        variables: {
                            where: {
                                conceptSpace: {
                                    id: conceptSpaceId,
                                },
                                owner: { email },
                                id: bookmark.id,
                            },
                        },
                    });
                } else {
                    addBookmark();
                }
            }}
        />
    );
}

export default BookmarkButton;
