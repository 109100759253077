import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Box, Tab, Tabs, Text, ThemeContext } from "grommet";
import {
    FormDown,
    FormUp,
    Group,
    Info,
    Location,
    Organization,
} from "grommet-icons";

function ConceptList({
    concepts: { people, organisations, places, miscConcepts },
    selectedConcept,
    currentMention,
    onChange,
}) {
    const [activeTab, setActiveTab] = useState(0);

    useEffect(() => {
        const selectedConceptTab = selectedConcept
            ? [people, organisations, places, miscConcepts]
                  .filter((items) => items.length)
                  .findIndex((concepts) =>
                      concepts.find(({ id }) => id === selectedConcept)
                  )
            : 0;
        setActiveTab(selectedConceptTab);
    }, [selectedConcept]);

    return (
        <ThemeContext.Extend
            value={{
                tabs: {
                    gap: "4px",
                },
                tab: {
                    margin: { horizontal: "8px" },
                    active: {
                        color: "brand",
                    },
                },
            }}
        >
            <Box
                border={{ color: "light-5" }}
                background="white"
                round="xxsmall"
                pad="medium"
                gap="medium"
                margin={{ bottom: "small" }}
                height={{ min: "medium" }}
            >
                <Tabs
                    alignControls="start"
                    activeIndex={activeTab}
                    onActive={setActiveTab}
                >
                    {Object.entries({
                        People: { items: people, icon: Group },
                        Organisations: {
                            items: organisations,
                            icon: Organization,
                        },
                        Places: { items: places, icon: Location },
                        Concepts: { items: miscConcepts, icon: Info },
                    })
                        .filter(([, { items }]) => items && items.length)
                        .map(([label, { items, icon }], tab) => (
                            <Tab
                                key={label}
                                title={
                                    <Box
                                        align="center"
                                        margin={{ top: "xsmall" }}
                                    >
                                        {icon.render({
                                            color:
                                                tab === activeTab
                                                    ? "brand"
                                                    : null,
                                        })}
                                        <Text
                                            size="10px"
                                            weight="bold"
                                            margin={{ top: "xxsmall" }}
                                            color={
                                                tab === activeTab
                                                    ? "brand"
                                                    : null
                                            }
                                        >
                                            {label}
                                        </Text>
                                    </Box>
                                }
                            >
                                <Box margin={{ top: "medium" }}>
                                    {items.map((item) => {
                                        const selected =
                                            item &&
                                            selectedConcept &&
                                            item.id === selectedConcept;
                                        return (
                                            <Box
                                                key={item.id}
                                                direction="row"
                                                justify="between"
                                                align="center"
                                                round="medium"
                                                pad={{
                                                    horizontal: "small",
                                                    // vertical: "xxsmall",
                                                }}
                                                border={
                                                    selected
                                                        ? { color: "brand" }
                                                        : { color: "white" }
                                                }
                                                focusIndicator={false}
                                                onClick={() => {
                                                    if (selected) {
                                                        onChange(null, null);
                                                    } else {
                                                        onChange(item, 0);
                                                    }
                                                }}
                                            >
                                                <Box fill>
                                                    <Text
                                                        size="small"
                                                        focusIndicator={false}
                                                        margin="0.3em"
                                                        style={{
                                                            lineHeight: "1.2em",
                                                        }}
                                                    >
                                                        {item.name}
                                                    </Text>
                                                </Box>
                                                {selected && (
                                                    <Box
                                                        margin={{
                                                            left: "medium",
                                                        }}
                                                        direction="row-responsive"
                                                        align="center"
                                                        justify="end"
                                                        fill
                                                    >
                                                        <Box
                                                            align="end"
                                                            wrap={false}
                                                        >
                                                            <Text
                                                                size="xsmall"
                                                                color="brand"
                                                                style={{
                                                                    whiteSpace:
                                                                        "nowrap",
                                                                }}
                                                            >
                                                                {`${
                                                                    currentMention +
                                                                    1
                                                                } / ${
                                                                    item.count ||
                                                                    1
                                                                }`}
                                                            </Text>
                                                        </Box>
                                                        {item.count &&
                                                            item.count > 1 && (
                                                                <Box direction="row">
                                                                    <FormUp
                                                                        color="brand"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            e.nativeEvent.stopImmediatePropagation();
                                                                            onChange(
                                                                                item,
                                                                                currentMention >
                                                                                    0
                                                                                    ? currentMention -
                                                                                          1
                                                                                    : item.count -
                                                                                          1
                                                                            );
                                                                        }}
                                                                    />
                                                                    <FormDown
                                                                        color="brand"
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            e.stopPropagation();
                                                                            e.nativeEvent.stopImmediatePropagation();
                                                                            onChange(
                                                                                item,
                                                                                currentMention +
                                                                                    1 <
                                                                                    item.count
                                                                                    ? currentMention +
                                                                                          1
                                                                                    : 0
                                                                            );
                                                                        }}
                                                                    />
                                                                </Box>
                                                            )}
                                                    </Box>
                                                )}
                                            </Box>
                                        );
                                    })}
                                </Box>
                            </Tab>
                        ))}
                </Tabs>
            </Box>
        </ThemeContext.Extend>
    );
}

const ItemType = PropTypes.objectOf({
    name: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
    count: PropTypes.number,
});

ConceptList.propTypes = {
    concepts: PropTypes.objectOf({
        people: PropTypes.arrayOf(ItemType),
        organisations: PropTypes.arrayOf(ItemType),
        places: PropTypes.arrayOf(ItemType),
        miscConcepts: PropTypes.arrayOf(ItemType),
    }).isRequired,
    selectedConcept: PropTypes.objectOf(ItemType),
    currentMention: PropTypes.number,
    onChange: PropTypes.func,
};

ConceptList.defaultProps = {
    selectedConcept: undefined,
    currentMention: undefined,
    onChange: () => {},
};

export default ConceptList;
