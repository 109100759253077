import React, { useEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import {
    Anchor,
    Box,
    Button,
    Heading,
    Page,
    PageContent,
    Spinner,
    Text,
} from "grommet";

import { gql, useMutation, useQuery } from "@apollo/client";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import minMax from "dayjs/plugin/minMax";

import StepsDiagram from "./components/StepsDiagram";

import ErrorBox from "./components/ErrorBox";
import NotFound from "./components/NotFound";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(minMax);

function getThemeGenerationStatus(documents) {
    const docsCompleted = documents.filter(({ status: docStatus }) =>
        ["READY", "FAILED"].includes(docStatus)
    );

    const docsFailed = docsCompleted.filter(
        ({ status: docStatus }) => docStatus === "FAILED"
    );
    if (docsCompleted.length === documents.length) {
        return (
            <span>
                <b>Generating</b> thematic summary from{" "}
                {docsCompleted.length - docsFailed.length} documents
            </span>
        );
    }
    return null;
}

function getDocumentStatus(documents) {
    const docsReady = documents.filter(
        ({ status: docStatus }) => docStatus === "READY"
    );

    const docsProcessing = documents.filter(
        ({ status: docStatus }) => docStatus === "PROCESSING"
    );

    if (
        documents.filter(({ status: docStatus }) => docStatus === "QUEUED")
            .length === documents.length
    ) {
        return `${documents.length} documents are in the processing queue.`;
    }

    if (docsReady.length === documents.length) {
        return <span>Processed {documents.length} documents.</span>;
    }

    return (
        <span>
            <b>Processing</b> {docsProcessing.length} of {documents.length}{" "}
            documents
        </span>
    );
}

const GET_CONCEPT_SPACE = gql`
    query getConceptSpace(
        $conceptSpacesWhere: ConceptSpaceWhere
        $themesOptions: ThemeOptions
    ) {
        conceptSpaces(where: $conceptSpacesWhere) {
            name
            group
            status
            updated
            wordCount
            researchQueries {
                question
                searchTerm
            }
            documentsConnection(sort: [{ edge: { updated: ASC } }]) {
                edges {
                    status
                    updated
                    error
                    errorText
                    node {
                        path
                        contentType
                        wordCount
                        parsedWordCount
                    }
                }
            }
            themes(options: $themesOptions) {
                name
                size
                importance
                hidden
                concepts(
                    where: {
                        entityType_NOT_IN: [
                            "DATE"
                            "TIME"
                            "MONEY"
                            "PERCENT"
                            "QUANTITY"
                        ]
                    }
                    options: { limit: 6 }
                ) {
                    id
                    name
                    entityType
                    source
                }
            }
            orgConcepts: concepts(where: { entityType_IN: ["ORG"] }) {
                id
                name
                source
            }
            personConcepts: concepts(
                where: { entityType_IN: ["PER", "PERSON"] }
            ) {
                id
                name
                source
            }
            locConcepts: concepts(where: { entityType_IN: ["LOC", "GPE"] }) {
                id
                name
                source
            }
            miscConcepts: concepts(
                where: {
                    entityType_NOT_IN: [
                        "ORG"
                        "PER"
                        "PERSON"
                        "LOC"
                        "GPE"
                        "DATE"
                        "MONEY"
                        "QUANTITY"
                        "PERCENT"
                    ]
                }
            ) {
                id
                name
                source
                entityType
            }
        }
    }
`;

const ADD_SEARCH_RESULTS = gql`
    mutation AddSearchResults($conceptSpaceId: ID!, $searchTerms: [String!]!) {
        addSearchResults(
            conceptSpaceId: $conceptSpaceId
            searchTerms: $searchTerms
        )
    }
`;

function ResearchLog() {
    const history = useHistory();
    const { conceptSpaceId } = useParams();
    const [updateLog, setUpdateLog] = useState([]);
    const [lastUpdated, setLastUpdated] = useState();
    // const size = React.useContext(ResponsiveContext);

    const endRef = useRef();

    const { loading, error, data, stopPolling } = useQuery(GET_CONCEPT_SPACE, {
        pollInterval: 5000,
        fetchPolicy: "cache-and-network",
        variables: {
            conceptSpacesWhere: { id: conceptSpaceId },
            themesOptions: {
                // limit: 20,
                sort: [
                    {
                        size: "DESC",
                    },
                ],
            },
        },
    });

    const [addSearchResults, { data: searchData, error: searchError }] =
        useMutation(ADD_SEARCH_RESULTS);

    const {
        conceptSpaces: [conceptSpace],
    } = data || { conceptSpaces: [{}] };

    const {
        name,
        status: conceptSpaceStatus,
        researchQueries,
        documentsConnection: { edges: documents } = {},
        // themes,
        // personConcepts,
        // orgConcepts,
        // locConcepts,
        // miscConcepts,
    } = conceptSpace;

    const isReady = conceptSpaceStatus === "READY";
    useEffect(() => {
        if (isReady) {
            stopPolling();
        }
    }, [conceptSpaceStatus]);

    useEffect(() => {
        if (
            !loading &&
            researchQueries &&
            (!documents || documents.length === 0)
        ) {
            setUpdateLog([
                <span>
                    <b>Searching</b> online for content
                </span>,
            ]);
            addSearchResults({
                variables: {
                    conceptSpaceId,
                    searchTerms: researchQueries.map(
                        ({ question, searchTerm }) => searchTerm || question
                    ),
                },
            });
            return;
        }

        if (documents && documents.length > 0) {
            const updateTimes = documents.map(({ updated }) => dayjs(updated));
            const latestUpdateTime = dayjs.max(updateTimes);
            if (latestUpdateTime !== lastUpdated) {
                setLastUpdated(latestUpdateTime);
            }

            const log = [];

            const docStatusSummary = getDocumentStatus(documents);
            if (!updateLog.includes(docStatusSummary)) {
                log.push(docStatusSummary);
            }

            documents
                .filter(({ updated }) => dayjs(updated).isAfter(lastUpdated))
                .forEach(
                    ({
                        status,
                        error: documentError,
                        errorText,
                        node: { path, wordCount, parsedWordCount },
                    }) => {
                        if (status === "PROCESSING") {
                            if (wordCount) {
                                if (
                                    parsedWordCount &&
                                    parsedWordCount !== wordCount
                                ) {
                                    log.push(
                                        <span>
                                            <b>Analysing</b> {path} (only
                                            reading the first {parsedWordCount}{" "}
                                            of {wordCount} words)
                                        </span>
                                    );
                                } else {
                                    log.push(
                                        <span>
                                            <b>Analysing</b>{" "}
                                            {wordCount.toLocaleString()} words
                                            from {path}
                                        </span>
                                    );
                                }
                            } else {
                                log.push(
                                    <span>
                                        <b>Fetching</b> {path}
                                    </span>
                                );
                            }
                        } else if (status === "FAILED") {
                            const internalError = ![
                                "TimeoutError",
                                "ContentError",
                                "ScrapingError",
                            ].includes(documentError);
                            if (internalError) {
                                log.push(
                                    <span>
                                        Something went wrong with {path}
                                        <br />
                                        <Anchor href="mailto:support@archerapp.ai">
                                            Contact us to report an issue
                                        </Anchor>
                                    </span>
                                );
                            } else {
                                log.push(
                                    `${path} failed with ${documentError}: ${errorText}`
                                );
                            }
                        } else if (status === "READY") {
                            log.push(
                                <span>
                                    <b>Finished</b> {path}
                                </span>
                            );
                        }
                    }
                );

            if (conceptSpaceStatus === "FAILED") {
                log.push(
                    <span>
                        Something went wrong
                        <br />
                        <Anchor href="mailto:support@archerapp.ai">
                            Contact us to report an issue
                        </Anchor>
                    </span>
                );
            } else if (conceptSpaceStatus === "READY") {
                if (
                    documents.filter(({ status }) => status === "READY")
                        .length === 0
                ) {
                    log.push(
                        <span>
                            None of the documents were processed successfully.
                            Need help?{" "}
                            <Anchor href="mailto:support@archerapp.ai">
                                Contact us
                            </Anchor>
                        </span>
                    );
                } else {
                    log.push(
                        <span>
                            All done. The{" "}
                            <Anchor
                                onClick={() =>
                                    history.push(`/${conceptSpaceId}/summary`)
                                }
                            >
                                summary
                            </Anchor>{" "}
                            is ready
                        </span>
                    );
                }
            } else {
                const themeSummary = getThemeGenerationStatus(documents);
                if (themeSummary && !updateLog.includes(themeSummary)) {
                    log.push(themeSummary);
                }
            }

            if (log.length > 0) {
                setUpdateLog([...new Set([...updateLog, ...log])]);
            }
        }
    }, [documents, conceptSpaceStatus]);

    useEffect(() => {
        if (searchData) {
            const { addSearchResults: searchResultsJSON } = searchData;
            const searchResults = JSON.parse(searchResultsJSON)
                .flat()
                .filter(({ filtered }) => !filtered);
            const pages = new Set(searchResults.map(({ link }) => link));

            setUpdateLog([
                ...updateLog,
                `Found ${pages.size} web pages that look relevant.`,
            ]);
        }
    }, [searchData]);

    useEffect(() => {
        if (endRef.current) {
            endRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [updateLog]);

    if (loading)
        return (
            <Page
                kind="narrow"
                gap="medium"
                flex="grow"
                pad={{ bottom: "large" }}
                fill
                align="center"
            >
                <Box fill justify="center" align="center" gap="small">
                    <Spinner size="small" />
                </Box>
            </Page>
        );

    if (data && data.conceptSpaces.length === 0) return <NotFound />;

    return (
        <Page
            kind="narrow"
            flex="grow"
            pad={{ top: "medium", bottom: "large" }}
        >
            {(error || searchError) && (
                <ErrorBox error={error || searchError} />
            )}
            <PageContent margin="large">
                <Box width={{ max: "large" }}>
                    <StepsDiagram
                        steps={
                            isReady
                                ? [
                                      { checked: true },
                                      { checked: true },
                                      { filled: true },
                                  ]
                                : [{ checked: true }, { active: true }]
                        }
                    />
                </Box>
                {isReady && (
                    <Box
                        direction="row"
                        justify="end"
                        margin={{ top: "small" }}
                    >
                        <Button
                            label="See the summary"
                            onClick={() => {
                                history.push(`/${conceptSpaceId}/summary`);
                            }}
                        />
                    </Box>
                )}
            </PageContent>
            <PageContent
                margin={{ top: "large" }}
                animation={{
                    type: "fadeIn",
                    duration: 200,
                }}
            >
                <Box>
                    <Heading level={6} size="small" margin="none">
                        Your project
                    </Heading>
                    <Heading
                        margin={{ vertical: "xsmall" }}
                        style={{ lineHeight: "1.2em" }}
                    >
                        {name}
                    </Heading>
                </Box>
            </PageContent>
            <PageContent
                background="backgroundDarker"
                margin={{ top: "medium" }}
                pad={{ horizontal: "large", vertical: "small" }}
            >
                {updateLog &&
                    updateLog.map((message) => (
                        <Box
                            key={message}
                            animation={{
                                type: "fadeIn",
                                duration: 200,
                            }}
                            direction="row"
                            align="center"
                            pad="xsmall"
                        >
                            <Text wordBreak="break-word">{message}</Text>
                        </Box>
                    ))}
                {!isReady && (
                    <Box pad="small">
                        <Spinner size="xsmall" />
                    </Box>
                )}
            </PageContent>
            <PageContent>
                {isReady && updateLog.length > 20 && (
                    <Box
                        direction="row"
                        justify="end"
                        margin={{ top: "medium" }}
                    >
                        <Button
                            label="Go to the summary"
                            onClick={() => {
                                history.push(`/${conceptSpaceId}/summary`);
                            }}
                        />
                    </Box>
                )}
            </PageContent>
            <div ref={endRef} />
        </Page>
    );
}

export default ResearchLog;
