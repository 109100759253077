import { deepMerge } from "grommet/utils";
import { css } from "styled-components";

import { lighten, transparentize } from "polished";
import { base } from "grommet/themes";
import { FormDown, FormUp } from "grommet-icons";

// const brandColor = "#0887f7";
// const brandColor = "#2A5DB0";
const brandColor = "#1F4A94";
// const brandColor = "#4073B5";

const uiTheme = deepMerge(base, {
    global: {
        colors: {
            brand: brandColor,
            active: "#FE77B3",
            placeholder: lighten(0.5, "#353B4E"),
            highlight: "rgba(255, 211, 227, 0.6)",
            concept: "#1FB9FC",
            background: "#FAF6F4",
            backgroundDarker: "#F4EFEF",
            backgroundLighter: "#FEFCFB",
            backgroundPopover: "#F5F5F5",
            primaryLabel: "#003186",
            primaryButton: "#3330BC",
            border: "rgba(0, 49, 134, 0.2)",
            pillBorder: "#E2E2E2",
            text: "#353B4E",
            grey: "#666666",
            secondaryText: "#4F4E4E",
            positive: "#1300F5",
            neutral: "#D8D8D8",
            negative: "#C62A1C",
        },
        font: {
            family: "'Karla', sans-serif",
            weight: 400,
        },
        focus: {
            outline: {
                color: "active",
                size: "1px",
            },
        },
        input: {
            font: { size: "16px", weight: 400 },
        },
    },
    page: {
        wide: {
            width: {
                max: "1280px",
            },
        },
    },
    spinner: {
        container: {
            color: "concept",
        },
    },
    avatar: {
        size: {
            medium: "40px",
        },
        extend: `
                border-radius: 10px;
                // filter: drop-shadow(0px 8px 10px rgba(41, 70, 152, 0.15));        
                // color: rgba(255, 255, 255, 0.6);
                // background: #04CB8D;
                // background: -webkit-linear-gradient(bottom right, #04CB8D, #6E3F71);
                // background: -moz-linear-gradient(bottom right, #04CB8D, #6E3F71);
                // background: linear-gradient(to top left, #04CB8D, #6E3F71);
                `,
    },
    heading: {
        font: {
            family: "'Lato', sans-serif",
        },
        level: {
            1: {
                font: { weight: 900 },
                small: {
                    size: "16px",
                    height: "19px",
                },
                medium: {
                    size: "20px",
                },
            },
            2: {
                font: { weight: 400 },
                large: {
                    size: "30px",
                },
                medium: {
                    size: "20px",
                },
                small: {
                    size: "16px",
                    height: "19px",
                },
                xsmall: {
                    size: "14px",
                    height: "17px",
                },
            },
            3: {
                font: {
                    weight: 300,
                },
                large: { size: "60px", height: "72px" },
            },
            4: {
                font: {
                    weight: 700,
                },
                medium: { size: "14px", height: "17px" },
                large: { size: "18px", height: "22px" },
            },
        },
    },
    text: {
        small: {
            size: "14px",
            height: "19px",
            weight: 400,
        },
        medium: {
            size: "16px",
            height: "19px",
            weight: 400,
        },
    },
    button: {
        default: {
            color: "text",
            background: "white",
            border: {
                color: "primaryLabel",
                radius: "10px",
                width: "1px",
            },
            extend: "filter: drop-shadow(0px 8px 5px rgba(41, 70, 152, 0.1));",
        },
        primary: {
            color: "white",
            background: "primaryButton",
            border: {
                color: "primaryButton",
                radius: "10px",
                width: "1px",
            },
            padding: {
                horizontal: "1.2em",
                vertical: "0.5em",
            },
            font: {
                weight: "900",
            },
            extend: "filter: drop-shadow(0px 10px 5px rgba(41, 70, 152, 0.05));",
        },
        secondary: {
            color: "text",
            background: "white",
            border: {
                width: "0px",
                radius: "10px",
                color: "active",
            },
            extend: "filter: drop-shadow(0px 8px 5px rgba(41, 70, 152, 0.1));",
        },
        hover: {
            default: {
                extend: ({ theme }) =>
                    css`
                        filter: drop-shadow(
                            0px 10px 5px
                                ${transparentize(
                                    0.9,
                                    theme.global.colors.active
                                )}
                        );
                    `,
                border: {
                    color: "active",
                },
            },
            secondary: {
                extend: ({ theme }) =>
                    css`
                        filter: drop-shadow(
                            0px 10px 5px
                                ${transparentize(
                                    0.9,
                                    theme.global.colors.active
                                )}
                        );
                    `,
                border: {
                    width: "1px",
                    radius: "10px",
                    color: "active",
                },
            },
            primary: {
                extend: ({ theme }) =>
                    css`
                        filter: drop-shadow(
                            0px 5px 5px
                                ${transparentize(
                                    0.9,
                                    theme.global.colors.active
                                )}
                        );
                    `,
                border: {
                    color: "active",
                },
            },
        },
    },
    anchor: {
        color: "primaryLabel",
        extend: ({ theme }) => css`
            :hover {
                color: ${theme.global.colors.active};
                text-decoration-thickness: 2px;
                text-underline-offset: 2px;
            }
            :focus {
                outline: none !important;
                color: ${theme.global.colors.active};
                text-decoration: underline;
                text-decoration-thickness: 2px;
                text-underline-offset: 2px;
            }
        `,
    },
    tabs: {
        gap: "42px",
        header: {
            border: {
                size: "1px",
                side: "bottom",
            },
        },
    },
    tab: {
        pad: { bottom: "12px" },
        margin: { horizontal: "2px", vertical: "none" },
        color: "primaryLabel",
        extend: ({ theme }) => css`
            font-family: "Lato", sans-serif;
            font-weight: 900;

            *[aria-expanded="true"] > & {
                box-shadow: 0 1px ${theme.global.colors.active};
            }
        `,
        active: {
            color: "active",
        },
        hover: {
            color: "active",
            extend: ({ theme }) => css`
                box-shadow: 0 1px ${theme.global.colors.active};
            `,
        },
        border: {
            color: "none",
            active: {
                color: "active",
            },
            hover: {
                color: "active",
            },
        },
    },
    list: {
        extend: () => css`
            :hover,
            :focus {
                outline: none !important;
            }
        `,
    },
    dataTable: {
        header: {
            font: { weight: 600 },
            extend: `
            justify-content: start;
            button {
                border-width: 0px;
                filter: none;
                background: none;
            }
            input {
                height: 28px;
                margin: 2px;
            }
            `,
        },
        container: {
            gap: "medium",
        },
    },
    card: {
        hover: {
            container: {
                elevation: "small",
            },
        },
        container: {
            pad: { vertical: "small" },
            margin: "none",
            background: "white",
            elevation: "none",
            // Safari rendering workaround https://stackoverflow.com/questions/38762661/css-drop-shadow-not-working-properly-in-safari/75027316#75027316
            extend: css`
                backdrop-filter: blur(0);
                filter: drop-shadow(0px 10px 5px rgba(41, 70, 152, 0.05));
            `,
        },
        header: {
            margin: { horizontal: "20px", vertical: "8px" },
        },
        body: {
            margin: { top: "12px" },
            pad: { horizontal: "small" },
        },
    },
    menu: {
        border: 1,
        background: "white",
        elevation: "large",
    },
    accordion: {
        heading: {
            level: 6,
        },
        icons: {
            expand: FormDown,
            collapse: FormUp,
            color: brandColor,
        },
        border: undefined,
    },
    select: {
        control: {
            extend: ({ theme }) => css`
                font-family: "Lato", sans-serif;
                font-weight: 700;
                font-size: 13px;
                line-height: 15.6px;

                color: ${theme.global.colors.primaryLabel};
                border-bottom: 2px solid ${theme.global.colors.primaryLabel};

                input {
                    padding: 2px;
                }
            `,
        },
        options: {
            text: {
                size: "small",
            },
        },
        container: {
            extend: `
            font-family: "Lato", sans-serif;
            `,
        },
    },
    checkBox: {
        size: "18px",
        border: {
            width: "1px",
        },
        check: {
            thickness: "3px",
        },
    },
    notification: {
        title: { margin: { top: "xxsmall", bottom: "small" } },
    },
});

export default uiTheme;
