import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";

import {
    Anchor,
    Box,
    Button,
    Card,
    CardBody,
    CardHeader,
    Grid,
    Heading,
    Image,
    Menu,
    Notification,
    Page,
    PageContent,
    ResponsiveContext,
    Spinner,
    Tag,
    Text,
    ThemeContext,
    Tip,
} from "grommet";
import { CircleAlert, CircleInformation, FormAdd, More } from "grommet-icons";

import { gql, useMutation, useQuery } from "@apollo/client";

import { getApp } from "firebase/app";
import {
    arrayUnion,
    doc,
    getFirestore,
    setDoc,
    updateDoc,
} from "firebase/firestore";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";

import { CreateIcon, DocumentsIcon, ThemeIcon } from "./icons";
import Modal from "./components/Modal";
import ErrorBox from "./components/ErrorBox";
import NewProject from "./NewProject";
import { useAuth } from "./components/auth/AuthProvider";
import GET_USAGE from "./graphql/getUsageQuery";

dayjs.extend(duration);
dayjs.extend(relativeTime);

const GET_CONCEPT_SPACES = gql`
    query getConceptSpaces {
        conceptSpaces(
            where: {
                OR: [{ status: null }, { status_NOT_IN: [DELETING, DELETED] }]
            }
            options: { sort: [{ updated: DESC }] }
        ) {
            id
            name
            status
            created
            updated
            expired
            locked
            documentsConnection {
                totalCount
            }
            themesConnection(where: { node: { hidden_NOT: true } }) {
                totalCount
            }
        }
    }
`;

const GET_USER = gql`
    query Users($where: UserWhere) {
        users(where: $where) {
            quota
            id
            email
        }
    }
`;

const UPDATE_CONCEPT_SPACES = gql`
    mutation UpdateConceptSpaces(
        $where: ConceptSpaceWhere
        $update: ConceptSpaceUpdateInput
    ) {
        updateConceptSpaces(where: $where, update: $update) {
            conceptSpaces {
                id
                updated
                expired
                locked
            }
        }
    }
`;

const DELETE_CONCEPT_SPACE = gql`
    mutation DeleteConceptSpace($id: ID!) {
        deleteConceptSpace(id: $id)
    }
`;

const CREATE_USER = gql`
    mutation CreateUsers($input: [UserCreateInput!]!) {
        createUsers(input: $input) {
            users {
                id
                email
                quota
            }
        }
    }
`;

const UPDATE_USER = gql`
    mutation UpdateUsers($update: UserUpdateInput, $where: UserWhere) {
        updateUsers(update: $update, where: $where) {
            users {
                email
                id
                quota
            }
        }
    }
`;

function isEqual(obj1, obj2) {
    const keys1 = Object.keys(obj1);
    const keys2 = Object.keys(obj2);

    if (keys1.length !== keys2.length) {
        return false;
    }

    for (let i = 0; i < keys1.length; i += 1) {
        const key = keys1[i];
        if (Array.isArray(obj1[key]) && Array.isArray(obj2[key])) {
            if (JSON.stringify(obj1[key]) !== JSON.stringify(obj2[key])) {
                return false;
            }
        } else if (obj1[key] !== obj2[key]) {
            return false;
        }
    }

    return true;
}

function UserConceptSpaces() {
    const history = useHistory();
    const location = useLocation();
    const { hash } = location;

    const { user, plan, getQuota } = useAuth();
    const firestore = getFirestore(getApp());

    const [deleteConceptSpaceId, setDeleteConceptSpaceId] = useState();
    const [quota, setQuota] = useState();
    const [quotaAlert, setQuotaAlert] = useState();

    const size = React.useContext(ResponsiveContext);

    const {
        loading,
        error,
        data = {},
        // refetch,
    } = useQuery(GET_CONCEPT_SPACES, {
        // notifyOnNetworkStatusChange: true,
        fetchPolicy: "cache-and-network",
    });

    const { data: usage } = useQuery(GET_USAGE, {
        variables: {
            where: { updated_GT: quota?.usagePeriod[0] },
        },
        skip: !quota && !quota?.usagePeriod,
    });

    const [
        deleteConceptSpace,
        { loading: deleteConceptSpaceLoading, error: deletionError },
    ] = useMutation(DELETE_CONCEPT_SPACE, {
        refetchQueries: [GET_CONCEPT_SPACES],
        onCompleted: () => {
            updateDoc(doc(firestore, "users", user.uid), {
                deletedProjects: arrayUnion(deleteConceptSpaceId),
            });
        },
    });

    const [updateConceptSpaces] = useMutation(UPDATE_CONCEPT_SPACES);

    const { data: { users: [userData] = [] } = {}, loading: userDataLoading } =
        useQuery(GET_USER, {
            variables: { where: { email: user.email } },
            skip: !user,
        });
    const [updateUser] = useMutation(UPDATE_USER);
    const [createUsers] = useMutation(CREATE_USER);

    const { conceptSpaces } = data;

    useEffect(() => {
        if (user && conceptSpaces) {
            setDoc(
                doc(firestore, "users", user.uid),
                {
                    recentProjects: conceptSpaces
                        .map(({ id }) => id)
                        .slice(0, 10),
                },
                { merge: true }
            );
        }
    }, [conceptSpaces, user]);

    useEffect(() => {
        if (plan === undefined || loading || userDataLoading) {
            return;
        }
        getQuota().then(async (userQuota) => {
            setQuota(userQuota);

            // sync quota limits to db
            const quotaJSON = JSON.stringify(userQuota);
            if (!userData) {
                // create user data record
                await createUsers({
                    variables: {
                        input: {
                            email: user.email,
                            id: user.uid,
                            quota: quotaJSON,
                        },
                    },
                });
            } else {
                const storedQuota = userData.quota
                    ? JSON.parse(userData.quota)
                    : {};
                if (!isEqual(storedQuota, userQuota)) {
                    // update quota
                    await updateUser({
                        variables: {
                            where: { email: user.email },
                            update: { quota: quotaJSON, id: user.uid },
                        },
                    });

                    // has anything other than the usage period changed?
                    const { usagePeriod: a, ...storedQuotaLimits } = storedQuota
                        ? JSON.parse(userData.quota)
                        : {};
                    const { usagePeriod: b, ...currentQuotaLimits } = userQuota;
                    if (!isEqual(storedQuotaLimits, currentQuotaLimits)) {
                        // reset all limits
                        await updateConceptSpaces({
                            variables: {
                                update: { expired: null, locked: null },
                            },
                        });

                        if (userQuota.projects) {
                            const excessProjects =
                                userQuota.projects &&
                                conceptSpaces.slice(userQuota.projects);

                            if (excessProjects) {
                                await updateConceptSpaces({
                                    variables: {
                                        where: {
                                            id_IN: excessProjects.map(
                                                ({ id }) => id
                                            ),
                                        },
                                        update: { locked: true },
                                    },
                                });
                            }
                        }
                    }
                }
                // update any expired
                if (userQuota.projectLifetimeMonths) {
                    const expiryDate = dayjs().subtract(
                        userQuota.projectLifetimeMonths,
                        "month"
                    );
                    // have any expired?
                    const expiredProjects = conceptSpaces?.filter(
                        ({ created }) => dayjs(created).isBefore(expiryDate)
                    );
                    if (expiredProjects?.length > 0) {
                        await updateConceptSpaces({
                            variables: {
                                where: {
                                    id_IN: expiredProjects.map(({ id }) => id),
                                },
                                update: { expired: expiryDate },
                            },
                        });
                    }
                }
            }
        });
    }, [plan, loading, userDataLoading]);

    useEffect(() => {
        if (quota !== undefined) {
            const wordsProcessed =
                (usage?.notTruncated?.wordCount?.sum || 0) +
                (usage?.truncated?.wordCount?.sum || 0);
            if (conceptSpaces.length >= quota?.projects) {
                setQuotaAlert({
                    name: "projects",
                    status: "critical",
                });
            } else if (
                quota.wordsPerMonth &&
                wordsProcessed > quota.wordsPerMonth
            ) {
                setQuotaAlert({
                    name: "processing",
                    status: "critical",
                });
            } else if (
                quota.wordsPerMonth &&
                wordsProcessed > quota.wordsPerMonth * 0.8
            ) {
                setQuotaAlert({
                    name: "processing",
                    status: "warning",
                });
            } else if (conceptSpaces.some(({ expired }) => expired)) {
                setQuotaAlert({
                    name: "project lifetime",
                    message: (
                        <Text>
                            Some of your projects have reached your plan&apos;s{" "}
                            <b>project lifetime</b>
                        </Text>
                    ),
                    status: "warning",
                });
            } else {
                setQuotaAlert(undefined);
            }
        }
    }, [quota, usage, conceptSpaces]);

    if (
        hash === "#newproject" &&
        quota !== undefined &&
        (!quotaAlert || quotaAlert.name !== "projects")
    ) {
        return <NewProject />;
    }

    return (
        <Page flex="grow" gap="large">
            {quotaAlert && (
                <ThemeContext.Extend
                    value={{
                        notification: {
                            textContainer: {
                                fill: "horizontal",
                                pad: { top: "xsmall" },
                                margin: { top: "2px" },
                            },
                            iconContainer: { pad: { vertical: "xsmall" } },
                            critical: {
                                icon: CircleAlert,
                            },
                        },
                    }}
                >
                    <Notification
                        global
                        message={
                            <Box justify="between" direction="row">
                                <Text>
                                    {quotaAlert.message || (
                                        <Text>
                                            {quotaAlert.status === "critical"
                                                ? "You've reached "
                                                : "You're nearing "}
                                            your plan&apos;s limit for{" "}
                                            <b>{quotaAlert.name}</b>
                                        </Text>
                                    )}
                                    <Text>
                                        . Upgrade now to keep exploring with
                                        Archer.
                                    </Text>
                                </Text>
                                <Anchor
                                    label="Manage account"
                                    onClick={() => history.push("/account")}
                                />
                            </Box>
                        }
                        status={quotaAlert.status}
                    />
                </ThemeContext.Extend>
            )}
            <PageContent margin={{ top: "medium", bottom: "medium" }}>
                <Box width={{ max: "large" }}>
                    <Heading
                        level={2}
                        size={size === "small" ? "2em" : "large"}
                        margin={{ top: "large", bottom: "medium" }}
                    >
                        What are you going to discover today?
                    </Heading>
                </Box>
                <Text>
                    Create a new project, browse previous or learn about Archer
                    with our{" "}
                    <Anchor
                        plain
                        href="https://same-judo-f78.notion.site/Archer-Help-084c04136b934873b6cea96702621473"
                        target="_blank"
                    >
                        Getting Started Guide
                    </Anchor>
                </Text>
            </PageContent>
            <PageContent gap="large">
                <Box direction="row">
                    <Button
                        icon={<FormAdd />}
                        label="New Project"
                        onClick={() => {
                            history.push({
                                ...location,
                                hash: "#newproject",
                            });
                        }}
                        disabled={
                            quotaAlert && quotaAlert.status === "critical"
                        }
                    />
                </Box>
            </PageContent>

            {deleteConceptSpaceId && (
                <Modal
                    modal
                    position="center"
                    onClickOutside={() => setDeleteConceptSpaceId(undefined)}
                    onEsc={() => setDeleteConceptSpaceId(undefined)}
                >
                    {deleteConceptSpaceLoading ? (
                        <Box pad="medium">
                            <Spinner />
                        </Box>
                    ) : (
                        <Box
                            fill
                            width={{ max: "large" }}
                            pad={{
                                vertical: "medium",
                                horizontal: "large",
                            }}
                            align="center"
                        >
                            <Heading
                                level={1}
                                size="small"
                                margin={{ bottom: "medium" }}
                            >
                                Delete project &quot;
                                {
                                    conceptSpaces.find(
                                        ({ id }) => id === deleteConceptSpaceId
                                    ).name
                                }
                                &quot;
                            </Heading>
                            <Text>
                                All themes, concepts and documents will be
                                permanently deleted.
                            </Text>
                            <Box
                                direction="row"
                                justify="center"
                                margin={{ top: "medium" }}
                                gap="xlarge"
                                fill
                            >
                                <Button
                                    secondary
                                    label="Cancel"
                                    onClick={() =>
                                        setDeleteConceptSpaceId(undefined)
                                    }
                                />
                                <Button
                                    label="Delete"
                                    onClick={() => {
                                        deleteConceptSpace({
                                            variables: {
                                                id: deleteConceptSpaceId,
                                            },
                                            onError: () =>
                                                setDeleteConceptSpaceId(
                                                    undefined
                                                ),
                                            onCompleted: () =>
                                                setDeleteConceptSpaceId(
                                                    undefined
                                                ),
                                        });
                                    }}
                                />
                            </Box>
                        </Box>
                    )}
                </Modal>
            )}
            <PageContent>
                <Heading level={2}>Explore projects</Heading>
                {(loading || quota === undefined) && (
                    <Spinner size="small" alignSelf="center" />
                )}
                {error && <ErrorBox error={error} />}
                {deletionError && (
                    <ErrorBox
                        canGoBack={false}
                        canRetry={false}
                        error={deletionError}
                    />
                )}
                {!loading && quota !== undefined && conceptSpaces && (
                    <Box
                        width={{ max: "xlarge" }}
                        gap="medium"
                        pad={{ bottom: "xlarge" }}
                    >
                        {!loading &&
                            conceptSpaces &&
                            conceptSpaces.length === 0 && (
                                <Text>
                                    Nothing to see yet. Create a project first
                                    to see it here.
                                </Text>
                            )}
                        <Grid
                            columns="320px"
                            gap={{ row: "medium", column: "2em" }}
                            fill
                        >
                            {conceptSpaces.map(
                                ({
                                    name,
                                    id,
                                    created,
                                    updated,
                                    expired,
                                    locked,
                                    documentsConnection: {
                                        totalCount: docCount,
                                    },
                                    themesConnection: {
                                        totalCount: themeCount,
                                    },
                                }) => {
                                    return (
                                        <ThemeContext.Extend
                                            key={id}
                                            // Safari rendering workaround https://stackoverflow.com/questions/38762661/css-drop-shadow-not-working-properly-in-safari/75027316#75027316
                                            value={{
                                                card: {
                                                    container: {
                                                        extend: ({ theme }) => `
                                            backdrop-filter: blur(0);
                                            filter: drop-shadow(0px 10px 5px rgba(41, 70, 152, 0.05));
                                            header {
                                                color: ${theme.global.colors.primaryLabel};
                                            }
                            
                                            :hover header {
                                                color: ${theme.global.colors.active};
                                            }        
                                            `,
                                                    },
                                                },
                                            }}
                                        >
                                            <Card
                                                pad={{
                                                    horizontal: "small",
                                                    bottom: "small",
                                                }}
                                                background={
                                                    expired || locked
                                                        ? "backgroundLighter"
                                                        : "white"
                                                }
                                                onClick={() => {
                                                    if (expired || locked) {
                                                        history.push(
                                                            "/account"
                                                        );
                                                    } else if (themeCount) {
                                                        history.push(
                                                            `/${id}/summary`
                                                        );
                                                    } else {
                                                        history.push(
                                                            `/${id}/docs`
                                                        );
                                                    }
                                                }}
                                            >
                                                <CardHeader
                                                    direction="column"
                                                    gap="none"
                                                >
                                                    <Box
                                                        direction="row"
                                                        gap="xsmall"
                                                        justify="center"
                                                        flex="grow"
                                                    >
                                                        {expired && (
                                                            <Tip
                                                                size="small"
                                                                plain
                                                                dropProps={{
                                                                    background:
                                                                        "dark-2",
                                                                    round: "xsmall",
                                                                    pad: {
                                                                        vertical:
                                                                            "xsmall",
                                                                        horizontal:
                                                                            "small",
                                                                    },
                                                                    margin: "xsmall",
                                                                    elevation:
                                                                        "small",
                                                                }}
                                                                content={
                                                                    <Text
                                                                        size="xsmall"
                                                                        color="white"
                                                                    >
                                                                        On your
                                                                        current
                                                                        plan
                                                                        projects
                                                                        expire
                                                                        after{" "}
                                                                        {
                                                                            quota?.projectLifetimeMonths
                                                                        }{" "}
                                                                        month
                                                                        {quota?.projectLifetimeMonths >
                                                                        1
                                                                            ? "s"
                                                                            : ""}
                                                                    </Text>
                                                                }
                                                            >
                                                                <Tag
                                                                    name={
                                                                        <Box
                                                                            direction="row"
                                                                            align="center"
                                                                            gap="xsmall"
                                                                        >
                                                                            <Text
                                                                                size="xsmall"
                                                                                color="black"
                                                                                justify="center"
                                                                            >
                                                                                EXPIRED
                                                                            </Text>
                                                                            <CircleInformation size="small" />
                                                                        </Box>
                                                                    }
                                                                    size="small"
                                                                />
                                                            </Tip>
                                                        )}
                                                        {locked && (
                                                            <Tip
                                                                size="small"
                                                                plain
                                                                dropProps={{
                                                                    background:
                                                                        "dark-2",
                                                                    round: "xsmall",
                                                                    pad: {
                                                                        vertical:
                                                                            "xsmall",
                                                                        horizontal:
                                                                            "small",
                                                                    },
                                                                    margin: "xsmall",
                                                                    elevation:
                                                                        "small",
                                                                }}
                                                                content={
                                                                    <Text
                                                                        size="xsmall"
                                                                        color="white"
                                                                    >
                                                                        On your
                                                                        current
                                                                        plan you
                                                                        have a
                                                                        limit of{" "}
                                                                        {
                                                                            quota?.projects
                                                                        }{" "}
                                                                        projects
                                                                    </Text>
                                                                }
                                                            >
                                                                <Tag
                                                                    name={
                                                                        <Box
                                                                            direction="row"
                                                                            align="center"
                                                                            gap="xsmall"
                                                                        >
                                                                            <Text
                                                                                size="xsmall"
                                                                                color="black"
                                                                                justify="center"
                                                                            >
                                                                                LOCKED
                                                                            </Text>
                                                                            <CircleInformation size="small" />
                                                                        </Box>
                                                                    }
                                                                    size="small"
                                                                />
                                                            </Tip>
                                                        )}
                                                    </Box>
                                                    <Box
                                                        fill
                                                        direction="row"
                                                        justify="between"
                                                        align="start"
                                                        margin={{
                                                            top: "small",
                                                        }}
                                                    >
                                                        <Heading
                                                            level={4}
                                                            size="large"
                                                            margin="none"
                                                        >
                                                            {name}
                                                        </Heading>
                                                        <Menu
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                e.nativeEvent.stopImmediatePropagation();
                                                            }}
                                                            plain
                                                            icon={
                                                                <Box pad="xsmall">
                                                                    <More size="small" />
                                                                </Box>
                                                            }
                                                            hoverIndicator
                                                            size="small"
                                                            items={[
                                                                {
                                                                    reverse: true,
                                                                    label: (
                                                                        <Text size="small">
                                                                            Delete
                                                                            this
                                                                            project
                                                                        </Text>
                                                                    ),
                                                                    onClick: (
                                                                        e
                                                                    ) => {
                                                                        setDeleteConceptSpaceId(
                                                                            id
                                                                        );
                                                                        e.stopPropagation();
                                                                        e.nativeEvent.stopImmediatePropagation();
                                                                    },
                                                                },
                                                            ]}
                                                        />
                                                    </Box>
                                                </CardHeader>
                                                <CardBody
                                                    margin={{
                                                        top: "medium",
                                                        horizontal: "xsmall",
                                                    }}
                                                    direction="row"
                                                    justify="between"
                                                    align="end"
                                                >
                                                    <Box gap="xsmall">
                                                        <Box
                                                            direction="row"
                                                            align="center"
                                                        >
                                                            <Image
                                                                src={ThemeIcon}
                                                                width="14px"
                                                                margin={{
                                                                    right: "xxsmall",
                                                                }}
                                                            />
                                                            <Text size="xsmall">
                                                                Themes:{" "}
                                                                {themeCount}
                                                            </Text>
                                                        </Box>
                                                        <Box
                                                            direction="row"
                                                            align="center"
                                                        >
                                                            <Image
                                                                src={
                                                                    DocumentsIcon
                                                                }
                                                                width="14px"
                                                                margin={{
                                                                    right: "xxsmall",
                                                                }}
                                                            />
                                                            <Text size="xsmall">
                                                                Documents:{" "}
                                                                {docCount}
                                                            </Text>
                                                        </Box>
                                                    </Box>
                                                    <Box gap="xsmall">
                                                        <Box
                                                            direction="row"
                                                            align="center"
                                                        >
                                                            <Image
                                                                src={CreateIcon}
                                                                width="14px"
                                                                margin={{
                                                                    right: "xxsmall",
                                                                }}
                                                            />
                                                            {expired ? (
                                                                <Text size="xsmall">
                                                                    Created:{" "}
                                                                    {dayjs(
                                                                        created
                                                                    ).format(
                                                                        "D MMM YYYY"
                                                                    )}
                                                                </Text>
                                                            ) : (
                                                                <Text size="xsmall">
                                                                    Updated:{" "}
                                                                    {dayjs(
                                                                        updated
                                                                    ).format(
                                                                        "D MMM YYYY"
                                                                    )}
                                                                </Text>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </CardBody>
                                            </Card>
                                        </ThemeContext.Extend>
                                    );
                                }
                            )}
                        </Grid>
                    </Box>
                )}
            </PageContent>
        </Page>
    );
}

export default UserConceptSpaces;
