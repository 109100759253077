import React from "react";
import { useHistory } from "react-router-dom";

import {
    Anchor,
    Box,
    Button,
    Heading,
    List,
    Paragraph,
    Spinner,
    Text,
    ThemeContext,
} from "grommet";
import { FormNext } from "grommet-icons";
import { gql, useQuery } from "@apollo/client";
import ErrorBox from "./ErrorBox";

import { getLabel, getLocation } from "../utils/bookmarkUtils";

const GET_BOOKMARKS = gql`
    query GetRecentBookmarks($where: BookmarkWhere, $options: BookmarkOptions) {
        bookmarks(where: $where, options: $options) {
            id
            created
            targetConnection {
                edges {
                    node {
                        __typename
                        ... on Theme {
                            name
                        }
                        ... on Concept {
                            id
                            name
                        }
                        ... on QueryResult {
                            query
                        }
                        ... on Sentence {
                            text
                            documentConnection {
                                edges {
                                    passage
                                    sentence
                                    node {
                                        path
                                        uri
                                        title
                                    }
                                }
                            }
                        }
                        ... on Document {
                            path
                            uri
                            title
                        }
                    }
                }
            }
        }
        bookmarksAggregate(where: $where) {
            count
        }
    }
`;

function Notes({ conceptSpaceId }) {
    const history = useHistory();

    const { data, loading, error } = useQuery(GET_BOOKMARKS, {
        variables: {
            where: {
                conceptSpace: {
                    id: conceptSpaceId,
                },
            },
            options: {
                sort: [
                    {
                        created: "DESC",
                    },
                ],
                limit: 5,
            },
        },
    });

    const { bookmarks = [], bookmarksAggregate: { count } = {} } = data || {};

    return (
        <Box width={{ max: "320px" }} fill>
            <Box
                background="rgba(247, 249, 190, 1)"
                round="10px"
                pad={{
                    horizontal: "20px",
                    vertical: "20px",
                }}
            >
                <Box direction="row" justify="between">
                    <Heading
                        level={2}
                        size="small"
                        color="#3B3B3B"
                        style={{ fontWeight: 700 }}
                        margin={{ vertical: "none" }}
                    >
                        My Notes
                    </Heading>
                    <Button
                        plain
                        reverse
                        label={
                            <Text
                                size="12px"
                                fontWeight={600}
                                color="primaryLabel"
                            >
                                Show all {count ? `(${count})` : ""}
                            </Text>
                        }
                        icon={<FormNext size="small" />}
                        onClick={() => history.push(`/${conceptSpaceId}/notes`)}
                    />
                </Box>
                <Box margin={{ top: "medium" }}>
                    <Text size="xsmall">
                        Save themes, concepts and mentions as notes to have them
                        appear in your summary.{" "}
                        <Anchor
                            plain
                            href="https://same-judo-f78.notion.site/Archer-Help-084c04136b934873b6cea96702621473#e7a42c5374d74f27b1906260e9b8c49c"
                            target="_blank"
                        >
                            Learn more.
                        </Anchor>
                    </Text>
                </Box>
                {loading && (
                    <Box justify="center" pad={{ top: "medium" }}>
                        <Spinner size="xsmall" alignSelf="center" />
                    </Box>
                )}
                {error && <ErrorBox error={error} />}
                <ThemeContext.Extend
                    value={{
                        global: {
                            hover: {
                                background: "none",
                            },
                        },
                    }}
                >
                    <List
                        margin={{ horizontal: "10px" }}
                        pad={{
                            vertical: "small",
                        }}
                        data={bookmarks || []}
                        border="bottom"
                        onClickItem={({
                            item: {
                                targetConnection: {
                                    edges: [{ node: target }],
                                },
                            },
                        }) => {
                            const { path, state } = getLocation(target);
                            history.push(`/${conceptSpaceId}${path}`, state);
                        }}
                    >
                        {({
                            targetConnection: {
                                edges: [{ node: target } = {}],
                            },
                        }) => (
                            <Paragraph
                                maxLines={3}
                                size="small"
                                pad="none"
                                margin="none"
                            >
                                {target ? (
                                    getLabel(target)
                                ) : (
                                    <Text size="xsmall">
                                        <i>Linked item no longer exists</i>
                                    </Text>
                                )}
                            </Paragraph>
                        )}
                    </List>
                </ThemeContext.Extend>
            </Box>
        </Box>
    );
}

export default Notes;
