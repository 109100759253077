import React, { useState } from "react";

import { Button, TextInput, ThemeContext } from "grommet";

import { FormView, FormViewHide } from "grommet-icons";

const PasswordInput = React.forwardRef((props, ref) => {
    const [hidden, setHidden] = useState(true);
    return (
        <ThemeContext.Extend
            value={{
                textInput: {
                    extend: hidden && "font-family: courier",
                },
            }}
        >
            <TextInput
                ref={ref}
                type={hidden && "password"}
                icon={
                    <Button
                        tabIndex={-1}
                        style={{ pointerEvents: "all" }}
                        plain
                        icon={
                            hidden ? (
                                <FormView color="light-4" />
                            ) : (
                                <FormViewHide color="light-4" />
                            )
                        }
                        onClick={() => setHidden(!hidden)}
                    />
                }
                reverse
                {...props}
            />
        </ThemeContext.Extend>
    );
});

export default PasswordInput;
