import React, { createContext, useRef } from "react";
import { Box } from "grommet";

export const ScrollContext = createContext();
export function ScrollBox({ children }) {
    const ref = useRef();

    return (
        <Box ref={ref} flex overflow="auto">
            <ScrollContext.Provider value={ref.current}>
                {children}
            </ScrollContext.Provider>
        </Box>
    );
}
