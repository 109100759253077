import { Text } from "grommet";
import React, { useEffect, useState } from "react";

function TypingText({ text, onDone, as = Text, interval = 10 }) {
    // eslint-disable-next-line no-unused-vars
    const element = as;
    const [displayedText, setDisplayedText] = useState("");

    useEffect(() => {
        if (text !== displayedText) {
            const typingTimer = setTimeout(() => {
                setDisplayedText((prevText) => {
                    const char = text.slice(
                        prevText.length,
                        prevText.length + 1
                    );
                    return prevText + char;
                });
            }, Math.random() * 50 + interval); // Randomized delay between 50ms and 250ms

            return () => clearTimeout(typingTimer);
        }
        if (onDone) {
            onDone(displayedText);
            setDisplayedText("");
        }

        return () => {};
    }, [text, displayedText]);

    // const caret = <>&#x25AE;</>;
    const caret = <b> |</b>;
    return (
        <element color="black">
            {displayedText}
            {displayedText.length % 2 === 0 && caret}
        </element>
    );
}

export default TypingText;
