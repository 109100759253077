import React from "react";

import { Anchor, Header, Heading, Page, PageContent } from "grommet";
import { Link } from "react-router-dom";

function NotFound() {
    return (
        <Page kind="narrow" flex="grow">
            <PageContent
                as={Header}
                margin={{ top: "large", bottom: "medium" }}
            >
                <Heading level={3}>
                    This resource does not exist or you do not have access.
                </Heading>
                <Anchor as={Link} to="/">
                    Back to your Workspace
                </Anchor>
            </PageContent>
        </Page>
    );
}

export default NotFound;
