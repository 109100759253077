function entityTypeToLabel(entityType) {
    switch (entityType) {
        case "PER":
        case "PERSON":
            return "Person";
        case "ORG":
            return "Organisation";
        case "LOC":
        case "GPE":
            return "Place";
        default:
            return "CONCEPT";
    }
}

export default entityTypeToLabel;
