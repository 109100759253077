import React, { useState } from "react";

import { Box, Layer, Page, Spinner } from "grommet";

function Roadmap() {
    const [loaded, setLoaded] = useState(false);

    return (
        <Page
            flex="grow"
            pad={{ horizontal: "xlarge", top: "large", bottom: "xlarge" }}
        >
            {!loaded && (
                <Layer plain full animation={null}>
                    <Box
                        width="100%"
                        height="100%"
                        justify="center"
                        alignContent="center"
                    >
                        <Spinner size="small" alignSelf="center" />
                    </Box>
                </Layer>
            )}
            {/* <PageContent margin={{ top: "large", bottom: "medium" }}> */}
            <Box elevation={loaded ? "small" : "none"} fill>
                <iframe
                    title="roadmap"
                    className="airtable-embed"
                    src="https://airtable.com/embed/shrkcfE8AApjyP7SK?backgroundColor=orange&viewControls=on"
                    frameBorder="0"
                    width="100%"
                    height="100%"
                    style={{
                        background: "transparent",
                        // border: "1px solid #ccc",
                    }}
                    onLoad={() => setLoaded(true)}
                />
            </Box>
            {/* </PageContent> */}
        </Page>
    );
}

export default Roadmap;
