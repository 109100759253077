import React, { useCallback, useEffect, useRef, useState } from "react";

import { useHistory, useParams } from "react-router-dom";

import {
    Anchor,
    Box,
    Button,
    Drop,
    Heading,
    Menu,
    Notification,
    Page,
    PageContent,
    Paragraph,
    ResponsiveContext,
    Spinner,
    Text,
    Tip,
    // TextArea,
} from "grommet";
import {
    DocumentText,
    // FormUp,
    Share,
} from "grommet-icons";
import { BsStars } from "react-icons/bs";
import { FiCornerDownRight } from "react-icons/fi";

import {
    gql,
    // useMutation,
    useQuery,
} from "@apollo/client";
import dayjs from "dayjs";
import ErrorBox from "./components/ErrorBox";
import NotFound from "./components/NotFound";
import ThemeMap from "./components/ThemeMap";
import SearchBar from "./components/SearchBar";

import { capitalize } from "./utils/textUtils";
import TypingLines from "./components/TypingLines";

const READING_RATE = 250;

const GET_CONCEPT_SPACE = gql`
    query getConceptSpaceThemes(
        $conceptSpacesWhere: ConceptSpaceWhere
        $themesOptions: ThemeOptions
    ) {
        conceptSpaces(where: $conceptSpacesWhere) {
            name
            status
            updated
            description
            wordCount
            documentsConnection {
                edges {
                    status
                }
            }
            bookmarks {
                targetConnection {
                    edges {
                        node {
                            __typename
                            ... on Theme {
                                name
                                highlights
                                concepts(
                                    where: {
                                        entityType_NOT_IN: [
                                            "DATE"
                                            "TIME"
                                            "MONEY"
                                            "PERCENT"
                                            "QUANTITY"
                                        ]
                                    }
                                    options: { limit: 5 }
                                ) {
                                    id
                                    name
                                    entityType
                                    source
                                }
                            }
                            ... on Concept {
                                id
                                name
                                entityType
                                source
                            }
                            ... on QueryResult {
                                query
                                answer
                                concepts(
                                    where: {
                                        entityType_NOT_IN: [
                                            "DATE"
                                            "TIME"
                                            "MONEY"
                                            "PERCENT"
                                            "QUANTITY"
                                        ]
                                    }
                                    options: { limit: 5 }
                                ) {
                                    id
                                    name
                                    entityType
                                    source
                                }
                            }
                            ... on Sentence {
                                text
                                documentConnection {
                                    edges {
                                        passage
                                        sentence
                                        node {
                                            path
                                            title
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
            researchQueries {
                question
            }
            themes(
                where: { OR: [{ hidden: null }, { hidden_NOT: true }] }
                options: $themesOptions
            ) {
                name
                size
                importance
                concepts(
                    where: {
                        entityType_NOT_IN: [
                            "DATE"
                            "TIME"
                            "MONEY"
                            "PERCENT"
                            "QUANTITY"
                        ]
                    }
                    options: { limit: 5 }
                ) {
                    id
                    name
                    entityType
                    source
                }
            }
        }
    }
`;

const GET_QUERY_HIGHLIGHTS = gql`
    query GetQueryHighlights($where: QueryResultWhere) {
        queryResults(
            where: $where
            options: { sort: [{ updated: DESC }], limit: 1 }
        ) {
            answer
        }
    }
`;

const GET_THEME_HIGHLIGHTS = gql`
    query GetThemeHighlights($where: ThemeWhere) {
        themes(where: $where) {
            highlights
        }
    }
`;

function Actionable({ disabled, children }) {
    const [active, setActive] = useState(false);
    const [thread /* setThread */] = useState();
    const targetRef = useRef();

    if (disabled) {
        return children;
    }

    return (
        <Box
            ref={targetRef}
            onMouseEnter={() => setActive(true)}
            onMouseLeave={() => setActive(false)}
            background={active ? "light-1" : null}
        >
            {targetRef.current && active && (
                <Drop
                    inline
                    stretch={false}
                    target={targetRef}
                    overflow="unset"
                    align={{ top: "top", right: "left" }}
                    // pad={{ right: "large" }}
                    plain
                >
                    <Box
                        pad={{
                            horizontal: "small",
                            top: "xsmall",
                            bottom: "small",
                        }}
                    >
                        <Menu
                            plain
                            icon={<BsStars color="#FE77B3" />}
                            dropProps={{
                                width: "small",
                                align: { top: "bottom", left: "right" },
                                elevation: "xlarge",
                            }}
                            items={[
                                {
                                    label: <Text size="xsmall">Explore</Text>,
                                    disabled: true,
                                    style: { height: "30px" },
                                },
                                // {
                                //     label: <Text size="small">Deep dive</Text>,
                                // },
                                // {
                                //     label: (
                                //         <Text size="small">Find evidence</Text>
                                //     ),
                                // },
                                {
                                    label: (
                                        <Text size="small">
                                            Chat about this
                                        </Text>
                                    ),
                                },
                                // {
                                //     label: <Text size="xsmall">Expand</Text>,
                                //     disabled: true,
                                //     style: { height: "30px" },
                                // },
                                // {
                                //     label: (
                                //         <Text size="small">What if . . .</Text>
                                //     ),
                                //     onClick: () => setThread(true),
                                // },
                                // {
                                //     label: (
                                //         <Text size="small">
                                //             How might we . . .
                                //         </Text>
                                //     ),
                                // },
                                // {
                                //     label: (
                                //         <Text size="small">Create . . .</Text>
                                //     ),
                                // },
                            ]}
                        />
                    </Box>
                </Drop>
            )}
            {children}
            {thread && (
                <Box
                    direction="row"
                    gap="small"
                    margin={{
                        horizontal: "small",
                        top: "small",
                        bottom: "medium",
                    }}
                >
                    <FiCornerDownRight color="silver" />
                    <Box pad={{ top: "2px" }}>
                        <TypingLines
                            lines={[
                                "What if the widespread adoption of Neuralink's technology leads to a societal divide between those who can afford to enhance their brains and those who cannot, creating a new form of inequality?",
                                "What if the use of Neuralink's technology results in unforeseen psychological or physiological side effects, potentially causing harm to users' mental or physical health?",
                                "What if Neuralink's technology falls into the wrong hands and is used for malicious purposes, such as mind control or unauthorized access to personal thoughts and memories?",
                            ]}
                        />
                        {/* <Text size="small" color="dark-4">
                            <i>
                                <b>What if </b>
                                students were actively involved in the
                                decision-making processes of higher education
                                institutions, shaping the policies and practices
                                <b> [. . .]</b>
                            </i>
                        </Text> */}
                    </Box>
                </Box>
            )}
        </Box>
    );
}

function removeCitations(str) {
    // Regular expression to match citations of the form [1], [12], etc.
    const citationRegex = /\[\d+\]/g;

    // Remove all citations from the string
    const result = str.replace(citationRegex, "");

    return result;
}

function QueryAnswer({
    conceptSpaceId,
    query,
    skip = true,
    onLoaded,
    actioned,
}) {
    const { loading, error, data } = useQuery(GET_QUERY_HIGHLIGHTS, {
        variables: {
            where: {
                conceptSpace: { id: conceptSpaceId },
                query,
            },
        },
        onCompleted: onLoaded,
        skip,
    });

    if (skip || loading) return <Spinner alignSelf="center" />;

    if (error)
        return (
            <Page kind="narrow">
                <PageContent margin={{ top: "large", bottom: "medium" }}>
                    <ErrorBox error={error} />
                </PageContent>
            </Page>
        );

    const {
        queryResults: [{ answer } = { answer: [] }],
    } = data;

    return (
        <div className="export">
            {answer ? (
                answer.map((sentence) => (
                    <Actionable disabled thread={sentence.includes(actioned)}>
                        <Box direction="row" margin={{ top: "xsmall" }}>
                            <Text>
                                <div
                                    key={sentence}
                                    style={{
                                        paddingRight: "0.5em",
                                        marginBottom: "0.5em",
                                    }}
                                >
                                    {removeCitations(sentence)}
                                </div>
                            </Text>
                        </Box>
                    </Actionable>
                ))
            ) : (
                <Text>Not enough info found to answer this.</Text>
            )}
            <p />
        </div>
    );
}

function HighlightList({ conceptSpaceId, themeName, skip = true, onLoaded }) {
    const { loading, error, data } = useQuery(GET_THEME_HIGHLIGHTS, {
        variables: {
            where: {
                conceptSpace: { id: conceptSpaceId },
                name: themeName,
            },
        },
        onCompleted: onLoaded,
        skip,
    });

    if (skip || loading) return <Spinner alignSelf="center" />;

    if (error) {
        return (
            <Page kind="narrow">
                <PageContent margin={{ top: "large", bottom: "medium" }}>
                    <ErrorBox error={error} />
                </PageContent>
            </Page>
        );
    }

    const {
        themes: [{ highlights } = { highlights: [] }],
    } = data;

    if (!highlights) {
        return null;
    }

    return (
        <div className="export">
            <ul>
                {highlights.map((highlight) => (
                    <li key={highlight}>{highlight}</li>
                ))}
            </ul>
            <p />
        </div>
    );
}

function ConceptList({ concepts, absoluteURL }) {
    const history = useHistory();
    return (
        <Box pad={{ left: "medium" }} direction="row" wrap>
            {concepts.map(({ name: conceptName, id }, i) => (
                <Box direction="row" align="center" key={id}>
                    {i > 0 && (
                        <Text
                            margin={{
                                horizontal: "small",
                            }}
                            size="small"
                        >
                            {" "}
                            |{" "}
                        </Text>
                    )}
                    <Box onClick={() => history.push(`concepts/${id}`)}>
                        {" "}
                        <Text size="small">{capitalize(conceptName)}</Text>
                    </Box>
                </Box>
            ))}
            <div className="export" style={{ display: "none" }}>
                <span>
                    {concepts.map(({ name: conceptName, id }, n) => (
                        <>
                            {n > 0 && "  |  "}
                            <a href={`${absoluteURL}/concepts/${id}`}>
                                {conceptName}
                            </a>
                        </>
                    ))}
                </span>
                <p />
                <br />
            </div>
        </Box>
    );
}

function Summary() {
    const history = useHistory();
    const { conceptSpaceId } = useParams();
    const mainRef = React.useRef();

    const size = React.useContext(ResponsiveContext);

    const [selectedTheme, setSelectedTheme] = useState();
    const [copiedToClipbboard, setCopiedToClipbboard] = useState();
    // const [newDescription, setNewDescription] = useState(null);
    // const [editingDescription, setEditingDescription] = useState(false);
    const [searchQuery, setSearchQuery] = useState();
    const [answersLoaded, setAnswersLoaded] = useState(0);
    const [highlightsLoaded, setHighlightsLoaded] = useState(0);

    const absoluteURL = `${window.location.origin}/${conceptSpaceId}`;

    const copyToClipboard = useCallback(async () => {
        if (mainRef.current) {
            if (!window.ClipboardItem) {
                // eslint-disable-next-line no-alert
                window.alert(
                    "Export failed. Your browser does not support adding content to the clipboard."
                );
                return;
            }
            // const content = mainRef.current.innerHTML;
            const exportEls = mainRef.current.getElementsByClassName("export");
            const content = `<div>${[...exportEls]
                .map((el) => el.innerHTML)
                .join("")}</div>`;
            const blob = new Blob([content], { type: "text/html" });

            const richTextInput = new window.ClipboardItem({
                "text/html": blob,
            });
            await navigator.clipboard.write([richTextInput]);

            setCopiedToClipbboard(true);
        }
    });

    useEffect(() => {
        if (!selectedTheme) return;

        const element = document.getElementById(selectedTheme);
        if (element) {
            element.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "nearest",
            });
        }
    }, [selectedTheme]);

    const { loading, error, data } = useQuery(GET_CONCEPT_SPACE, {
        variables: {
            conceptSpacesWhere: { id: conceptSpaceId },
            themesOptions: {
                sort: [
                    {
                        importance: "DESC",
                    },
                ],
            },
        },
    });

    const {
        conceptSpaces: [{ status }],
    } = data || { conceptSpaces: [{}] };

    useEffect(() => {
        if (status === "UPDATING") {
            history.replace(`/${conceptSpaceId}/log`);
        }
    }, [status]);

    if (error) {
        return (
            <Page kind="narrow">
                <PageContent margin={{ top: "large", bottom: "medium" }}>
                    <ErrorBox error={error} />
                </PageContent>
            </Page>
        );
    }

    if (
        !loading &&
        (!data || !data.conceptSpaces || data.conceptSpaces.length === 0)
    ) {
        return <NotFound />;
    }

    const {
        conceptSpaces: [
            {
                name,
                updated,
                bookmarks: allBookmarks,
                researchQueries,
                themes,
                documentsConnection: { edges: documents } = {},
                wordCount,
            },
        ],
    } = data || { conceptSpaces: [{}] };

    const bookmarks =
        allBookmarks &&
        allBookmarks.filter(
            ({ targetConnection: { edges } }) => edges && edges.length > 0
        );

    const bookmarkedThemes =
        bookmarks &&
        bookmarks
            .map(
                ({
                    targetConnection: {
                        edges: [{ node }],
                    },
                }) => ({ target: node })
            )
            .filter(
                ({ target: { __typename: typeName } }) => typeName === "Theme"
            )
            .map(({ target }) => target);

    const keyThemeNames =
        bookmarkedThemes &&
        bookmarkedThemes.map(({ name: themeName }) => themeName);

    const bookmarkedConcepts =
        bookmarks &&
        bookmarks
            .map(
                ({
                    targetConnection: {
                        edges: [{ node }],
                    },
                }) => ({ target: node })
            )
            .filter(
                ({ target: { __typename: typeName } }) => typeName === "Concept"
            )
            .map(({ target }) => target);

    const bookmarkedQueries =
        bookmarks &&
        bookmarks
            .map(
                ({
                    targetConnection: {
                        edges: [{ node }],
                    },
                }) => ({ target: node })
            )
            .filter(
                ({ target: { __typename: typeName } }) =>
                    typeName === "QueryResult"
            )
            .map(({ target }) => target);

    const bookmarkedSentences =
        bookmarks &&
        bookmarks
            .map(
                ({
                    targetConnection: {
                        edges: [{ node }],
                    },
                }) => ({ target: node })
            )
            .filter(
                ({ target: { __typename: typeName } }) =>
                    typeName === "Sentence"
            )
            .map(({ target }) => target);

    // const bookmarkedDocuments =
    //     bookmarks &&
    //     bookmarks
    //         .map(
    //             ({
    //                 targetConnection: {
    //                     edges: [{ node }],
    //                 },
    //             }) => ({ target: node })
    //         )
    //         .filter(
    //             ({ target: { __typename: typeName } }) =>
    //                 typeName === "Document"
    //         )
    //         .map(({ target }) => target);

    if (loading) {
        return (
            <PageContent margin={{ top: "large", bottom: "medium" }}>
                <Spinner alignSelf="center" />
            </PageContent>
        );
    }

    if (
        documents.length === 0 ||
        (researchQueries &&
            researchQueries.length === 0 &&
            themes &&
            themes.length === 0)
    ) {
        return (
            <Page kind="wide" pad="medium" flex="grow">
                <PageContent margin={{ top: "large", bottom: "medium" }}>
                    <Heading
                        level={3}
                        // size="large"
                        margin={{ vertical: "large", left: "small" }}
                    >
                        There’s not enough content here yet to generate a
                        summary
                    </Heading>
                    <Text margin={{ left: "small" }}>
                        <Anchor
                            onClick={() =>
                                history.push(`/${conceptSpaceId}/docs`)
                            }
                        >
                            Add some documents
                        </Anchor>{" "}
                        for Archer to analyse.
                    </Text>
                </PageContent>
            </Page>
        );
    }

    return (
        <Page kind="wide" pad="medium" flex="grow">
            <PageContent margin={{ top: "large" }} pad="none">
                <SearchBar
                    text={searchQuery}
                    size="small"
                    onChange={(value) => setSearchQuery(value)}
                    onSearch={(value) => {
                        if (value instanceof Object) {
                            history.push(
                                `/${conceptSpaceId}/${
                                    value.type
                                }s/${encodeURIComponent(
                                    value.id || value.name
                                )}`
                            );
                        } else {
                            history.push({
                                pathname: `/${conceptSpaceId}/answers`,
                                search: `q=${value}`,
                            });
                        }
                    }}
                />
            </PageContent>
            {copiedToClipbboard && (
                <Notification
                    status="info"
                    message={<Text>Copied to clipboard</Text>}
                    onClose={() => setCopiedToClipbboard(false)}
                    toast
                />
            )}
            <PageContent pad="none">
                <Box
                    background="white"
                    elevation="small"
                    round="xsmall"
                    margin={{ vertical: "large" }}
                    pad={{ bottom: "xlarge" }}
                    ref={mainRef}
                >
                    <Box
                        direction="row"
                        justify="end"
                        align="end"
                        margin={{ horizontal: "medium", top: "medium" }}
                        pad="small"
                        // width="xlarge"
                    >
                        <Button
                            plain
                            reverse
                            icon={<Share />}
                            label="Export"
                            onClick={() => copyToClipboard()}
                        />
                    </Box>
                    <PageContent
                        margin={{ top: "xlarge" }}
                        pad={{ horizontal: "xlarge" }}
                    >
                        <Heading
                            level="2"
                            size={size === "small" ? "xsmall" : "small"}
                            color="primaryLabel"
                            margin="none"
                        >
                            Summary
                        </Heading>
                        <Heading
                            level={size === "small" ? 2 : 2}
                            size={size === "small" ? "medium" : "large"}
                            margin={{ top: "none", bottom: "xsmall" }}
                            fill
                        >
                            {name}
                        </Heading>
                        <Box
                            margin={{ top: "medium", bottom: "small" }}
                            direction={size === "small" ? "column" : "row"}
                            gap={size === "small" ? "xsmall" : "small"}
                        >
                            <Anchor
                                size="small"
                                color="text"
                                weight="inherit"
                                onClick={() =>
                                    history.push(`/${conceptSpaceId}/docs`)
                                }
                            >
                                <Text
                                    size="small"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    Documents:{" "}
                                    {documents
                                        .filter(
                                            ({ status: docStatus }) =>
                                                docStatus === "READY"
                                        )
                                        .length.toLocaleString()}
                                </Text>
                            </Anchor>
                            {size !== "small" && <Text size="small">•</Text>}
                            <Tip
                                content={
                                    <Text size="xsmall">
                                        {(wordCount || 0).toLocaleString()}{" "}
                                        words would take around{" "}
                                        {wordCount / READING_RATE > 180
                                            ? `${dayjs
                                                  .duration(
                                                      wordCount / READING_RATE,
                                                      "minutes"
                                                  )
                                                  .as("hours")
                                                  .toFixed(0)} hours `
                                            : `${dayjs
                                                  .duration(
                                                      wordCount / READING_RATE,
                                                      "minutes"
                                                  )
                                                  .as("minutes")
                                                  .toFixed(0)} minutes `}
                                        to read.
                                    </Text>
                                }
                            >
                                <Text
                                    size="small"
                                    style={{ whiteSpace: "nowrap" }}
                                >
                                    Words: {(wordCount || 0).toLocaleString()}
                                </Text>
                            </Tip>
                            {size !== "small" && <Text size="small">•</Text>}
                            <Text size="small" style={{ whiteSpace: "nowrap" }}>
                                Updated: {dayjs(updated).format("D MMM YYYY")}
                            </Text>
                        </Box>

                        <div className="export" style={{ display: "none" }}>
                            <h1>
                                <a href={`${absoluteURL}/summary`}>{name}</a>
                            </h1>
                            <p>
                                Updated: {dayjs(updated).format("D MMM YYYY")}{" "}
                            </p>
                            <p />
                        </div>
                    </PageContent>
                    {/* <PageContent
                        pad={{ horizontal: "xlarge", bottom: "large" }}
                    >
                        <Text size="large">{description}</Text>
                    </PageContent> */}
                    {/* <PageContent>
                    <Heading level={1} margin={{ bottom: "none" }}>
                        Background
                    </Heading>
                    {description && !editingDescription ? (
                        <Text
                            onClick={() => {
                                setNewDescription(description);
                                setEditingDescription(true);
                            }}
                        >
                            {description}
                        </Text>
                    ) : (
                        <Box>
                            <FocusedTextArea
                                placeholder="Click to edit this text include a little about what your task or project is all about."
                                value={newDescription}
                                onChange={({ target: { value } }) =>
                                    setNewDescription(value)
                                }
                                resize="vertical"
                                height="medium"
                                flex="grow"
                            />
                            <Box
                                width="xsmall"
                                alignSelf="end"
                                margin={{ top: "small" }}
                            >
                                {newDescription !== description && (
                                    <Box direction="row" gap="small">
                                        {description && (
                                            <Button
                                                secondary
                                                size="small"
                                                label="Cancel"
                                                onClick={() => {
                                                    setEditingDescription(
                                                        false
                                                    );
                                                }}
                                            />
                                        )}
                                        <Button
                                            primary
                                            label="Save"
                                            onClick={() => {
                                                if (
                                                    newDescription !==
                                                    description
                                                ) {
                                                    updateDescription({
                                                        variables: {
                                                            where: {
                                                                id: conceptSpaceId,
                                                            },
                                                            update: {
                                                                description:
                                                                    newDescription,
                                                            },
                                                        },
                                                        onCompleted: () =>
                                                            setEditingDescription(
                                                                false
                                                            ),
                                                    });
                                                } else {
                                                    setEditingDescription(
                                                        false
                                                    );
                                                }
                                            }}
                                        />
                                    </Box>
                                )}
                            </Box>
                            {updatingDescription && <Spinner />}
                        </Box>
                    )}
                    {description && (
                        <div className="export" style={{ display: "none" }}>
                            <h2>Background</h2>
                            <p>{description}</p>
                        </div>
                    )}
                </PageContent> */}

                    {researchQueries && researchQueries.length > 0 && (
                        <PageContent pad={{ horizontal: "xlarge" }}>
                            <Box>
                                {researchQueries.map(({ question }, i) => (
                                    <Box key={question}>
                                        <div
                                            className="export"
                                            style={{ display: "none" }}
                                        >
                                            <h3>
                                                <a
                                                    href={`${absoluteURL}/answers/${question}`}
                                                >
                                                    {question}
                                                </a>
                                            </h3>
                                        </div>
                                        <Heading
                                            level={2}
                                            size="small"
                                            responsive={false}
                                            style={{ lineHeight: "1.25em" }}
                                            // margin={{ bottom: "none" }}
                                        >
                                            <Anchor
                                                onClick={() =>
                                                    history.push(
                                                        `/${conceptSpaceId}/answers?q=${encodeURIComponent(
                                                            question
                                                        )}`
                                                    )
                                                }
                                            >
                                                {question}
                                            </Anchor>
                                        </Heading>
                                        <QueryAnswer
                                            conceptSpaceId={conceptSpaceId}
                                            query={question}
                                            skip={answersLoaded < i}
                                            onLoaded={() =>
                                                setAnswersLoaded(
                                                    answersLoaded + 1
                                                )
                                            }
                                        />
                                    </Box>
                                ))}
                            </Box>
                        </PageContent>
                    )}
                    {bookmarkedQueries && bookmarkedQueries.length > 0 && (
                        <PageContent pad={{ horizontal: "xlarge" }}>
                            <Box>
                                {bookmarkedQueries.map(
                                    ({ query, answer, concepts }) => (
                                        <Box key={query}>
                                            <div
                                                className="export"
                                                style={{ display: "none" }}
                                            >
                                                <h3>
                                                    <a
                                                        href={`${absoluteURL}/themes/${query}`}
                                                    >
                                                        {query}
                                                    </a>
                                                </h3>
                                                <ul>
                                                    {answer.map((sentence) => (
                                                        <li key={sentence}>
                                                            {sentence}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                            <Heading
                                                level={2}
                                                size="small"
                                                responsive={false}
                                                style={{ lineHeight: "1.25em" }}
                                                margin={{ bottom: "none" }}
                                            >
                                                <Anchor
                                                    onClick={() =>
                                                        history.push(
                                                            `/${conceptSpaceId}/answers?q=${encodeURIComponent(
                                                                query
                                                            )}`
                                                        )
                                                    }
                                                >
                                                    {query}
                                                </Anchor>
                                            </Heading>
                                            <ul>
                                                {answer.map((sentence) => (
                                                    <li key={sentence}>
                                                        {sentence}
                                                    </li>
                                                ))}
                                            </ul>
                                            <ConceptList
                                                concepts={concepts}
                                                absoluteURL={absoluteURL}
                                            />
                                        </Box>
                                    )
                                )}
                            </Box>
                        </PageContent>
                    )}
                    {bookmarkedThemes && bookmarkedThemes.length > 0 && (
                        <PageContent pad={{ horizontal: "xlarge" }}>
                            <Heading level={1} margin={{ bottom: "none" }}>
                                <div
                                    className="export"
                                    style={{ display: "none" }}
                                >
                                    <h2>Key Themes</h2>
                                </div>
                                Key Themes
                            </Heading>
                            <Box>
                                {bookmarkedThemes.map(
                                    ({
                                        name: themeName,
                                        highlights,
                                        concepts,
                                    }) => (
                                        <Box key={themeName}>
                                            <div
                                                className="export"
                                                style={{ display: "none" }}
                                            >
                                                <h3>
                                                    <a
                                                        href={`${absoluteURL}/themes/${themeName}`}
                                                    >
                                                        {themeName}
                                                    </a>
                                                </h3>
                                                <ul>
                                                    {highlights.map(
                                                        (highlight) => (
                                                            <li key={highlight}>
                                                                {highlight}
                                                            </li>
                                                        )
                                                    )}
                                                </ul>
                                            </div>
                                            <Heading
                                                level={2}
                                                size="small"
                                                responsive={false}
                                                style={{ lineHeight: "1.25em" }}
                                                margin={{ bottom: "none" }}
                                            >
                                                <Anchor
                                                    id={themeName}
                                                    onClick={() =>
                                                        history.push(
                                                            `/${conceptSpaceId}/themes/${encodeURIComponent(
                                                                themeName
                                                            )}`
                                                        )
                                                    }
                                                >
                                                    {themeName}
                                                </Anchor>
                                            </Heading>
                                            <ul>
                                                {highlights.map((highlight) => (
                                                    <li key={highlight}>
                                                        {highlight}
                                                    </li>
                                                ))}
                                            </ul>
                                            <ConceptList
                                                concepts={concepts}
                                                absoluteURL={absoluteURL}
                                            />
                                        </Box>
                                    )
                                )}
                            </Box>
                        </PageContent>
                    )}
                    {bookmarkedConcepts && bookmarkedConcepts.length > 0 && (
                        <PageContent pad={{ horizontal: "xlarge" }}>
                            <Heading level={1} margin={{ bottom: "none" }}>
                                <div
                                    className="export"
                                    style={{ display: "none" }}
                                >
                                    <br />
                                    <h2>Interesting Concepts</h2>
                                    <ul>
                                        {bookmarkedConcepts.map(
                                            ({
                                                id,
                                                name: conceptName,
                                                entityType,
                                            }) => (
                                                <li key={id}>
                                                    <a
                                                        href={`${absoluteURL}/concepts/${encodeURIComponent(
                                                            id
                                                        )}`}
                                                    >
                                                        {conceptName}
                                                    </a>
                                                    {entityType
                                                        ? ` (${entityType})`
                                                        : ""}
                                                </li>
                                            )
                                        )}
                                    </ul>
                                    <br />
                                </div>
                                Interesting Concepts
                            </Heading>
                            <ul>
                                {bookmarkedConcepts.map(
                                    ({ id, name: conceptName, entityType }) => (
                                        <li key={id}>
                                            <Anchor
                                                onClick={() =>
                                                    history.push(
                                                        `/${conceptSpaceId}/concepts/${encodeURIComponent(
                                                            id
                                                        )}`
                                                    )
                                                }
                                            >
                                                {conceptName}
                                            </Anchor>
                                            {entityType
                                                ? ` (${entityType})`
                                                : ""}
                                        </li>
                                    )
                                )}
                            </ul>
                        </PageContent>
                    )}
                    {bookmarkedSentences && bookmarkedSentences.length > 0 && (
                        <PageContent pad={{ horizontal: "xlarge" }}>
                            <Heading level={1} margin={{ bottom: "none" }}>
                                <div
                                    className="export"
                                    style={{ display: "none" }}
                                >
                                    <h2>Excerpts</h2>
                                </div>
                                Excerpts
                            </Heading>

                            {bookmarkedSentences.map(
                                ({
                                    text,
                                    documentConnection: {
                                        edges: [
                                            {
                                                passage,
                                                sentence,
                                                node: { path, title },
                                            },
                                        ],
                                    },
                                }) => (
                                    <Box
                                        key={text}
                                        margin={{ bottom: "medium" }}
                                    >
                                        <div
                                            className="export"
                                            style={{ display: "none" }}
                                        >
                                            <p>
                                                {text}
                                                {" - "}
                                                <a
                                                    href={`${absoluteURL}/docs/${path}#p=${passage}&s=${sentence}`}
                                                >
                                                    {title || path}
                                                </a>
                                            </p>
                                            <br />
                                        </div>
                                        <Paragraph
                                            fill
                                            margin={{ bottom: "xsmall" }}
                                        >
                                            {text}
                                        </Paragraph>
                                        <Text margin={{ left: "2px" }}>
                                            <Anchor
                                                plain
                                                color="grey"
                                                size="xsmall"
                                                weight={400}
                                                label={title || path}
                                                icon={
                                                    <DocumentText size="small" />
                                                }
                                                onClick={() =>
                                                    history.push(
                                                        {
                                                            pathname: `/${conceptSpaceId}/docs/${path}`,
                                                            hash: `p=${passage}&s=${sentence}`,
                                                        },
                                                        {
                                                            context: {
                                                                type: "excerpt",
                                                                name: text,
                                                            },
                                                            passages: [
                                                                { passage },
                                                            ],
                                                        }
                                                    )
                                                }
                                                target="_blank"
                                            />
                                        </Text>
                                    </Box>
                                )
                            )}
                        </PageContent>
                    )}
                    <PageContent
                        margin={{ vertical: "xlarge" }}
                        pad={{ horizontal: "xlarge" }}
                    >
                        <Heading level={1} margin={{ bottom: "none" }}>
                            Thematic Map
                        </Heading>
                        <Box margin={{ bottom: "medium" }}>
                            <Text size="xsmall" color="grey">
                                Size of themes shows the amount of relevant
                                content
                            </Text>
                            <Text size="xsmall" color="grey">
                                More similar themes are shown closer together
                            </Text>
                            <Text size="xsmall" color="grey">
                                Pink colour means the theme is in more documents
                            </Text>
                        </Box>
                        <ThemeMap
                            onSelectTheme={(theme) => {
                                setSelectedTheme(null);
                                setSelectedTheme(theme);
                            }}
                        />
                    </PageContent>
                    <PageContent
                        gap="medium"
                        margin={{ bottom: "large" }}
                        pad={{ horizontal: "xlarge" }}
                    >
                        {bookmarkedThemes && bookmarkedThemes.length > 0 && (
                            <Heading level={1} margin={{ bottom: "none" }}>
                                <div
                                    className="export"
                                    style={{ display: "none" }}
                                >
                                    <h2>Other Themes</h2>
                                    <p />
                                </div>
                                Other Themes
                            </Heading>
                        )}
                        {themes &&
                            themes
                                .filter(
                                    ({ name: themeName }) =>
                                        !keyThemeNames.includes(themeName)
                                )
                                .map(({ name: themeName, concepts }, i) => (
                                    <Box key={themeName}>
                                        <div
                                            className="export"
                                            style={{ display: "none" }}
                                        >
                                            <h3>
                                                <a
                                                    href={`${absoluteURL}/themes/${themeName}`}
                                                >
                                                    {themeName}
                                                </a>
                                            </h3>
                                        </div>
                                        <Heading
                                            level={2}
                                            size="small"
                                            responsive={false}
                                            style={{ lineHeight: "1.25em" }}
                                            margin={{
                                                top:
                                                    size === "small"
                                                        ? "large"
                                                        : "medium",
                                            }}
                                            // style={{ color: "active" }}
                                        >
                                            <Anchor
                                                id={themeName}
                                                color={
                                                    themeName === selectedTheme
                                                        ? "active"
                                                        : null
                                                }
                                                onClick={() =>
                                                    history.push(
                                                        `/${conceptSpaceId}/themes/${encodeURIComponent(
                                                            themeName
                                                        )}`
                                                    )
                                                }
                                            >
                                                {themeName}
                                            </Anchor>
                                        </Heading>
                                        <HighlightList
                                            conceptSpaceId={conceptSpaceId}
                                            themeName={themeName}
                                            skip={highlightsLoaded < i}
                                            onLoaded={() =>
                                                setHighlightsLoaded(
                                                    highlightsLoaded + 1
                                                )
                                            }
                                        />
                                        <ConceptList
                                            concepts={concepts}
                                            absoluteURL={absoluteURL}
                                        />
                                    </Box>
                                ))}
                    </PageContent>
                </Box>
            </PageContent>
        </Page>
    );
}

export default Summary;
