import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";

import { getApp } from "firebase/app";
import { getAuth, sendPasswordResetEmail } from "firebase/auth";

import {
    Anchor,
    Box,
    Button,
    Form,
    FormField,
    Heading,
    Notification,
    Page,
    Text,
    TextInput,
    ThemeContext,
} from "grommet";

function PasswordReset() {
    const history = useHistory();
    const { register, handleSubmit, formState } = useForm();
    const [passwordResetSent, setPasswordResetSent] = useState(false);
    const [resetError, setResetError] = useState();

    const { errors } = formState;

    return (
        <Page
            kind="narrow"
            pad="medium"
            flex="grow"
            align="center"
            justify="center"
        >
            <Box width={{ min: "340px" }} pad={{ bottom: "xlarge" }}>
                <Box direction="row">
                    <Heading
                        level="2"
                        pad={{ bottom: "large" }}
                        margin={{ top: "14px", right: "2px" }}
                    >
                        Having trouble logging in?
                    </Heading>
                </Box>

                <Box>
                    <ThemeContext.Extend
                        value={{
                            global: {
                                control: {
                                    border: { radius: "10px", color: "white" },
                                },
                                input: {
                                    padding: { left: "small", vertical: "8px" },
                                },
                            },
                            formField: {
                                border: null,
                            },
                            textInput: {
                                container: {
                                    extend: `
                                    // background: white;
                                    // border-radius: 6px;
                                    filter: drop-shadow(0px 8px 10px rgba(41, 70, 152, 0.05));`,
                                },
                            },
                        }}
                    >
                        <Form
                            onSubmit={handleSubmit(async ({ email }) => {
                                setPasswordResetSent(false);
                                setResetError(undefined);
                                try {
                                    await sendPasswordResetEmail(
                                        getAuth(getApp()),
                                        email
                                    );
                                    setPasswordResetSent(true);
                                } catch (error) {
                                    switch (error.code) {
                                        case "auth/user-not-found":
                                            setResetError(
                                                "No account found with that email address"
                                            );
                                            break;
                                        default:
                                            setResetError(
                                                "Internal error. Please try again."
                                            );
                                    }
                                }
                            })}
                        >
                            <FormField name="email" label="EMAIL">
                                <TextInput
                                    plain
                                    style={{
                                        border: 1,
                                        round: "xsmall",
                                        background: "white",
                                    }}
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: "Email is required",
                                        },
                                        pattern: {
                                            value: /^\S+@\S+\.\S+$/i,
                                            message: "Invalid email address",
                                        },
                                    })}
                                />
                                {errors.email && (
                                    <Box
                                        pad={{
                                            top: "xsmall",
                                            horizontal: "small",
                                        }}
                                    >
                                        <Text size="small" color="status-error">
                                            {errors.email.message}
                                        </Text>
                                    </Box>
                                )}
                            </FormField>
                            <FormField
                                margin={{ vertical: "medium" }}
                                direction="row"
                                justify="center"
                            >
                                <Button type="submit" label="Reset password" />
                            </FormField>
                            {resetError && (
                                <Notification
                                    status="critical"
                                    title="Password reset failed"
                                    message={resetError}
                                />
                            )}
                            {passwordResetSent && (
                                <Notification
                                    status="info"
                                    title="Check your email"
                                    message="An email has been sent with instructions on how to reset your password"
                                />
                            )}
                        </Form>
                    </ThemeContext.Extend>
                    <Box align="center" margin={{ top: "xlarge" }}>
                        <Text size="small">
                            Back to{" "}
                            <Anchor
                                color="primaryLabel"
                                onClick={() => history.push("/login")}
                            >
                                Login
                            </Anchor>
                        </Text>
                    </Box>
                </Box>
            </Box>
        </Page>
    );
}

export default PasswordReset;
