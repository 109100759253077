import concepts from "./concepts.svg";
import create from "./create.svg";
import documents from "./documents.svg";
import highlights from "./highlights.svg";
import theme from "./theme.svg";
import timer from "./timer.svg";
import archer from "./archer-logo.svg";

export const ConceptsIcon = concepts;
export const CreateIcon = create;
export const DocumentsIcon = documents;
export const HighlightsIcon = highlights;
export const ThemeIcon = theme;
export const TimerIcon = timer;
export const ArcherIcon = archer;
