import React from "react";

import { getApp } from "firebase/app";
import { GoogleAuthProvider, getAuth, signInWithRedirect } from "firebase/auth";

import Bugsnag from "@bugsnag/js";

import { Button, Image } from "grommet";
import { useHistory } from "react-router-dom";

const googleProvider = new GoogleAuthProvider();

function GoogleLoginButton(props) {
    const history = useHistory();

    return (
        <Button
            {...props}
            style={{ padding: "8px" }}
            label="Continue with Google"
            icon={<Image width="24px" src="/logo/google.svg" />}
            onClick={async () => {
                history.replace({
                    ...history.location,
                    // pathname: "/authenticating",
                    hash:
                        history.location.state &&
                        history.location.state.pathname,
                });

                await signInWithRedirect(
                    getAuth(getApp()),
                    googleProvider
                ).catch((error) => {
                    Bugsnag.notify(error);
                });
            }}
        />
    );
}

export default GoogleLoginButton;
