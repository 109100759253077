import React, { StrictMode, useEffect } from "react";

import {
    Route as RRoute,
    BrowserRouter as Router,
    Switch,
    useParams,
    useRouteMatch,
} from "react-router-dom";

import { Box, Grommet } from "grommet";
import { gql, useQuery } from "@apollo/client";

import { hotjar } from "react-hotjar";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";

import { initializeApp } from "firebase/app";
import {
    initializeAnalytics,
    logEvent,
    setUserId,
    setUserProperties,
} from "firebase/analytics";
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";
import { connectAuthEmulator, getAuth } from "firebase/auth";
import { connectFirestoreEmulator, getFirestore } from "firebase/firestore";

import uiTheme from "./styles/UITheme";
import { ScrollBox } from "./components/ScrollBox";

// import EvidenceSearch from "./EvidenceSearch";
import DocumentView from "./DocumentView";
import UserConceptSpaces from "./UserConceptSpaces";
import ConceptSpace from "./ConceptSpace";
import ResearchLog from "./ResearchLog";
import Summary from "./Summary";
import Theme from "./Theme";
import Concept from "./Concept";
import Answer from "./Answer";
import Documents from "./Documents";
import AddDocuments from "./AddDocuments";
import UserNotes from "./UserNotes";
import Roadmap from "./Roadmap";

import AppBar from "./components/AppBar";
// import MyErrorBoundary from "./components/ErrorBoundary";
import NotFound from "./components/NotFound";
import ErrorBox from "./components/ErrorBox";
import Maintenance from "./Maintenance";
import SignUp from "./SignUp";
import Login from "./Login";
import PasswordReset from "./PasswordReset";
import { useAuth } from "./components/auth/AuthProvider";
import Protected from "./components/auth/Protected";
import NewProject from "./NewProject";
import Chat from "./Chat";
import Account from "./Account";

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig =
    process.env.REACT_APP_ENV === "prod"
        ? {
              apiKey: "AIzaSyC522hzMgmeKFVP54zdl4rNvPqpQ-qE3FQ",
              authDomain: "archerapp.ai",
              projectId: "archer-358407",
              storageBucket: "archer-358407.appspot.com",
              messagingSenderId: "631845475162",
              appId: "1:631845475162:web:50038684ceebe351555963",
              measurementId: "G-R8KP42BYYP",
          }
        : {
              apiKey: "AIzaSyAnNEATpmUpyHF3GkMjsO7ZSjIm7HIpwi4",
              authDomain: "archer-dev-380811.firebaseapp.com",
              projectId: "archer-dev-380811",
              storageBucket: "archer-dev-380811.appspot.com",
              messagingSenderId: "987390458475",
              appId: "1:987390458475:web:c94dd143431b3b5540cf2e",
              measurementId: "G-NF2T9DCLMX",
          };

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = initializeAnalytics(app, {
    config: { send_page_view: true }, // set false to stop sending page_view events
});
const firestore = getFirestore(app);

if (process.env.NODE_ENV === "developmentX") {
    if (window.location.host === "http://localhost:5000") {
        connectAuthEmulator(getAuth(app), "http://localhost:9099");
        connectFunctionsEmulator(getFunctions(app), "localhost", 5001);
    }
    connectFirestoreEmulator(firestore, "localhost", 8080);
}

Bugsnag.start({
    apiKey: "67c4e75fb62bacf9dfe3fd30f197c7b1",
    plugins: [new BugsnagPluginReact()],
    metadata: {
        version: process.env.REACT_APP_VERSION,
    },
});

const ErrorBoundary = Bugsnag.getPlugin("react").createErrorBoundary(React);

const GET_CONCEPT_SPACE_STATUS = gql`
    query getConceptSpaceStatus($conceptSpacesWhere: ConceptSpaceWhere) {
        conceptSpaces(where: $conceptSpacesWhere) {
            name
            status
            expired
        }
    }
`;

function Route({ exact, path, children, strictMode = true }) {
    return (
        <RRoute exact={exact} path={path}>
            {strictMode ? (
                <StrictMode>
                    <ScreenTracker path={path}>{children}</ScreenTracker>
                </StrictMode>
            ) : (
                <ScreenTracker path={path}>{children}</ScreenTracker>
            )}
        </RRoute>
    );
}

function ScreenTracker({ path, children }) {
    const match = useRouteMatch({ path, exact: true });

    useEffect(() => {
        if (match) {
            logEvent(analytics, "screen_view", {
                firebase_screen: match.path,
                firebase_screen_class: match.path,
                app_version: process.env.REACT_APP_VERSION,
                ...match.params,
            });
        }
    }, [match && match.path]);

    return children;
}

function ConceptSpaceRoute() {
    const { conceptSpaceId } = useParams();
    const { loading, error, data } = useQuery(GET_CONCEPT_SPACE_STATUS, {
        variables: {
            conceptSpacesWhere: {
                id: conceptSpaceId,
                expired: null,
                AND: [
                    {
                        OR: [
                            { status: null },
                            { status_NOT_IN: ["DELETING", "DELETED"] },
                        ],
                    },
                    {
                        OR: [{ locked: false }, { locked: null }],
                    },
                ],
            },
        },
    });

    if (!loading && !error && !data?.conceptSpaces?.length) {
        return <NotFound />;
    }

    return (
        <Switch>
            <Route exact path="/:conceptSpaceId">
                <ConceptSpace />
            </Route>
            <Route exact path="/:conceptSpaceId/log">
                <ResearchLog />
            </Route>
            <Route exact path="/:conceptSpaceId/docs">
                <Documents />
            </Route>
            <Route exact path="/:conceptSpaceId/import">
                <AddDocuments />
            </Route>
            <Route path="/:conceptSpaceId/docs/:uri+" strictMode={false}>
                <DocumentView />
            </Route>
            <Route exact path="/:conceptSpaceId/summary">
                <Summary />
            </Route>
            <Route path="/:conceptSpaceId/themes/:themeName">
                <Theme />
            </Route>
            <Route path="/:conceptSpaceId/concepts/:conceptId">
                <Concept />
            </Route>
            <Route exact path="/:conceptSpaceId/answers">
                <Answer />
            </Route>
            <Route exact path="/:conceptSpaceId/notes">
                <UserNotes />
            </Route>
            <Route exact path="/:conceptSpaceId/chat/:conversationId?">
                <Chat />
            </Route>
        </Switch>
    );
}

function App() {
    const { user, roles } = useAuth();

    useEffect(() => {
        if (user) {
            setUserId(analytics, user.uid);
            setUserProperties(analytics, { roles });

            Bugsnag.setUser(user.uid);

            if (hotjar.initialized()) {
                hotjar.identify(user.uid, {});
            }
        }
    }, [user]);

    return (
        <Router>
            <Grommet theme={uiTheme} full>
                <Box fill>
                    <Switch>
                        <Route exact path="/login">
                            <Login />
                        </Route>
                        <Route exact path="/signup">
                            <SignUp />
                        </Route>
                        <Route exact path="/passwordreset">
                            <PasswordReset />
                        </Route>
                        <Protected>
                            <Route path="/">
                                <ErrorBoundary FallbackComponent={ErrorBox}>
                                    <AppBar heading="" />
                                </ErrorBoundary>
                                <ScrollBox>
                                    <ErrorBoundary FallbackComponent={ErrorBox}>
                                        <Switch>
                                            <Route exact path="/roadmap">
                                                <Roadmap />
                                            </Route>
                                            {process.env
                                                .REACT_APP_MAINTENANCE_MODE && (
                                                <Route path="/">
                                                    <Maintenance />
                                                </Route>
                                            )}
                                            <Route path="/account">
                                                <Account />
                                            </Route>
                                            <Route exact path="/">
                                                <UserConceptSpaces />
                                            </Route>
                                            <Route path="/newproject">
                                                <NewProject />
                                            </Route>

                                            <Route
                                                path="/:conceptSpaceId"
                                                strictMode={false}
                                            >
                                                <ConceptSpaceRoute />
                                            </Route>
                                        </Switch>
                                    </ErrorBoundary>
                                </ScrollBox>
                            </Route>
                        </Protected>
                    </Switch>
                </Box>
            </Grommet>
        </Router>
    );
}

export default App;
