import React, { useEffect, useState } from "react";

import {
    Box,
    Button,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    DataTable,
    Grid,
    Heading,
    Page,
    PageContent,
    ResponsiveContext,
    Spinner,
    Text,
} from "grommet";

import { getFunctions, httpsCallable } from "firebase/functions";
import { getApp } from "firebase/app";

import {
    createCheckoutSession,
    getStripePayments,
} from "@stripe/firestore-stripe-payments";

import { useQuery } from "@apollo/client";

import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import localizedFormat from "dayjs/plugin/localizedFormat";
import advancedFormat from "dayjs/plugin/advancedFormat";

import { StatusGood } from "grommet-icons";
import {
    getPlans,
    getSubscription,
    getUsagePeriod,
} from "./components/auth/subscription";
import { useAuth } from "./components/auth/AuthProvider";
import ErrorBox from "./components/ErrorBox";
import GET_USAGE from "./graphql/getUsageQuery";

dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(localizedFormat);
dayjs.extend(advancedFormat);

function Account() {
    const { user, plan, isAdmin, updateClaims } = useAuth();
    const [plans, setPlans] = useState();
    const [subscription, setSubscription] = useState();
    const [usagePeriod, setUsagePeriod] = useState();
    const [stripeLoading, setStripeLoading] = useState();
    const [error, setError] = useState();

    const size = React.useContext(ResponsiveContext);

    const payments = getStripePayments(getApp(), {
        productsCollection: "products",
        customersCollection: "users",
    });

    useEffect(() => {
        updateClaims();

        getPlans().then(setPlans);
        getSubscription(user).then(setSubscription);
        getUsagePeriod(user).then(setUsagePeriod);
    }, [user]);

    const currentPlanIndex =
        plans && plans.findIndex(({ role }) => plan === role);

    const { data: usage, loading: usageLoading } = useQuery(GET_USAGE, {
        variables: {
            where: { updated_GT: usagePeriod && usagePeriod[0] },
        },
        skip: !usagePeriod,
    });

    return (
        <Page kind="wide" flex="grow" pad={{ top: "medium", bottom: "large" }}>
            {error && (
                <ErrorBox error={error} canGoBack={false} canRetry={false} />
            )}
            <PageContent
                margin="medium"
                pad={{ horizontal: "large", bottom: "large" }}
                height={{ min: "medium" }}
                gap="medium"
            >
                <Heading
                    level={2}
                    size={size}
                    margin={{ top: "large", bottom: "small" }}
                    pad="none"
                >
                    {isAdmin ? "Your Admin Account" : "Your account"}
                </Heading>
                <Box>
                    <Heading level={2} size="small">
                        Profile
                    </Heading>
                    <Text>{user.displayName}</Text>
                    <Text>{user.email}</Text>
                </Box>
                <Box margin={{ vertical: "small" }}>
                    <Heading level={2} size="small">
                        Subscription
                    </Heading>
                    {plans ? (
                        <Grid
                            columns={size !== "small" ? "220px" : "100%"}
                            gap="medium"
                        >
                            {plans.map(
                                (
                                    {
                                        id,
                                        name,
                                        description,
                                        prices,
                                        metadata = {},
                                    },
                                    i
                                ) => {
                                    let action;
                                    let displayPrice;
                                    if (currentPlanIndex === i) {
                                        if (subscription) {
                                            action = (
                                                <Button
                                                    disabled={stripeLoading}
                                                    plain={stripeLoading === id}
                                                    label={
                                                        stripeLoading === id ? (
                                                            <Spinner size="small" />
                                                        ) : (
                                                            "Manage plan"
                                                        )
                                                    }
                                                    onClick={async () => {
                                                        setStripeLoading(id);
                                                        const functions =
                                                            getFunctions();
                                                        const functionRef =
                                                            httpsCallable(
                                                                functions,
                                                                "ext-firestore-stripe-payments-createPortalLink"
                                                            );
                                                        const { data } =
                                                            await functionRef({
                                                                returnUrl:
                                                                    window
                                                                        .location
                                                                        .origin,
                                                                locale: "auto", // Optional, defaults to "auto"
                                                                // configuration: "bpc_1JSEAKHYgolSBA358VNoc2Hs", // Optional ID of a portal configuration: https://stripe.com/docs/api/customer_portal/configuration
                                                            });
                                                        window.location.assign(
                                                            data.url
                                                        );
                                                    }}
                                                />
                                            );
                                        }
                                    } else if (currentPlanIndex < i) {
                                        if (metadata.tier) {
                                            action = (
                                                <Button
                                                    disabled={
                                                        stripeLoading === null
                                                    }
                                                    plain={stripeLoading === id}
                                                    label={
                                                        stripeLoading === id ? (
                                                            <Spinner size="small" />
                                                        ) : (
                                                            "Upgrade"
                                                        )
                                                    }
                                                    onClick={async () => {
                                                        setStripeLoading(id);
                                                        try {
                                                            const session =
                                                                await createCheckoutSession(
                                                                    payments,
                                                                    {
                                                                        price: prices[0]
                                                                            .id,
                                                                    }
                                                                );
                                                            window.location.assign(
                                                                session.url
                                                            );
                                                        } catch (e) {
                                                            setError(e);
                                                            setStripeLoading(
                                                                false
                                                            );
                                                        }
                                                    }}
                                                />
                                            );
                                        } else {
                                            action = (
                                                <Button
                                                    label="Contact us"
                                                    onClick={() => {
                                                        window.open(
                                                            "https://www.move37.ai/contact",
                                                            "_blank"
                                                        );
                                                    }}
                                                />
                                            );
                                        }
                                    }

                                    if (
                                        metadata.tier &&
                                        currentPlanIndex !== i
                                    ) {
                                        displayPrice = (
                                            <Text>
                                                {(prices.length > 0 &&
                                                    `${
                                                        prices[0].unit_amount /
                                                        100
                                                    } ${prices[0].currency.toUpperCase()}/month`) ||
                                                    "FREE"}
                                            </Text>
                                        );
                                    } else {
                                        displayPrice = <Text />;
                                    }

                                    return (
                                        <Card
                                            pad="small"
                                            key={id}
                                            round="none"
                                            border={currentPlanIndex === i}
                                        >
                                            <CardHeader
                                                justify="start"
                                                direction="column"
                                                height="small"
                                            >
                                                <Heading level={4}>
                                                    {name}
                                                </Heading>
                                                <Text>{description}</Text>
                                                {currentPlanIndex === i && (
                                                    <StatusGood
                                                        size="medium"
                                                        color="status-ok"
                                                    />
                                                )}
                                            </CardHeader>
                                            <CardBody margin="none">
                                                <Text size="xsmall">
                                                    <DataTable
                                                        size="small"
                                                        fill={false}
                                                        columns={[
                                                            {
                                                                property:
                                                                    "name",
                                                                render: ({
                                                                    name: val,
                                                                }) => (
                                                                    <Text
                                                                        size="small"
                                                                        weight="bold"
                                                                    >
                                                                        {val}
                                                                    </Text>
                                                                ),
                                                            },
                                                            {
                                                                property:
                                                                    "value",
                                                                render: ({
                                                                    value,
                                                                }) => (
                                                                    <Text size="small">
                                                                        {value}
                                                                    </Text>
                                                                ),
                                                            },
                                                        ]}
                                                        data={[
                                                            {
                                                                name: "Projects",
                                                                value:
                                                                    metadata.projects ||
                                                                    "Custom",
                                                            },
                                                            {
                                                                name: "Project lifetime",
                                                                value: metadata.projectLifetimeMonths
                                                                    ? `${
                                                                          metadata.projectLifetimeMonths
                                                                      } month${
                                                                          metadata.projectLifetimeMonths >
                                                                          1
                                                                              ? "s"
                                                                              : ""
                                                                      }`
                                                                    : "Unlimited",
                                                            },
                                                            {
                                                                name: "Documents per project",
                                                                value:
                                                                    metadata.documentsPerProject ||
                                                                    "Unlimited",
                                                            },
                                                            {
                                                                name: "Processing per month",
                                                                value: metadata.wordsPerMonth
                                                                    ? `${parseInt(
                                                                          metadata.wordsPerMonth,
                                                                          10
                                                                      ).toLocaleString()} words `
                                                                    : "Custom",
                                                            },
                                                        ]}
                                                    />
                                                </Text>
                                            </CardBody>
                                            <CardFooter
                                                direction="column"
                                                justify="end"
                                                margin={{
                                                    top: "medium",
                                                }}
                                            >
                                                {displayPrice}
                                                <Box height={{ min: "60px" }}>
                                                    {action}
                                                </Box>
                                            </CardFooter>
                                        </Card>
                                    );
                                }
                            )}
                        </Grid>
                    ) : (
                        <Spinner />
                    )}
                </Box>
                <Box>
                    <Heading level={2} size="small">
                        Usage
                    </Heading>
                    {usageLoading || subscription === undefined ? (
                        <Spinner />
                    ) : (
                        <Box direction="row">
                            <DataTable
                                fill={false}
                                columns={[
                                    { property: "name" },
                                    { property: "value" },
                                ]}
                                data={[
                                    {
                                        name: "Projects",
                                        value: usage?.conceptSpacesAggregate
                                            .count,
                                    },
                                    {
                                        name: "Processing",
                                        value: `${(
                                            (usage?.notTruncated?.wordCount
                                                ?.sum || 0) +
                                            (usage?.truncated?.wordCount?.sum ||
                                                0)
                                        ).toLocaleString()} words`,
                                    },
                                    {
                                        name: "Resets on",
                                        value: usagePeriod[1]?.format("lll z"),
                                    },
                                ]}
                            />
                        </Box>
                    )}
                </Box>
            </PageContent>
        </Page>
    );
}

export default Account;
