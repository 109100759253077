import React from "react";
import { Anchor, Box, Notification, Text } from "grommet";

function parseError(error) {
    const res = {
        status: "critical",
        message: "Internal error. Please try again.",
    };
    switch (error.code) {
        case "auth/wrong-password":
        case "auth/user-not-found":
            res.message = "Incorrect email or password";
            break;
        case "auth/email-already-in-use":
            res.message = "An account with that email address already exists";
            break;
        case "auth/internal-error": {
            const jsonError = error.message.match(/{.*}/g);
            if (jsonError) {
                const errorDetail = JSON.parse(jsonError);
                if (
                    errorDetail &&
                    errorDetail.error &&
                    errorDetail.error.status === "PERMISSION_DENIED"
                ) {
                    res.title = "Join the Archer Waitlist";
                    res.status = "info";
                    res.message = (
                        <Box gap="small">
                            <Text>
                                Archer is currently only available to invited
                                users.
                            </Text>
                            <Anchor
                                href={process.env.REACT_APP_WAITLIST_SIGNUP_URL}
                            >
                                Click here to get your invitation
                            </Anchor>
                        </Box>
                    );
                }
            }
            break;
        }
        default:
    }
    return res;
}

function AuthErrorNotification({ title, error }) {
    return <Notification title={title} {...parseError(error)} />;
}

export default AuthErrorNotification;
