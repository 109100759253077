import React, { useEffect, useRef } from "react";
import { TextArea } from "grommet";

function ExpandingTextArea({ ...props }) {
    const { onChange, style, value } = props;
    const textAreaRef = useRef(null);

    useEffect(() => {
        if (!value && textAreaRef.current) {
            textAreaRef.current.style.height = "auto";
        }
    }, [value]);

    const handleChange = (event) => {
        const { current } = textAreaRef;
        if (current) {
            current.style.height = "auto";
            if (current.style.maxHeight) {
                current.style.height = `${Math.min(
                    current.scrollHeight,
                    parseInt(current.style.maxHeight, 10)
                )}px`;
            } else {
                current.style.height = `${current.scrollHeight}px`;
            }
        }
        if (onChange) {
            onChange(event);
        }
    };

    return (
        <TextArea
            {...props}
            autoFocus
            ref={textAreaRef}
            onChange={handleChange}
            style={{ resize: "none", overflow: "auto", ...style }}
        />
    );
}

export default ExpandingTextArea;
