import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useForm } from "react-hook-form";
import { getApp } from "firebase/app";
import {
    getAuth,
    getRedirectResult,
    signInWithEmailAndPassword,
} from "firebase/auth";

import {
    Anchor,
    Box,
    Button,
    Form,
    FormField,
    Heading,
    Image,
    Page,
    Spinner,
    Text,
    TextInput,
    ThemeContext,
} from "grommet";

import { useAuth } from "./components/auth/AuthProvider";
import PasswordInput from "./components/auth/PasswordInput";
import GoogleLoginButton from "./components/auth/GoogleLoginButton";
import AuthErrorNotification from "./components/auth/AuthErrorNotification";

function Login() {
    const history = useHistory();
    const { hash } = useLocation();
    const { register, handleSubmit, formState } = useForm();
    const [loginError, setLoginError] = useState();
    const [loggingIn, setLoggingIn] = useState(false);
    const { loading } = useAuth();

    const { errors } = formState;

    useEffect(() => {
        getRedirectResult(getAuth(getApp()))
            .then((result) => {
                if (result) {
                    history.replace(hash ? hash.slice(1) : "/");
                }
            })
            .catch((error) => {
                setLoginError(error);
            });
    }, []);

    return (
        <Page
            kind="narrow"
            pad="medium"
            flex="grow"
            align="center"
            justify="center"
        >
            <Box pad={{ bottom: "xlarge" }} align="center">
                <Image
                    height="100px"
                    alt="Archer logo"
                    src="/logo/archer-secondary-logo.png"
                />
                <Box direction="row" margin={{ bottom: "large" }}>
                    <Heading
                        level="2"
                        size="small"
                        pad={{ bottom: "large" }}
                        margin={{ top: "14px", right: "2px" }}
                    >
                        Login to Archer
                    </Heading>
                </Box>
                {/* <Box margin={{ bottom: "large" }}>
                    <Text size="small">
                        Welcome back! What are you going to discover today?
                    </Text>
                </Box> */}
                <Box width="300px" margin={{ bottom: "small" }}>
                    <ThemeContext.Extend
                        value={{
                            global: {
                                control: {
                                    border: { radius: "10px", color: "white" },
                                },
                                input: {
                                    padding: { left: "small", vertical: "8px" },
                                },
                            },
                            formField: {
                                border: null,
                            },
                            textInput: {
                                container: {
                                    extend: `
                                    filter: drop-shadow(0px 8px 10px rgba(41, 70, 152, 0.05));`,
                                },
                            },
                        }}
                    >
                        <Form
                            onSubmit={handleSubmit(
                                async ({ email, password }) => {
                                    setLoginError(undefined);
                                    setLoggingIn(true);
                                    try {
                                        await signInWithEmailAndPassword(
                                            getAuth(getApp()),
                                            email,
                                            password
                                        );

                                        history.replace({
                                            ...history.location,
                                            pathname: history.location.state
                                                ? history.location.state
                                                      .pathname
                                                : "/",
                                        });
                                    } catch (error) {
                                        setLoginError(error);
                                    } finally {
                                        setLoggingIn(false);
                                    }
                                }
                            )}
                        >
                            <FormField>
                                <GoogleLoginButton
                                    disabled={loading || loggingIn}
                                />
                            </FormField>
                            <Box
                                direction="row"
                                margin={{ top: "large", bottom: "small" }}
                                align="center"
                                gap="small"
                            >
                                <Box
                                    fill
                                    border={{
                                        side: "bottom",
                                        color: "light-5",
                                        size: "1px",
                                    }}
                                />
                                <Box flex="grow">
                                    <Text size="xsmall" wordBreak="keep-all">
                                        or continue with email
                                    </Text>
                                </Box>
                                <Box
                                    fill
                                    border={{
                                        side: "bottom",
                                        color: "light-5",
                                        size: "1px",
                                    }}
                                />
                            </Box>
                            <FormField name="email" label="EMAIL">
                                <TextInput
                                    plain
                                    style={{
                                        border: 1,
                                        round: "xsmall",
                                        background: "white",
                                    }}
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: "Email is required",
                                        },
                                        pattern: {
                                            value: /^\S+@\S+\.\S+$/i,
                                            message: "Invalid email address",
                                        },
                                    })}
                                    disabled={loading || loggingIn}
                                />
                                {errors.email && (
                                    <Box
                                        pad={{
                                            top: "xsmall",
                                            horizontal: "small",
                                        }}
                                    >
                                        <Text size="small" color="status-error">
                                            {errors.email.message}
                                        </Text>
                                    </Box>
                                )}
                            </FormField>
                            <FormField name="password" label="PASSWORD">
                                <PasswordInput
                                    style={{
                                        border: 1,
                                        round: "xsmall",
                                        background: "white",
                                    }}
                                    {...register("password", {
                                        required: {
                                            value: true,
                                            message: "Password is required",
                                        },
                                    })}
                                    disabled={loading || loggingIn}
                                />
                                {errors.password && (
                                    <Box
                                        pad={{
                                            top: "xsmall",
                                            horizontal: "small",
                                        }}
                                    >
                                        <Text size="small" color="status-error">
                                            {errors.password.message}
                                        </Text>
                                    </Box>
                                )}
                            </FormField>
                            <Box pad={{ left: "small" }}>
                                <Text size="small">
                                    <Anchor
                                        color="primaryLabel"
                                        onClick={() =>
                                            history.push("/passwordreset")
                                        }
                                        disabled={loading || loggingIn}
                                    >
                                        Forgot password?
                                    </Anchor>
                                </Text>
                            </Box>
                            <FormField
                                margin={{ vertical: "medium" }}
                                direction="row"
                                justify="center"
                            >
                                <Button
                                    type="submit"
                                    label="Login to Archer"
                                    disabled={loading || loggingIn}
                                />
                            </FormField>
                            {(loading || loggingIn) && (
                                <Box align="center">
                                    <Spinner />
                                </Box>
                            )}
                        </Form>
                    </ThemeContext.Extend>
                </Box>
                {loginError && (
                    <AuthErrorNotification
                        title="Login attempt failed"
                        error={loginError}
                    />
                )}
                <Box align="center" margin={{ top: "xlarge" }}>
                    <Text size="small">
                        Don&apos;t have an account yet?{" "}
                        <Anchor
                            color="primaryLabel"
                            onClick={() =>
                                history.push({
                                    ...history.location,
                                    pathname: "/signup",
                                })
                            }
                        >
                            Sign Up
                        </Anchor>
                    </Text>
                </Box>
            </Box>
        </Page>
    );
}

export default Login;
