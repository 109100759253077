import React, {
    forwardRef,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { useHistory, useParams } from "react-router-dom";

import {
    Anchor,
    Box,
    Button,
    Heading,
    Image,
    Page,
    PageContent,
    ResponsiveContext,
    Spinner,
    Text,
} from "grommet";
import { Ascending } from "grommet-icons";
import styled from "styled-components";

import { gql, useLazyQuery, useQuery } from "@apollo/client";
import ErrorBox from "./components/ErrorBox";
import SearchBar from "./components/SearchBar";
import SkipNav from "./components/SkipNav";
import ContentCard from "./components/ContentCard";
import ConceptMentions from "./components/ConceptMentions";
import Notes from "./components/Notes";
import BookmarkButton from "./components/BookmarkButton";
import NoteContext from "./components/NoteContext";

import { HighlightsIcon } from "./icons";

import useQueryString from "./utils/useQueryString";
import { useAuth } from "./components/auth/AuthProvider";
import { ScrollContext } from "./components/ScrollBox";

const chatUrl =
    process.env.REACT_APP_CHAT_URL ||
    (process.env.NODE_ENV === "development"
        ? "http://localhost:8083"
        : // "https://archer-chat-ptm2rbpu7a-wn.a.run.app"
          "/agent/chat");

const GET_ANSWER = gql`
    query GetAnswer(
        $where: QueryResultWhere
        $options: QueryResultOptions
        $conceptsWhere: ConceptsWhere
        $conceptsOptions: PaginationOptions
    ) {
        queryResults(where: $where, options: $options) {
            query
            answer
            updated
            concepts(where: $conceptsWhere, options: $conceptsOptions) {
                id
                name
                entityType
                source
            }
        }
    }
`;

const GET_ANSWER_MENTIONS = gql`
    query GetAnswerMentions(
        $where: QueryResultWhere
        $options: QueryResultOptions
        $sentencesWhere: QueryResultSentencesConnectionWhere
    ) {
        queryResults(where: $where, options: $options) {
            sentencesConnection(
                where: $sentencesWhere
                sort: [{ edge: { relevance: DESC } }]
                first: 200
            ) {
                edges {
                    relevance
                    node {
                        text
                        documentConnection {
                            edges {
                                passage
                                sentence
                                node {
                                    title
                                    path
                                    uri
                                    date
                                    sitename
                                }
                            }
                        }
                        mentionsConnection {
                            edges {
                                startOffset
                                endOffset
                                node {
                                    id
                                    entityType
                                    parent {
                                        id
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`;

const conceptFilters = {
    "All Concepts": null,
    People: ["PER", "PERSON"],
    Places: ["LOC", "GPE"],
    Organisations: ["ORG"],
};

function renderCitationsAsLinks(str, onClickCitation) {
    // Regular expression to match citations of the form [1], [12], etc.
    const citationRegex = /(\[\d+\])/;

    // Split the string into an array of elements
    const elements = [];
    const parts = str.split(citationRegex);

    for (let i = 0; i < parts.length; i += 1) {
        if (parts[i].match(citationRegex)) {
            // If the part matches the citation pattern, create a link element
            const citationNumber = parts[i].match(/\d+/)[0];
            const link = (
                <Anchor
                    size="xsmall"
                    // weight="normal"
                    style={{ verticalAlign: "super", marginLeft: "2px" }}
                    // href={`#${citationNumber}`}
                    onClick={() =>
                        onClickCitation(parseInt(citationNumber, 10))
                    }
                >
                    [{citationNumber}]
                </Anchor>
            );
            elements.push(link);
        } else {
            // Otherwise, create a text element
            const text = <span>{parts[i]}</span>;
            elements.push(text);
        }
    }

    return elements;
}

const AnswerText = forwardRef(function AnswerText(
    { loading, error, answer, onClickCitation },
    ref
) {
    return (
        <ContentCard
            icon={<Image src={HighlightsIcon} width="20px" height="20px" />}
            header={
                <Heading
                    id="highlights"
                    level={4}
                    color="primaryLabel"
                    margin="none"
                >
                    Answer
                </Heading>
            }
            content={
                <Box
                    ref={ref}
                    pad={{
                        // left: "medium",
                        right: "medium",
                        bottom: "medium",
                    }}
                    gap="small"
                >
                    {error && <ErrorBox error={error} />}
                    {!loading && !error && answer && answer.length === 0 && (
                        <Text>Nothing relevant found.</Text>
                    )}
                    <Text color="grey">
                        {answer &&
                            answer.map((sentence) => (
                                <Box
                                    direction="row"
                                    margin={{ vertical: "small" }}
                                    animation="fadeIn"
                                >
                                    <span
                                        style={{
                                            color: "silver",
                                            marginRight: "1em",
                                        }}
                                    >
                                        -
                                    </span>
                                    <div
                                        key={sentence}
                                        style={{
                                            paddingRight: "0.5em",
                                            marginBottom: "0.5em",
                                        }}
                                    >
                                        {/* <SelectableText canBookmark={false}> */}
                                        {renderCitationsAsLinks(
                                            sentence,
                                            onClickCitation
                                        )}
                                        {/* </SelectableText> */}
                                    </div>
                                </Box>
                            ))}
                    </Text>
                    {loading && !error && <Spinner alignSelf="center" />}
                </Box>
            }
        />
    );
});

const BackToTopButton = styled(Button)`
    position: fixed;
    bottom: 30px;
    right: 50px;
    opacity: ${(props) => (props.visible ? 1 : 0)};
    transition: opacity 0.3s ease-in-out;
`;

function Answer() {
    const history = useHistory();
    const { conceptSpaceId } = useParams();

    const urlQuery = useQueryString();
    const [query, setQuery] = useState();
    // const [title] = useState();
    const [answer, setAnswer] = useState();

    const [streaming, setStreaming] = useState();
    const [streamingAnswer, setStreamingAnswer] = useState();
    const [streamingError, setStreamingError] = useState();

    const [selectedCitation, setSelectedCitation] = useState();
    const [scrollToTopVisible, setScrollToTopVisible] = useState(false);

    const size = React.useContext(ResponsiveContext);
    const scrollBox = React.useContext(ScrollContext);
    const answerRef = useRef();

    const { user } = useAuth();

    const [selectedConcept, setSelectedConcept] = useState(
        history.location.state && history.location.state.selectedConcept
    );
    const [selectedFilter, setSelectedFilter] = useState(
        Object.keys(conceptFilters)[0]
    );

    const noteContext = useMemo(() => ({
        conceptSpaceId,
    }));

    const { loading, error, data, previousData, refetch } = useQuery(
        GET_ANSWER,
        {
            variables: {
                where: {
                    conceptSpace: { id: conceptSpaceId },
                    query,
                },
                conceptsWhere: {
                    entityType_NOT_IN: [
                        "DATE",
                        "TIME",
                        "MONEY",
                        "PERCENT",
                        "QUANTITY",
                    ],
                },
                ...(conceptFilters[selectedFilter] && {
                    conceptsWhere: {
                        entityType_IN: conceptFilters[selectedFilter],
                    },
                }),
                options: {
                    sort: [
                        {
                            updated: "DESC",
                        },
                    ],
                    limit: 1,
                },
                conceptsOptions: {
                    limit: 20,
                },
                // associationsOptions: {
                //     limit: 100,
                // },
                // sentencesOptions: {
                //     limit: 100,
                // },
            },
            skip: !query,
        }
    );

    const [
        getMentions,
        {
            loading: mentionsLoading,
            error: mentionsError,
            data: mentionsData,
            previousData: previousMentionsData,
        },
    ] = useLazyQuery(GET_ANSWER_MENTIONS);

    const streamAnswer = useCallback(async () => {
        setStreaming(true);
        const sessionToken = await user.getIdToken();

        try {
            const response = await fetch(`${chatUrl}/answer`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    query,
                    conceptSpace: conceptSpaceId,
                }),
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let done = false;

            while (!done) {
                // if (stopConversationRef.current === true) {
                //   controller.abort();
                //   done = true;
                //   break;
                // }
                // eslint-disable-next-line no-await-in-loop
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                const chunkValue = decoder.decode(value);

                setStreamingAnswer((prevChunks) => {
                    const updatedChunks = prevChunks + chunkValue;
                    return updatedChunks;
                });
            }
            setStreaming(false);

            // refetch query to update cache
            refetch();
        } catch (e) {
            setStreamingError(e);
        }
    });

    useEffect(() => {
        if (streamingAnswer) {
            const parts = streamingAnswer.split("\n");
            setAnswer(
                parts.map((line) => {
                    if (line.startsWith("- ")) {
                        return line.slice(2);
                    }
                    return line;
                })
            );
        }
    }, [streamingAnswer]);

    useEffect(() => {
        if (urlQuery) {
            setQuery(urlQuery.get("q"));
            setSelectedConcept(null);
            setAnswer(null);
        }
    }, [urlQuery]);

    useEffect(() => {
        if (!loading && !error && query && data) {
            const {
                queryResults: [{ answer: newAnswer } = {}],
            } = data;
            if (!newAnswer) {
                // stream an answer
                setStreamingAnswer("");
                streamAnswer();
            } else {
                // show cached answer
                setAnswer(newAnswer);
            }
            getMentions({
                variables: {
                    where: {
                        conceptSpace: { id: conceptSpaceId },
                        query,
                    },
                    ...(conceptFilters[selectedFilter] && {
                        sentencesWhere: {
                            node: {
                                mentions: {
                                    OR: [
                                        {
                                            entityType_IN:
                                                conceptFilters[selectedFilter],
                                        },
                                        {
                                            parent: {
                                                entityType_IN:
                                                    conceptFilters[
                                                        selectedFilter
                                                    ],
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    }),
                    ...(selectedConcept && {
                        sentencesWhere: {
                            node: {
                                mentions: {
                                    OR: [
                                        { id: selectedConcept.id },
                                        {
                                            parent: {
                                                id: selectedConcept.id,
                                            },
                                        },
                                    ],
                                },
                            },
                        },
                    }),
                    // sentencesOptions: {
                    //     limit: 100,
                    // },
                },
            });
        }
    }, [loading, error, data, selectedConcept, selectedFilter]);

    useEffect(() => {
        // preserve state for back navigation
        history.replace({ ...history.location, state: { selectedConcept } });
    }, [selectedConcept]);

    const { queryResults: [{ concepts = [] } = {}] = [{}] } =
        data || previousData || {};

    const {
        queryResults: [
            { sentencesConnection: { edges: sentences = null } = {} } = {},
        ] = [{}],
    } = mentionsData || previousMentionsData || {};

    useEffect(() => {
        let timeoutId;

        const handleScrollAway = () => {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                setSelectedCitation(undefined);
                scrollBox.removeEventListener("scroll", this);
            }, 50);
        };

        const handleScrollTo = () => {
            clearTimeout(timeoutId);

            timeoutId = setTimeout(() => {
                scrollBox.removeEventListener("scroll", handleScrollTo);
                scrollBox.addEventListener("scroll", handleScrollAway);
            }, 50);
        };

        if (scrollBox && selectedCitation) {
            scrollBox.addEventListener("scroll", handleScrollTo);
        }

        return () => {
            if (scrollBox) {
                scrollBox.removeEventListener("scroll", handleScrollTo);
                scrollBox.removeEventListener("scroll", handleScrollAway);
            }
        };
    }, [scrollBox, selectedCitation]);

    // handle scroll to top
    useEffect(() => {
        const observer = new IntersectionObserver((entries) => {
            entries.forEach((entry) => {
                setScrollToTopVisible(!entry.isIntersecting);
            });
        });

        if (answerRef.current) {
            observer.observe(answerRef.current);
        }

        return () => {
            if (answerRef.current) {
                observer.unobserve(answerRef.current);
            }
        };
    }, []);

    return (
        <Page kind="wide" flex="grow">
            <NoteContext.Provider value={noteContext}>
                <PageContent margin={{ top: "large" }}>
                    <SearchBar
                        size={size === "small" ? "small" : "large"}
                        text={query}
                        onSearch={(value) => {
                            if (value) {
                                if (value instanceof Object) {
                                    history.push(
                                        `/${conceptSpaceId}/${
                                            value.type
                                        }s/${encodeURIComponent(
                                            value.id || value.name
                                        )}`
                                    );
                                } else {
                                    urlQuery.set("q", value);
                                    history.replace({
                                        ...history.location,
                                        search: urlQuery.toString(),
                                    });
                                }
                            }
                        }}
                    />
                    {size === "small" && (
                        <Heading
                            level={2}
                            size="medium"
                            margin={{ top: "large", bottom: "medium" }}
                            fill
                        >
                            {query}
                        </Heading>
                    )}
                    <Box width="small" pad="none" margin={{ top: "medium" }}>
                        <BookmarkButton
                            conceptSpaceId={conceptSpaceId}
                            targetType="QueryResult"
                            targetNode={{
                                query,
                                conceptSpace: {
                                    id: conceptSpaceId,
                                },
                            }}
                        />
                    </Box>
                </PageContent>
                <PageContent>
                    <Box margin={{ left: "22px" }}>
                        <SkipNav
                            items={[
                                ...[
                                    (answer && answer.length) > 0 && {
                                        id: "answer",
                                        label: "Answer",
                                    },
                                ],
                                { id: "concepts", label: "Concepts" },
                                { id: "mentions", label: "Mentions" },
                            ]}
                        />
                        <Box direction="row" justify="between" gap="large">
                            <Box margin={{ bottom: "small" }} fill>
                                {error && <ErrorBox error={error} />}
                                {streamingError && (
                                    <ErrorBox error={streamingError} />
                                )}
                                {mentionsError && (
                                    <ErrorBox error={mentionsError} />
                                )}
                                <AnswerText
                                    ref={answerRef}
                                    loading={
                                        (data === undefined &&
                                            error === undefined) ||
                                        (!answer && loading) ||
                                        streaming
                                    }
                                    answer={
                                        answer && streaming
                                            ? answer.slice(0, answer.length - 1)
                                            : answer
                                        // answer ||
                                        // (streamingAnswer
                                        //     ? [streamingAnswer]
                                        //     : null)
                                    }
                                    onClickCitation={(citation) =>
                                        setSelectedCitation(citation)
                                    }
                                />
                                {concepts && (
                                    <ConceptMentions
                                        conceptsLabel="Connected to this search"
                                        concepts={concepts}
                                        conceptsLoading={
                                            (data === undefined &&
                                                error === undefined) ||
                                            loading
                                        }
                                        selectedConcept={selectedConcept}
                                        filters={conceptFilters}
                                        selectedFilter={selectedFilter}
                                        mentions={
                                            sentences
                                                ? sentences.map(
                                                      ({ node }) => node
                                                  )
                                                : null
                                        }
                                        mentionContext={{
                                            type: "query",
                                            name: query,
                                        }}
                                        mentionsLoading={
                                            (mentionsData === undefined &&
                                                mentionsError === undefined) ||
                                            mentionsLoading
                                        }
                                        selectedMention={selectedCitation}
                                        onSelectConcept={(concept) =>
                                            setSelectedConcept(
                                                selectedConcept === concept
                                                    ? null
                                                    : concept
                                            )
                                        }
                                        onChangeFilter={(filter) => {
                                            setSelectedConcept(null);
                                            setSelectedFilter(filter);
                                        }}
                                    />
                                )}
                                <BackToTopButton
                                    secondary
                                    visible={scrollToTopVisible}
                                    label="Back to top"
                                    icon={<Ascending />}
                                    onClick={() => {
                                        scrollBox.scrollTo(0, 0);
                                    }}
                                />
                            </Box>
                            {size !== "small" && (
                                <Notes conceptSpaceId={conceptSpaceId} />
                            )}
                        </Box>
                    </Box>
                </PageContent>
            </NoteContext.Provider>
        </Page>
    );
}

export default Answer;
