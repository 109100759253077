import React, { useEffect, useState } from "react";
import {
    Anchor,
    Avatar,
    Box,
    Button,
    Header,
    Heading,
    Image,
    Layer,
    Menu,
    Nav,
    Notification,
    ResponsiveContext,
    Spinner,
    Text,
    ThemeContext,
} from "grommet";
import { Alert, CaretDownFill, Close, Menu as MenuIcon } from "grommet-icons";

import { useHistory, useLocation, useRouteMatch } from "react-router-dom";
import { gql, useQuery } from "@apollo/client";
import { ArcherIcon } from "../icons";

import { useAuth } from "./auth/AuthProvider";

const GET_CONCEPT_SPACE_NAME = gql`
    query getConceptSpaceName($conceptSpacesWhere: ConceptSpaceWhere) {
        conceptSpaces(where: $conceptSpacesWhere) {
            name
            status
            themesAggregate(where: { hidden_NOT: true }) {
                node {
                    created {
                        max
                    }
                }
            }
        }
    }
`;

function ConceptSpaceMenu({ conceptSpaceId }) {
    const history = useHistory();
    const location = useLocation();

    const [themesUpdated, setThemesUpdated] = useState(false);

    const { loading, error, data, previousData, client } = useQuery(
        GET_CONCEPT_SPACE_NAME,
        {
            variables: {
                conceptSpacesWhere: { id: conceptSpaceId },
            },
            pollInterval: 10000,
        }
    );

    const { conceptSpaces: [{ status: previousStatus } = {}] = [{}] } =
        previousData || {};
    const { conceptSpaces: [{ name, status } = {}] = [{}] } = data || {};

    useEffect(() => {
        if (
            !location.pathname.endsWith("/log") &&
            previousStatus &&
            status !== previousStatus &&
            status === "READY"
        ) {
            setThemesUpdated(true);
        }
    }, [status, previousStatus]);

    if (loading) {
        return null;
    }

    if (error || (data && data.conceptSpaces.length === 0)) {
        return (
            <Box direction="row">
                <Alert />
            </Box>
        );
    }

    return (
        <Box direction="row" align="center" margin={{ left: "medium" }}>
            {/* <Text size="small" pad="none">
                :
            </Text> */}
            <Menu
                margin={{ horizontal: "small" }}
                dropProps={{
                    a11yTitle: "Project Menu",
                    align: { top: "bottom", left: "left" },
                    elevation: "xlarge",
                }}
                items={[
                    {
                        label: <Text size="small">Summary</Text>,
                        onClick: () =>
                            history.push(`/${conceptSpaceId}/summary`),
                    },
                    {
                        label: <Text size="small">Concepts</Text>,
                        onClick: () => history.push(`/${conceptSpaceId}`),
                    },
                    // {
                    //     label: <Text size="small">Evidence Search</Text>,
                    //     onClick: () => history.push(`/${conceptSpaceId}/es`),
                    // },
                    {
                        label: <Text size="small">Documents</Text>,
                        onClick: () => history.push(`/${conceptSpaceId}/docs`),
                    },
                    {
                        label: <Text size="small">Chat</Text>,
                        onClick: () => history.push(`/${conceptSpaceId}/chat`),
                    },
                ]}
            >
                <Box
                    direction="row"
                    align="center"
                    gap="small"
                    margin={{ left: "small" }}
                >
                    <Heading level={2}>{name || conceptSpaceId}</Heading>
                    <CaretDownFill />
                </Box>
            </Menu>
            {status === "UPDATING" && !location.pathname.endsWith("/log") && (
                <Box
                    margin={{ left: "medium" }}
                    direction="row"
                    align="center"
                    gap="small"
                    onClick={() => history.push(`/${conceptSpaceId}/log`)}
                    focusIndicator={false}
                >
                    <Heading level={2}>Researching...</Heading>
                    <Spinner size="xsmall" />
                </Box>
            )}
            {themesUpdated && (
                <Notification
                    status="info"
                    message={
                        <Text>
                            A new summary has been generated!{"\n"}
                            <Anchor
                                onClick={async () => {
                                    await client.refetchQueries({
                                        include: [
                                            "getConceptSpaces",
                                            "getConceptSpace",
                                            "getConceptSpaceThemes",
                                            "SearchSuggestions",
                                            "ThemeMap",
                                            "GetAllBookmarks",
                                            "GetRecentBookmarks",
                                            "getConceptSpaceThemes",
                                            "GetThemeHighlights",
                                            "GetQueryHighlights",
                                        ],
                                    });
                                    setThemesUpdated(false);
                                    history.push(`/${conceptSpaceId}/summary`);
                                }}
                            >
                                See the latest summary
                            </Anchor>
                        </Text>
                    }
                    toast={{ autoClose: false }}
                    onClose={() => setThemesUpdated(false)}
                />
            )}
        </Box>
    );
}

function DefaultAvatar({ user }) {
    return (
        <ThemeContext.Extend
            value={{
                avatar: {
                    size: {
                        medium: "40px",
                    },
                    extend: `border-radius: 10px;
                        filter: drop-shadow(0px 8px 10px rgba(41, 70, 152, 0.15));        
                        color: rgba(255, 255, 255, 0.6);
                        background: #04CB8D;
                        background: -webkit-linear-gradient(bottom right, #04CB8D, #6E3F71);
                        background: -moz-linear-gradient(bottom right, #04CB8D, #6E3F71);
                        background: linear-gradient(to top left, #04CB8D, #6E3F71);
                        `,
                },
            }}
        >
            <Avatar elevation="medium">{user.email[0].toUpperCase()}</Avatar>
        </ThemeContext.Extend>
    );
}

function DesktopAppBar() {
    const history = useHistory();
    const { params: { conceptSpaceId } = {} } =
        useRouteMatch({
            path: "/:conceptSpaceId/:page?",
        }) || {};

    const { user, isAdmin, auth } = useAuth();

    return (
        <ThemeContext.Extend
            value={{
                global: {
                    colors: {
                        border: "#E1E4F1",
                    },
                },
                heading: {
                    color: "#353B4E",
                    level: {
                        1: {
                            font: { weight: 600 },
                            medium: {
                                size: "20px",
                            },
                        },
                        // comment this out for largwer title in app bar
                        2: {
                            font: {
                                family: "'Karla', sans-serif",
                                weight: 400,
                            },
                            medium: {
                                size: "14px",
                            },
                        },
                    },
                    extend: "line-height: 24px",
                },
                // text: {
                //     font: {},
                // },
                button: {
                    secondary: { color: "white" },
                },
            }}
        >
            <Header
                background="white"
                border={[
                    {
                        color: "border",
                        side: "bottom",
                    },
                ]}
            >
                <Box
                    fill
                    direction="row"
                    align="center"
                    justify="between"
                    pad={{ vertical: "18px" }}
                    margin={{ horizontal: "20px" }}
                >
                    <Anchor onClick={() => history.push("/")}>
                        <Box direction="row" align="center">
                            <Box pad="8px" margin="none">
                                <Image
                                    src={ArcherIcon}
                                    fit="contain"
                                    width={32}
                                />
                            </Box>
                        </Box>
                    </Anchor>
                    <Box direction="row" fill>
                        {conceptSpaceId &&
                            !["roadmap", "account", "newproject"].includes(
                                conceptSpaceId
                            ) && (
                                <ConceptSpaceMenu
                                    conceptSpaceId={conceptSpaceId}
                                />
                            )}
                    </Box>
                    <Box direction="row" fill justify="end" gap="large">
                        <Box direction="row" gap="medium">
                            <Anchor
                                size="small"
                                color="text"
                                weight="inherit"
                                alignSelf="center"
                                href="https://www.move37.ai/ourstory"
                                target="_blank"
                            >
                                <Text size="small">About</Text>
                            </Anchor>
                            <Anchor
                                size="small"
                                color="text"
                                weight="inherit"
                                alignSelf="center"
                                href="https://same-judo-f78.notion.site/Teamspace-Home-084c04136b934873b6cea96702621473"
                                target="_blank"
                            >
                                <Text size="small">Help</Text>
                            </Anchor>
                            <Anchor
                                size="small"
                                color="text"
                                weight="inherit"
                                alignSelf="center"
                                href="mailto:support@archerapp.ai"
                                target="_blank"
                            >
                                <Text size="small">Contact</Text>
                            </Anchor>
                            <Anchor
                                size="small"
                                color="text"
                                weight="inherit"
                                alignSelf="center"
                                onClick={() => history.push("/roadmap")}
                            >
                                <Text size="small">Roadmap</Text>
                            </Anchor>
                        </Box>
                        {user && (
                            <Menu
                                color="white"
                                items={[
                                    {
                                        label: (
                                            <Text size="small">
                                                {user.email}
                                                {isAdmin && (
                                                    <Box
                                                        margin={{
                                                            top: "xsmall",
                                                        }}
                                                    >
                                                        <Text size="small">
                                                            ADMIN
                                                        </Text>
                                                        <Text size="small">
                                                            v:{" "}
                                                            {process.env
                                                                .REACT_APP_VERSION ||
                                                                "dev"}
                                                        </Text>
                                                    </Box>
                                                )}
                                            </Text>
                                        ),
                                        disabled: true,
                                    },
                                    {
                                        label: (
                                            <Text size="small">
                                                Manage Account
                                            </Text>
                                        ),
                                        onClick: () => {
                                            history.push("/account");
                                        },
                                    },
                                    {
                                        label: (
                                            <Text size="small">Sign out</Text>
                                        ),
                                        onClick: async () => auth.signOut(),
                                    },
                                ]}
                                dropProps={{
                                    font: "'Lato', sans-serif",
                                    a11yTitle: "User Menu",
                                    align: { top: "bottom", right: "right" },
                                    elevation: "xlarge",
                                }}
                            >
                                {user.photoURL ? (
                                    <Avatar
                                        elevation="medium"
                                        src={user.photoURL}
                                    />
                                ) : (
                                    <DefaultAvatar user={user} />
                                )}
                            </Menu>
                        )}
                    </Box>
                </Box>
            </Header>
        </ThemeContext.Extend>
    );
}

function MobileAppBar() {
    const history = useHistory();
    const location = useLocation();
    const { params: { conceptSpaceId } = {} } =
        useRouteMatch({
            path: "/:conceptSpaceId",
        }) || {};
    const { user, isAdmin, auth } = useAuth();
    const [menuVisible, setMenuVisible] = useState(false);

    const { data } = useQuery(GET_CONCEPT_SPACE_NAME, {
        variables: {
            conceptSpacesWhere: { id: conceptSpaceId },
        },
        skip: !conceptSpaceId,
    });

    const { conceptSpaces: [{ name, status } = {}] = [{}] } = data || {};

    useEffect(() => {
        setMenuVisible(false);
    }, [location]);

    return (
        <ThemeContext.Extend
            value={{
                global: {
                    colors: {
                        border: "#E1E4F1",
                    },
                },
                heading: {
                    color: "#353B4E",
                    level: {
                        1: {
                            font: { weight: 600 },
                            medium: {
                                size: "20px",
                            },
                        },
                        // comment this out for larger title in app bar
                        2: {
                            font: {
                                family: "'Karla', sans-serif",
                                weight: 400,
                            },
                            medium: {
                                size: "14px",
                            },
                            small: {
                                size: "12px",
                            },
                        },
                    },
                    extend: "line-height: 24px",
                },
                button: {
                    secondary: { color: "white" },
                },
            }}
        >
            <Header
                background="white"
                border={[
                    {
                        color: "border",
                        side: "bottom",
                    },
                ]}
            >
                <Box
                    fill
                    direction="row"
                    align="center"
                    justify="between"
                    pad={{ vertical: "18px" }}
                    margin={{ horizontal: "20px" }}
                >
                    <Anchor onClick={() => history.push("/")}>
                        <Box direction="row" align="center">
                            <Box
                                // background="black"
                                // round="10px"
                                pad="8px"
                                margin="none"
                            >
                                <Image
                                    src={ArcherIcon}
                                    fit="contain"
                                    width={32}
                                />
                            </Box>
                        </Box>
                    </Anchor>
                    {status === "UPDATING" &&
                        !location.pathname.endsWith("/log") && (
                            <Box
                                flex="grow"
                                margin={{ left: "large" }}
                                direction="row"
                                align="center"
                                gap="small"
                                onClick={() =>
                                    history.push(`/${conceptSpaceId}/log`)
                                }
                                focusIndicator={false}
                            >
                                <Heading level={2} size="xsmall">
                                    Researching...
                                </Heading>
                                <Spinner size="small" />
                            </Box>
                        )}
                    <Box direction="row" justify="end" gap="small" fill>
                        <Button
                            plain
                            icon={<MenuIcon />}
                            onClick={() => setMenuVisible(!menuVisible)}
                        />
                        {menuVisible && (
                            <Layer
                                position="right"
                                full="vertical"
                                modal
                                onClickOutside={() => setMenuVisible(false)}
                                onEsc={() => setMenuVisible(false)}
                            >
                                <Header
                                    justify="end"
                                    margin={{ top: "large", right: "large" }}
                                >
                                    <Button
                                        plain
                                        icon={<Close />}
                                        onClick={() => setMenuVisible(false)}
                                    />
                                </Header>
                                <Box
                                    fill="vertical"
                                    overflow="auto"
                                    pad={{ horizontal: "xlarge" }}
                                    // flex="grow"
                                >
                                    {conceptSpaceId && (
                                        <Box flex="grow">
                                            <Heading level={3} margin="none">
                                                Project
                                            </Heading>
                                            <Heading level={2} margin="none">
                                                {name || conceptSpaceId}
                                            </Heading>

                                            <Nav
                                                border={{ side: "top" }}
                                                align="end"
                                                margin={{ top: "large" }}
                                                pad={{ top: "large" }}
                                            >
                                                <Anchor
                                                    size="large"
                                                    onClick={() =>
                                                        history.push(
                                                            `/${conceptSpaceId}/summary`
                                                        )
                                                    }
                                                >
                                                    Summary
                                                </Anchor>
                                                <Anchor
                                                    size="large"
                                                    onClick={() =>
                                                        history.push(
                                                            `/${conceptSpaceId}/`
                                                        )
                                                    }
                                                >
                                                    Concepts
                                                </Anchor>
                                                <Anchor
                                                    size="large"
                                                    onClick={() =>
                                                        history.push(
                                                            `/${conceptSpaceId}/docs`
                                                        )
                                                    }
                                                >
                                                    Documents
                                                </Anchor>
                                                <Anchor
                                                    size="large"
                                                    onClick={() =>
                                                        history.push(
                                                            `/${conceptSpaceId}/notes`
                                                        )
                                                    }
                                                >
                                                    Notes
                                                </Anchor>
                                                <Anchor
                                                    size="large"
                                                    onClick={() =>
                                                        history.push(
                                                            `/${conceptSpaceId}/chat`
                                                        )
                                                    }
                                                >
                                                    Chat
                                                </Anchor>
                                            </Nav>
                                        </Box>
                                    )}
                                    <Nav
                                        border={{ side: "top" }}
                                        align="end"
                                        margin={{ top: "large" }}
                                        pad={{ top: "large" }}
                                    >
                                        <Anchor
                                            href="https://www.move37.ai/ourstory"
                                            target="_blank"
                                        >
                                            About
                                        </Anchor>
                                        <Anchor
                                            href="https://same-judo-f78.notion.site/Teamspace-Home-084c04136b934873b6cea96702621473"
                                            target="_blank"
                                        >
                                            Help
                                        </Anchor>
                                        <Anchor
                                            href="mailto:support@archerapp.ai"
                                            target="_blank"
                                        >
                                            Contact
                                        </Anchor>
                                        <Anchor
                                            onClick={() =>
                                                history.push("/roadmap")
                                            }
                                        >
                                            Roadmap
                                        </Anchor>
                                    </Nav>
                                    {user && (
                                        <Nav
                                            border={{ side: "top" }}
                                            align="end"
                                            margin={{ top: "large" }}
                                            pad={{ top: "large" }}
                                        >
                                            <Box
                                                pad="small"
                                                direction="row"
                                                align="center"
                                                gap="medium"
                                            >
                                                {user.photoURL ? (
                                                    <Avatar
                                                        elevation="medium"
                                                        src={user.photoURL}
                                                    />
                                                ) : (
                                                    <DefaultAvatar
                                                        user={user}
                                                    />
                                                )}
                                                <Text size="medium">
                                                    {user.email}
                                                    {isAdmin && " (ADMIN)"}
                                                </Text>
                                            </Box>
                                            <Anchor
                                                onClick={async () =>
                                                    history.push("/account")
                                                }
                                            >
                                                Manage Account
                                            </Anchor>
                                            <Anchor
                                                onClick={async () =>
                                                    auth.signOut()
                                                }
                                            >
                                                Sign Out
                                            </Anchor>
                                        </Nav>
                                    )}
                                    {isAdmin && (
                                        <Box
                                            align="end"
                                            pad={{ vertical: "large" }}
                                            color="light-4"
                                        >
                                            <Text color="dark-4">
                                                v:{" "}
                                                {process.env
                                                    .REACT_APP_VERSION || "dev"}
                                            </Text>
                                        </Box>
                                    )}
                                </Box>
                            </Layer>
                        )}
                    </Box>
                </Box>
            </Header>
        </ThemeContext.Extend>
    );
}

function AppBar() {
    const size = React.useContext(ResponsiveContext);

    if (size === "small") {
        return <MobileAppBar />;
    }

    return <DesktopAppBar />;
}

export default AppBar;
