function getPathFromUri(uri) {
    return decodeURIComponent(uri.slice(5).split("/").slice(2).join("/"));
}

function getLabel(target) {
    const { __typename: targetType } = target;
    switch (targetType) {
        case "QueryResult":
            return target.query;
        case "Sentence":
            return target.text;
        case "Document":
            return target.title || getPathFromUri(target.uri);
        default:
            return target.name;
    }
}

function getTypeLabel(target) {
    const { __typename: targetType } = target;
    switch (targetType) {
        case "QueryResult":
            return "Query";
        case "Sentence":
            return "Excerpt";
        default:
            return targetType;
    }
}

function getSentenceLocation(target) {
    const {
        text,
        documentConnection: {
            edges: [
                {
                    passage,
                    sentence,
                    node: { uri },
                },
            ],
        },
    } = target;
    return {
        path: `/docs/${getPathFromUri(uri)}#p=${passage}&s=${sentence}`,
        state: {
            context: {
                type: "excerpt",
                name: text,
            },
            passages: [{ passage }],
        },
    };
}

function getLocation(target) {
    const { __typename: targetType } = target;
    switch (targetType) {
        case "Theme":
            return { path: `/themes/${encodeURIComponent(target.name)}` };
        case "Concept":
            return { path: `/concepts/${encodeURIComponent(target.id)}` };
        case "QueryResult":
            return { path: `/answers?q=${target.query}` };
        case "Sentence":
            return getSentenceLocation(target);
        case "Document":
            return { path: `/docs/${getPathFromUri(target.uri)}` };
        default:
            return { path: "" };
    }
}

export { getLocation, getLabel, getTypeLabel };
