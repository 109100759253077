/* eslint-disable no-restricted-syntax */
import { Box } from "grommet";
import React, { useEffect, useState } from "react";

// eslint-disable-next-line no-underscore-dangle
const _urls = {};

function GoogleSearch({ onResults = () => {}, autoAddCount = 10 }) {
    const [selectedURLs, setSelectedURLs] = useState([]);

    useEffect(() => {
        onResults(selectedURLs);
    }, [selectedURLs]);

    useEffect(() => {
        (function () {
            const cx = "83c5243cff1c843f1";
            const gcse = document.createElement("script");
            gcse.type = "text/javascript";
            gcse.async = true;
            gcse.src = `https://cse.google.com/cse.js?cx=${cx}`;
            const s = document.getElementsByTagName("script")[0];
            s.parentNode.insertBefore(gcse, s);

            // eslint-disable-next-line no-underscore-dangle
            if (!window.__gcse) window.__gcse = {};

            // eslint-disable-next-line no-underscore-dangle
            window.__gcse.searchCallbacks = {
                web: {
                    starting: () =>
                        Object.getOwnPropertyNames(_urls).forEach((url) => {
                            delete _urls[url];
                            setSelectedURLs(
                                Object.entries(_urls)
                                    .filter((entry) => entry[1])
                                    .map((entry) => entry[0])
                            );
                        }),
                    ready: (name, q, promos, results) => {
                        const urls = results.map(({ url }) =>
                            decodeURIComponent(
                                url
                                    .split("&")
                                    .find((p) => p.startsWith("q="))
                                    .slice(2)
                            )
                        );
                        urls.forEach((url) => {
                            if (
                                Object.getOwnPropertyNames(_urls).length <
                                    autoAddCount &&
                                !(url in _urls)
                            ) {
                                _urls[url] = true;
                            }
                        });
                        setSelectedURLs(
                            Object.entries(_urls)
                                .filter((entry) => entry[1])
                                .map((entry) => entry[0])
                        );
                    },
                    rendered: (gname, query, promoElts, resultElts) => {
                        for (const div of resultElts) {
                            const titleAnchor = div.querySelector("a");
                            const resultURL = titleAnchor.href;

                            const titleDiv = document.createElement("span");
                            titleDiv.style.display = "flex";
                            titleDiv.style["align-items"] = "center";

                            const checkBox = document.createElement("input");
                            checkBox.type = "checkbox";
                            checkBox.name = "save";
                            checkBox.style.width = "18px";
                            checkBox.style.height = "18px";
                            checkBox.style["margin-left"] = "1em";

                            checkBox.checked = _urls[resultURL];
                            // eslint-disable-next-line no-loop-func
                            checkBox.onclick = ({ target: { checked } }) => {
                                _urls[resultURL] = checked;
                                setSelectedURLs(
                                    Object.entries(_urls)
                                        .filter((entry) => entry[1])
                                        .map((entry) => entry[0])
                                );
                            };
                            titleDiv.appendChild(titleAnchor);
                            titleDiv.appendChild(checkBox);
                            // div.appendChild(checkDiv);
                            // div.insertBefore(checkDiv, titleAnchor.next);
                            div.insertAdjacentElement("afterbegin", titleDiv);
                        }
                    },
                },
            };
        })();
    }, []);

    return (
        <Box
            flex="shrink"
            round="10px"
            style={{
                filter: "drop-shadow(0px 8px 10px rgba(41, 70, 152, 0.05))",
            }}
            // pad="xsmall"
            // background="white"
        >
            {/* <Text>
                <ul>
                    {selectedURLs.map((url) => (
                        <li>{url}</li>
                    ))}
                </ul>
            </Text> */}
            <div className="gcse-search" />
        </Box>
    );
}

export default GoogleSearch;
