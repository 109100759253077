/* eslint-disable no-unused-vars */
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";

import { useHistory, useLocation, useParams } from "react-router-dom";

import {
    Accordion,
    AccordionPanel,
    Anchor,
    Box,
    Button,
    Collapsible,
    Drop,
    // Heading,
    Keyboard,
    Layer,
    List,
    Notification,
    Page,
    PageContent,
    ResponsiveContext,
    Sidebar,
    Spinner,
    Text,
} from "grommet";

import { FiSend as Send } from "react-icons/fi";
import { Add, Close, FormDown, FormUp, History } from "grommet-icons";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import minMax from "dayjs/plugin/minMax";

import { gql, useQuery } from "@apollo/client";
import { useAuth } from "./components/auth/AuthProvider";
import ExpandingTextArea from "./components/ExpandingTextArea";
import DeleteButton from "./components/DeleteButton";

dayjs.extend(duration);
dayjs.extend(relativeTime);
dayjs.extend(minMax);

const chatUrl =
    process.env.REACT_APP_CHAT_URL ||
    (process.env.NODE_ENV === "development"
        ? "http://localhost:8083"
        : // "https://archer-chat-ptm2rbpu7a-wn.a.run.app"
          "/agent/chat");

const META_BLOCK_START = "```meta";
const META_BLOCK_END = "```";

const GET_CONCEPT_SPACE_DESCRIPTION = gql`
    query getConceptSpaceName($conceptSpacesWhere: ConceptSpaceWhere) {
        conceptSpaces(where: $conceptSpacesWhere) {
            name
            description
        }
    }
`;

const GET_DOCUMENT_TITLE = gql`
    query Documents($where: DocumentWhere) {
        documents(where: $where) {
            title
            uri
        }
    }
`;

function DocumentAnchor({ conceptSpaceId, conversationName, path, passage }) {
    const history = useHistory();
    const { data, loading, error } = useQuery(GET_DOCUMENT_TITLE, {
        variables: {
            where: {
                conceptSpace: {
                    id: conceptSpaceId,
                },
                uri_ENDS_WITH: path,
            },
        },
    });

    if (error) {
        return null;
    }

    if (loading) {
        return <Spinner size="xsmall" />;
    }

    const {
        documents: [{ title } = {}],
    } = data || { documents: [{}] };

    return (
        <Anchor
            plain
            color="light-4"
            size="xsmall"
            onClick={() => {
                history.push(
                    {
                        pathname: `/${conceptSpaceId}/docs${path}`,
                        hash: `p=${passage}`,
                    },
                    {
                        context: {
                            type: "Source cited in chat",
                            name: conversationName,
                        },
                        passages: [{ passage }],
                    }
                    // getDocumentContext(document, passages, mentionContext)
                );
            }}
        >
            {title
                ? `${title} / #${passage + 1}`
                : `${decodeURIComponent(path)}#${passage + 1}`}
        </Anchor>
    );
}

function MessageWithSources({
    conceptSpaceId,
    conversationName,
    message,
    sources,
}) {
    const [showSources, setShowSources] = useState(false);
    const [selectedSource, setSelectedSource] = useState();
    const scrollRefs = useRef({});

    useEffect(() => {
        if (!showSources) {
            setSelectedSource(undefined);
            return;
        }
        const scrollRef = scrollRefs.current[selectedSource];
        if (scrollRef && scrollRef.current) {
            scrollRef.current.scrollIntoView({
                behavior: "smooth",
                block: "center",
                inline: "center",
            });
        }
    }, [selectedSource, showSources]);

    const renderedMessage = useMemo(() => {
        const parts = message.split(/(\[\d+\])/);

        const renderText = parts.map((part) => {
            const match = part.match(/\[(\d+)\]/);
            if (match && sources) {
                const citedSource = parseInt(match[1], 10);
                if (
                    sources.some(
                        ({ metadata: { source } }) => source === citedSource
                    )
                ) {
                    return (
                        <Anchor
                            plain
                            size="small"
                            onClick={() => {
                                setShowSources(true);
                                setSelectedSource(citedSource);
                            }}
                        >
                            {match[0]}
                        </Anchor>
                    );
                }

                return null;
            }
            return part;
        });
        return renderText;
    }, [message, sources]);

    return (
        <>
            <Text style={{ whiteSpace: "pre-line", lineHeight: "1.3em" }}>
                {renderedMessage}
            </Text>
            {sources && sources.length > 0 && (
                <Box
                    margin={{
                        vertical: "medium",
                    }}
                >
                    <Accordion
                        animate={false}
                        activeIndex={showSources ? 0 : null}
                        onActive={([newActiveIndex]) => {
                            setShowSources(
                                newActiveIndex !== undefined && true
                            );
                        }}
                    >
                        <AccordionPanel
                            header={
                                <Box direction="row">
                                    <Box
                                        background="light-4"
                                        pad="xsmall"
                                        direction="row"
                                    >
                                        <Button
                                            plain
                                            reverse
                                            label={
                                                <Text size="xsmall">
                                                    Sources
                                                </Text>
                                            }
                                            icon={
                                                showSources ? (
                                                    <FormUp size="small" />
                                                ) : (
                                                    <FormDown size="small" />
                                                )
                                            }
                                        />
                                    </Box>
                                </Box>
                            }
                        >
                            <Box color="white" background="dark-2" pad="medium">
                                {selectedSource && (
                                    <Box margin={{ left: "medium" }}>
                                        <Button
                                            plain
                                            label={
                                                <Text
                                                    size="xsmall"
                                                    color="light-4"
                                                    onClick={() =>
                                                        setSelectedSource(
                                                            undefined
                                                        )
                                                    }
                                                >
                                                    Show all
                                                </Text>
                                            }
                                        />
                                    </Box>
                                )}
                                {sources
                                    .filter(
                                        ({ metadata: { source } }) =>
                                            !selectedSource ||
                                            source === selectedSource
                                    )
                                    .map(
                                        ({
                                            pageContent,
                                            metadata: { source, path, passage },
                                        }) => {
                                            const ref =
                                                scrollRefs.current[source] ||
                                                (scrollRefs.current[source] =
                                                    React.createRef());
                                            return (
                                                <Box
                                                    ref={ref}
                                                    key={`${path}#${passage}`}
                                                    direction="row"
                                                    margin={{
                                                        vertical: "small",
                                                    }}
                                                    gap="small"
                                                >
                                                    <Text
                                                        size="xsmall"
                                                        color="light-4"
                                                    >
                                                        {source}.{" "}
                                                    </Text>
                                                    <Box>
                                                        <Text color="white">
                                                            {pageContent}
                                                        </Text>
                                                        <Box
                                                            margin={{
                                                                top: "xsmall",
                                                            }}
                                                        >
                                                            <DocumentAnchor
                                                                conceptSpaceId={
                                                                    conceptSpaceId
                                                                }
                                                                conversationName={
                                                                    conversationName
                                                                }
                                                                path={path}
                                                                passage={
                                                                    passage
                                                                }
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Box>
                                            );
                                        }
                                    )}
                            </Box>
                        </AccordionPanel>
                    </Accordion>
                </Box>
            )}
        </>
    );
}

function SidebarContainer({ open, onClose, children }) {
    const [sidebarOpen, setSidebarOpen] = useState(open);
    const size = React.useContext(ResponsiveContext);

    useEffect(() => {
        setSidebarOpen(open);
    }, [open]);

    if (size === "small") {
        return (
            sidebarOpen && (
                <Layer
                    full="vertical"
                    position="left"
                    responsive={false}
                    background="background"
                    onClickOutside={onClose}
                >
                    <Box>
                        <Box direction="row" justify="end" pad="medium">
                            <Button
                                plain
                                icon={<Close size="medium" />}
                                onClick={onClose}
                            />
                        </Box>
                        {children}
                    </Box>
                </Layer>
            )
        );
    }

    return <Box border={{ size: "0.5px", side: "right" }}>{children}</Box>;
}

function InputContainer({ targetRef, children }) {
    const size = React.useContext(ResponsiveContext);

    if (!targetRef.current) {
        return null;
    }

    if (size === "small") {
        return (
            <Layer
                modal={false}
                responsive={false}
                full="horizontal"
                position="bottom"
                // target={targetRef.current}
                style={{ boxShadow: "0 0 6px 6px #FAF6F4" }}
            >
                {children}
            </Layer>
        );
    }
    return (
        <Box
            style={{
                position: "absolute",
                bottom: 0,
                width: "100%",
                backgroundImage:
                    "linear-gradient(to bottom, transparent, #FAF6F4 30%, #FAF6F4)",
            }}
            align="center"
            // background="background"
        >
            {children}
        </Box>
        // <Drop
        //     plain
        //     background={null}
        //     target={targetRef.current}
        //     align={{ bottom: "bottom" }}
        //     style={{ marginLeft: "6px", boxShadow: "0 0 6px 6px #FAF6F4" }}
        // >
        //     {children}
        // </Drop>
    );
}

function Chat() {
    const history = useHistory();
    const { conceptSpaceId, conversationId: savedConversationId } = useParams();
    const [sidebarOpen, setSidebarOpen] = useState();
    const [userMessage, setUserMessage] = useState();
    const [currentReply, setCurrentReply] = useState();
    const [conversationId, setConversationId] = useState(savedConversationId);
    const [conversation, setConversation] = useState([]);
    const [conversations, setConversations] = useState();
    const [error, setError] = useState();

    const { user } = useAuth();

    const mainRef = useRef();
    const endRef = useRef();
    const stopConversationRef = useRef(false);

    const { data } = useQuery(GET_CONCEPT_SPACE_DESCRIPTION, {
        variables: { conceptSpacesWhere: { id: conceptSpaceId } },
    });

    const { conceptSpaces: [{ name } = {}] = [] } = data || {};

    const getConversations = useCallback(async () => {
        const response = await fetch(
            `${chatUrl}/chats?${new URLSearchParams({
                conceptSpaceId,
            }).toString()}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${await user.getIdToken()}`,
                },
            }
        );
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const unsorted = await response.json();
        const sorted = unsorted.sort(({ timestamp: a }, { timestamp: b }) =>
            b.localeCompare(a)
        );
        setConversations(sorted);
        return sorted;
    });

    const getConversation = useCallback(async (id) => {
        const response = await fetch(
            `${chatUrl}/chat?${new URLSearchParams({
                conversationId: id,
            }).toString()}`,
            {
                method: "GET",
                headers: {
                    Authorization: `Bearer ${await user.getIdToken()}`,
                },
            }
        );
        if (!response.ok) {
            throw Error(response.statusText);
        }
        const chatHistory = await response.json();
        setConversation(
            chatHistory.map((turn) => {
                const { message } = turn;
                if (message.includes(META_BLOCK_START)) {
                    const [textContent, rest] = message.split(META_BLOCK_START);
                    const [metadata] = rest.split(META_BLOCK_END);
                    return {
                        ...turn,
                        message: textContent,
                        metadata: JSON.parse(metadata),
                    };
                }
                return turn;
            })
        );
    });

    const deleteConversation = useCallback(async (deleteId) => {
        setConversations(conversations.filter(({ id }) => id !== deleteId));
        const response = await fetch(
            `${chatUrl}/chat?${new URLSearchParams({
                conversationId: deleteId,
            }).toString()}`,
            {
                method: "DELETE",
                headers: {
                    Authorization: `Bearer ${await user.getIdToken()}`,
                },
            }
        );
        if (!response.ok) {
            throw Error(response.statusText);
        }
    });

    const sendMessage = useCallback(async (input) => {
        if (!input || input.length === 0) {
            return;
        }

        const updatedConversation = [
            ...conversation,
            { role: "human", message: input, timestamp: dayjs() },
        ];
        setConversation(updatedConversation);
        setUserMessage("");
        setCurrentReply(<Spinner size="xsmall" />);

        const sessionToken = await user.getIdToken();

        try {
            stopConversationRef.current = false;
            const controller = new AbortController();
            const response = await fetch(`${chatUrl}/chat`, {
                method: "POST",
                headers: {
                    Authorization: `Bearer ${sessionToken}`,
                    "Content-Type": "application/json",
                },
                body: JSON.stringify({
                    input,
                    conversation: conversationId,
                    conceptSpace: conceptSpaceId,
                    context: name,
                }),
                signal: controller.signal,
            });
            if (!response.ok) {
                throw Error(response.statusText);
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let done = false;
            let metadata = null;

            const reply = { role: "ai", message: "", timestamp: dayjs() };

            while (!done) {
                // eslint-disable-next-line no-await-in-loop
                const { value, done: doneReading } = await reader.read();
                if (stopConversationRef.current === true) {
                    controller.abort();
                    done = true;
                    break;
                }

                done = doneReading;
                const chunkValue = decoder.decode(value);

                if (chunkValue.includes(META_BLOCK_START)) {
                    [metadata] = chunkValue
                        .split(META_BLOCK_START)
                        .pop()
                        .split(META_BLOCK_END);
                } else if (metadata) {
                    metadata += chunkValue.split(META_BLOCK_END)[0];
                } else {
                    reply.message += chunkValue;
                    setCurrentReply(reply.message);
                }
            }
            if (stopConversationRef.current !== true) {
                if (metadata) {
                    reply.metadata = JSON.parse(metadata);
                }
                setConversation([...updatedConversation, reply]);
                setCurrentReply(null);

                const newConversation = (await getConversations())[0];
                setConversationId(newConversation.id);
            }
        } catch (e) {
            setError(e);
            setCurrentReply(null);
        }
    });

    useEffect(() => {
        getConversations();
    }, []);

    useEffect(() => {
        if (conversationId) {
            getConversation(conversationId);
            history.replace(`/${conceptSpaceId}/chat/${conversationId}`);
        } else {
            setConversation([]);
            if (conversationId !== undefined) {
                history.replace(`/${conceptSpaceId}/chat`);
            }
        }

        setCurrentReply(null);
        setError(undefined);
    }, [conversationId]);

    const size = React.useContext(ResponsiveContext);
    // useEffect(() => {
    //     setSidebarOpen(size !== "small");
    // }, [size]);

    useEffect(() => {
        if (endRef.current) {
            endRef.current
                .scrollIntoView
                // { behavior: "smooth" }
                ();
        }
    }, [currentReply, conversation]);

    return (
        <Box direction="row" fill>
            <SidebarContainer
                open={sidebarOpen}
                onClose={() => setSidebarOpen(false)}
            >
                <Box
                    fill="horizontal"
                    width={{ max: "large" }}
                    pad="medium"
                    margin={{ right: "large" }}
                    overflow={{ vertical: "auto" }}
                >
                    <Box direction="row" justify="center" flex="grow">
                        <Button
                            secondary
                            icon={<Add size="small" />}
                            label={<Text size="small">New thread</Text>}
                            onClick={() => {
                                stopConversationRef.current = true;
                                setConversationId(null);
                                setConversation([]);
                                setCurrentReply(null);
                                setSidebarOpen(false);
                            }}
                        />
                    </Box>
                    {conversations === undefined ? (
                        <Box pad="large" justify="center" align="center">
                            <Spinner />
                        </Box>
                    ) : (
                        <List
                            data={conversations}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            primaryKey={({ id, timestamp, title }) => (
                                <Box
                                    onClick={() => {
                                        stopConversationRef.current = true;
                                        setConversationId(id);
                                        setSidebarOpen(false);
                                    }}
                                    focusIndicator={false}
                                >
                                    <Text size="small">
                                        {title || dayjs(timestamp).fromNow()}
                                    </Text>
                                </Box>
                            )}
                            // eslint-disable-next-line react/no-unstable-nested-components
                            action={({ id }) => (
                                <DeleteButton
                                    plain
                                    size="small"
                                    onDelete={() => {
                                        deleteConversation(id);
                                        if (conversationId === id) {
                                            stopConversationRef.current = true;
                                            setConversationId(null);
                                        }
                                    }}
                                />
                            )}
                            margin={{ vertical: "medium" }}
                        />
                    )}
                </Box>
            </SidebarContainer>
            <Box
                fill
                style={{ position: "relative" }}
                pad={{ bottom: size === "small" ? "xlarge" : "large" }}
                ref={mainRef}
            >
                {(!conversation || conversation.length === 0) && (
                    <Box justify="center" align="center" flex="grow">
                        <Box alignSelf="center" margin="medium">
                            <Text
                                textAlign="center"
                                size="small"
                                margin={{ horizontal: "medium" }}
                            >
                                Ask a question about the project to start a
                                conversation.
                            </Text>
                        </Box>
                    </Box>
                )}
                <Box
                    // fill
                    overflow={{ vertical: "auto" }}
                    pad={{ bottom: "xlarge" }}
                >
                    {conversation.map(({ role, message, metadata }, i) => (
                        <Box
                            pad={{
                                top: i === 0 ? "xlarge" : "medium",
                                bottom: "medium",
                                horizontal: "medium",
                            }}
                            key={`${role}: ${message}`}
                            background={
                                role === "ai"
                                    ? "backgroundDarker"
                                    : "backgroundLighter"
                            }
                            align="center"
                            flex="grow"
                        >
                            <Box
                                pad={{ horizontal: "xlarge" }}
                                width={{ max: "xxlarge" }}
                                align="start"
                                fill="horizontal"
                            >
                                {role === "ai" ? (
                                    <MessageWithSources
                                        conceptSpaceId={conceptSpaceId}
                                        conversationName={
                                            conversations?.find(
                                                ({ id }) =>
                                                    id === conversationId
                                            )?.title
                                        }
                                        message={message}
                                        sources={metadata?.sources}
                                    />
                                ) : (
                                    <Text
                                        style={{
                                            whiteSpace: "pre-line",
                                            lineHeight: "1.3em",
                                        }}
                                    >
                                        {message}
                                    </Text>
                                )}
                            </Box>
                        </Box>
                    ))}

                    {currentReply && (
                        <Box
                            pad={{
                                vertical: "medium",
                                horizontal: "medium",
                            }}
                            key="current"
                            background="backgroundDarker"
                            align="center"
                            flex="grow"
                        >
                            <Box
                                pad={{ horizontal: "xlarge" }}
                                width={{ max: "xxlarge" }}
                                align="start"
                                fill
                            >
                                <Text
                                    style={{
                                        whiteSpace: "pre-line",
                                        lineHeight: "1.3em",
                                    }}
                                >
                                    {currentReply}
                                </Text>
                            </Box>
                        </Box>
                    )}
                    {error && (
                        <Box
                            width={{ max: "medium" }}
                            margin={{
                                top: "small",
                                right: "large",
                                bottom: "xlarge",
                            }}
                            direction="row"
                            alignSelf="end"
                        >
                            <Notification
                                status="critical"
                                title="Failed to get a response"
                                message=""
                                actions={[
                                    {
                                        onClick: () => {
                                            setError(undefined);
                                            sendMessage(
                                                conversation.pop().message
                                            );
                                        },
                                        label: "Retry",
                                    },
                                ]}
                            />
                        </Box>
                    )}
                    <div ref={endRef} />
                </Box>
                <InputContainer targetRef={mainRef}>
                    <Box
                        fill
                        direction="row"
                        background="background"
                        pad={{
                            horizontal: size === "small" ? "medium" : "xlarge",
                            top: "xsmall",
                            bottom: "small",
                        }}
                        justify="center"
                    >
                        {size === "small" && (
                            <Box
                                margin={{ top: "10px", right: "medium" }}
                                pad="small"
                            >
                                <Button
                                    size="medium"
                                    plain
                                    icon={<History size="18px" />}
                                    onClick={() => setSidebarOpen(true)}
                                />
                            </Box>
                        )}
                        <Box
                            fill
                            direction="row"
                            background="white"
                            pad="small"
                            width={{ max: "xlarge" }}
                        >
                            <Keyboard
                                onEnter={(e) => {
                                    if (!currentReply && !e.shiftKey) {
                                        sendMessage(userMessage);
                                        e.preventDefault();
                                    }
                                }}
                            >
                                <ExpandingTextArea
                                    plain
                                    focusIndicator={false}
                                    resize={false}
                                    style={{
                                        background: "white",
                                        maxHeight: "200px",
                                    }}
                                    placeholder="Type a message..."
                                    value={userMessage}
                                    onChange={({ target: { value } }) =>
                                        setUserMessage(value)
                                    }
                                />
                            </Keyboard>
                            <Box
                                pad="xsmall"
                                margin={{ top: size === "small" && "small" }}
                            >
                                <Button
                                    icon={<Send color="grey" />}
                                    plain
                                    onClick={() => sendMessage(userMessage)}
                                />
                            </Box>
                        </Box>
                    </Box>
                    <Box
                        background="background"
                        align="center"
                        pad={{
                            horizontal: size === "large" ? "none" : "medium",
                            top: "small",
                            bottom: size === "large" ? "medium" : "small",
                        }}
                    >
                        <Text size="xsmall">
                            Archer may produce inaccurate information and has
                            other{" "}
                            <Anchor
                                plain
                                href="https://same-judo-f78.notion.site/Archer-Getting-Started-Guide-084c04136b934873b6cea96702621473#f3a7f1aad2694aa395321aacf1adc4ed"
                                target="_blank"
                            >
                                known limitations
                            </Anchor>
                            .
                        </Text>
                    </Box>
                </InputContainer>
            </Box>
        </Box>
    );
}

export default Chat;
