import { gql } from "@apollo/client";

const GET_USAGE = gql`
    query getUsage($where: ConceptSpaceDocumentWhere) {
        conceptSpacesAggregate(where: { status_NOT_IN: [DELETING, DELETED] }) {
            count
        }
        notTruncated: documentsAggregate(
            where: {
                parsedWordCount: null
                conceptSpaceConnection: { edge: $where }
            }
        ) {
            count
            wordCount {
                sum
            }
        }
        truncated: documentsAggregate(
            where: {
                parsedWordCount_NOT: null
                conceptSpaceConnection: { edge: $where }
            }
        ) {
            count
            parsedWordCount {
                sum
            }
        }
    }
`;

export default GET_USAGE;
