import React from "react";

import { Box, SkipLink, ThemeContext } from "grommet";

function SkipNav({ items }) {
    return (
        <ThemeContext.Extend
            value={{
                anchor: {
                    fontWeight: 600,
                    extend: ({ theme }) => `
                                        font-family: 'Lato', sans-serif;
                                        font-size: 16px;
                                        line-height: 19px;
                                        font-weight: 600;

                                        :hover {
                                            color: ${theme.global.colors.active};
                                            text-decoration-thickness: 2px;
                                            text-underline-offset: 19px;
                                        }
                                        :focus {
                                            outline: none !important;
                                            color: ${theme.global.colors.active};
                                            text-decoration: underline;
                                            text-decoration-thickness: 2px;
                                            text-underline-offset: 19px;
                                        }
                                    `,
                },
            }}
        >
            <Box
                border={{ side: "bottom" }}
                direction="row"
                gap="large"
                pad={{ bottom: "18px" }}
                margin={{ top: "large", bottom: "medium" }}
            >
                {items.map(({ id, label }) => (
                    <SkipLink id={id} label={label} />
                ))}
            </Box>
        </ThemeContext.Extend>
    );
}

export default SkipNav;
