import React, { useEffect, useState } from "react";
// import PropTypes from "prop-types";

import { useHistory, useParams } from "react-router-dom";

import {
    Anchor,
    Box,
    Card,
    CardBody,
    CardFooter,
    CardHeader,
    Grid,
    Heading,
    Page,
    PageContent,
    ResponsiveContext,
    Spinner,
    Tab,
    Tabs,
    Text,
    ThemeContext,
} from "grommet";
import { Hide } from "grommet-icons";

import { gql, useQuery } from "@apollo/client";

import dayjs from "dayjs";
import duration from "dayjs/plugin/duration";
import relativeTime from "dayjs/plugin/relativeTime";
import SearchBar from "./components/SearchBar";
import ErrorBox from "./components/ErrorBox";
import Pill from "./components/Pill";
import Notes from "./components/Notes";
import NotFound from "./components/NotFound";
import { useAuth } from "./components/auth/AuthProvider";

dayjs.extend(duration);
dayjs.extend(relativeTime);

// where: { OR: [{ hidden: null }, { hidden_NOT: true }] }

const GET_CONCEPT_SPACE = gql`
    query getConceptSpace(
        $conceptSpacesWhere: ConceptSpaceWhere
        $themesOptions: ThemeOptions
    ) {
        conceptSpaces(where: $conceptSpacesWhere) {
            name
            group
            updated
            wordCount
            documentsConnection {
                totalCount
            }
            themes(options: $themesOptions) {
                name
                size
                importance
                hidden
                concepts(
                    where: {
                        entityType_NOT_IN: [
                            "DATE"
                            "TIME"
                            "MONEY"
                            "PERCENT"
                            "QUANTITY"
                        ]
                    }
                    options: { limit: 6 }
                ) {
                    id
                    name
                    entityType
                    source
                }
            }
            orgConcepts: concepts(where: { entityType_IN: ["ORG"] }) {
                id
                name
                source
            }
            personConcepts: concepts(
                where: { entityType_IN: ["PER", "PERSON"] }
            ) {
                id
                name
                source
            }
            locConcepts: concepts(where: { entityType_IN: ["LOC", "GPE"] }) {
                id
                name
                source
            }
            miscConcepts: concepts(
                where: {
                    entityType_NOT_IN: [
                        "ORG"
                        "PER"
                        "PERSON"
                        "LOC"
                        "GPE"
                        "DATE"
                        "MONEY"
                        "QUANTITY"
                        "PERCENT"
                    ]
                }
            ) {
                id
                name
                source
                entityType
            }
        }
    }
`;

function ConceptSpace() {
    const history = useHistory();
    const { conceptSpaceId } = useParams();
    const size = React.useContext(ResponsiveContext);

    const { isAdmin } = useAuth();

    const [query, setQuery] = useState();

    const { loading, error, data } = useQuery(GET_CONCEPT_SPACE, {
        variables: {
            conceptSpacesWhere: { id: conceptSpaceId },
            themesOptions: {
                // limit: 20,
                sort: [
                    {
                        size: "DESC",
                    },
                ],
            },
        },
    });

    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (loading)
        return (
            <PageContent margin={{ top: "large", bottom: "medium" }}>
                <Spinner alignSelf="center" />
            </PageContent>
        );
    if (error)
        return (
            <PageContent margin={{ top: "large", bottom: "medium" }}>
                <ErrorBox error={error} />
            </PageContent>
        );

    if (data && data.conceptSpaces.length === 0) return <NotFound />;

    const {
        conceptSpaces: [
            {
                documentsConnection: { totalCount: docCount },
                themes,
                personConcepts,
                orgConcepts,
                locConcepts,
                miscConcepts,
            },
        ],
    } = data;

    return (
        <Page kind="wide" flex="grow">
            <PageContent margin={{ top: "large", bottom: "medium" }}>
                <SearchBar
                    text={query}
                    onChange={(value) => setQuery(value)}
                    onSearch={(value) => {
                        if (value instanceof Object) {
                            history.push(
                                `/${conceptSpaceId}/${
                                    value.type
                                }s/${encodeURIComponent(
                                    value.id || value.name
                                )}`
                            );
                        } else {
                            history.push({
                                pathname: `/${conceptSpaceId}/answers`,
                                search: `q=${value}`,
                            });
                        }
                    }}
                />
            </PageContent>
            <PageContent margin={{ top: "none", bottom: "large" }}>
                <Heading
                    level={2}
                    size={size}
                    margin={{ bottom: "xsmall" }}
                    pad="none"
                    // style={{ fontFamily: "Karla" }}
                >
                    Explore concepts
                </Heading>
                <Text size="xsmall">
                    Explore and save concepts and mentions to your notes
                </Text>
            </PageContent>
            <PageContent margin={{ top: "small", bottom: "xlarge" }}>
                <Tabs justify="start" fill>
                    <Tab title="Concepts by theme">
                        <Box
                            margin={{ top: "medium", bottom: "large" }}
                            pad={{ horizontal: "xsmall" }}
                        >
                            {themes.length === 0 && (
                                <Text size="xsmall">
                                    {docCount === 0 ? (
                                        <>
                                            <Anchor
                                                onClick={() =>
                                                    history.push(
                                                        `/${conceptSpaceId}/docs`
                                                    )
                                                }
                                            >
                                                Add some documents
                                            </Anchor>{" "}
                                            to start exploring themes
                                        </>
                                    ) : (
                                        "There are no themes yet"
                                    )}
                                </Text>
                            )}
                            {themes.length > 0 && (
                                <Text size="xsmall">
                                    See concepts sorted by theme
                                </Text>
                            )}
                        </Box>
                        <Box direction="row" justify="between" gap="large">
                            <Grid columns="360px" gap="medium" fill>
                                {themes
                                    .filter(({ hidden }) => isAdmin || !hidden)
                                    .map(
                                        ({
                                            name: themeName,
                                            concepts,
                                            hidden,
                                        }) => (
                                            <ThemeContext.Extend
                                                key={themeName}
                                                // Safari rendering workaround https://stackoverflow.com/questions/38762661/css-drop-shadow-not-working-properly-in-safari/75027316#75027316
                                                value={{
                                                    card: {
                                                        container: {
                                                            extend: ({
                                                                theme,
                                                            }) => `
                                                    backdrop-filter: blur(0); 
                                                    filter: drop-shadow(0px 10px 5px rgba(41, 70, 152, 0.05));
                                                    header {
                                                        color: ${theme.global.colors.primaryLabel};
                                                    }

                                                    :hover header {
                                                        color: ${theme.global.colors.active};
                                                    }
                                                `,
                                                        },
                                                    },
                                                }}
                                            >
                                                <Card
                                                    onClick={() =>
                                                        history.push(
                                                            `/${conceptSpaceId}/themes/${encodeURIComponent(
                                                                themeName
                                                            )}`
                                                        )
                                                    }
                                                    background={
                                                        hidden
                                                            ? "light-2"
                                                            : "white"
                                                    }
                                                >
                                                    <CardHeader>
                                                        <Heading
                                                            level={4}
                                                            size="large"
                                                            margin="none"
                                                        >
                                                            {themeName}
                                                        </Heading>
                                                        {hidden && <Hide />}
                                                    </CardHeader>
                                                    <CardBody margin="xsmall">
                                                        <Box
                                                            direction="row"
                                                            wrap
                                                        >
                                                            {concepts.map(
                                                                ({
                                                                    id: conceptId,
                                                                    name: conceptName,
                                                                    source,
                                                                }) => (
                                                                    <Pill
                                                                        key={`${themeName}/${conceptId}`}
                                                                        label={
                                                                            conceptId &&
                                                                            source ===
                                                                                "wikipedia"
                                                                                ? conceptId
                                                                                : conceptName
                                                                        }
                                                                        onClick={(
                                                                            e
                                                                        ) => {
                                                                            history.push(
                                                                                `/${conceptSpaceId}/concepts/${encodeURIComponent(
                                                                                    conceptId
                                                                                )}`
                                                                            );
                                                                            e.stopPropagation();
                                                                        }}
                                                                    />
                                                                )
                                                            )}
                                                        </Box>
                                                    </CardBody>
                                                </Card>
                                            </ThemeContext.Extend>
                                        )
                                    )}
                            </Grid>
                            {size !== "small" && (
                                <Notes conceptSpaceId={conceptSpaceId} />
                            )}
                        </Box>
                    </Tab>
                    <Tab title="Top concepts">
                        <Box
                            pad={{ horizontal: "small" }}
                            margin={{ top: "medium", bottom: "large" }}
                        >
                            <Text size="xsmall">
                                {(
                                    personConcepts +
                                    orgConcepts +
                                    locConcepts +
                                    miscConcepts
                                ).length === 0
                                    ? "There are no concepts yet"
                                    : "See the top concepts mentioned"}
                            </Text>
                        </Box>
                        <Box direction="row" justify="between" gap="large">
                            <Grid columns="360px" gap="medium">
                                {[
                                    ["General", miscConcepts],
                                    ["People", personConcepts],
                                    ["Organisations", orgConcepts],
                                    ["Places", locConcepts],
                                ].map(
                                    ([label, concepts]) =>
                                        concepts.length > 0 && (
                                            <Card key={label}>
                                                <CardHeader>
                                                    <Text
                                                        color="primaryLabel"
                                                        weight={700}
                                                        style={{
                                                            fontFamily:
                                                                "'Lato', sans-serif",
                                                        }}
                                                    >
                                                        {label}
                                                    </Text>
                                                </CardHeader>
                                                <CardBody>
                                                    <Box direction="row" wrap>
                                                        {concepts.map(
                                                            ({
                                                                id: conceptId,
                                                                name: conceptName,
                                                                source,
                                                            }) => (
                                                                <Pill
                                                                    key={`${label}/${conceptId}`}
                                                                    label={
                                                                        conceptId &&
                                                                        source ===
                                                                            "wikipedia"
                                                                            ? conceptId
                                                                            : conceptName
                                                                    }
                                                                    onClick={() => {
                                                                        history.push(
                                                                            `/${conceptSpaceId}/concepts/${encodeURIComponent(
                                                                                conceptId
                                                                            )}`
                                                                        );
                                                                    }}
                                                                />
                                                            )
                                                        )}
                                                    </Box>
                                                </CardBody>
                                                <CardFooter />
                                            </Card>
                                        )
                                )}
                            </Grid>
                            {size !== "small" && (
                                <Notes conceptSpaceId={conceptSpaceId} />
                            )}
                        </Box>
                    </Tab>
                </Tabs>
            </PageContent>
        </Page>
    );
}

export default ConceptSpace;
