import { getApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import React, {
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";

import { getQuota, getUsagePeriod } from "./subscription";

export const AuthContext = React.createContext();
export const useAuth = () => useContext(AuthContext);

export function AuthProvider({ children }) {
    const [authenticatedUser, setAuthenticatedUser] = useState(null);
    const [plan, setPlan] = useState();
    const [roles, setRoles] = useState();
    const [quota, setQuota] = useState();
    const [loading, setLoading] = useState(true);

    const auth = getAuth(getApp());

    const getClaims = useCallback(async (user, update) => {
        if (update) {
            await authenticatedUser.getIdToken(true);
        }
        user.getIdTokenResult().then(async (token) => {
            setRoles(token.claims.roles);
            setPlan(token.claims.stripeRole || null);
        });
    });

    useEffect(() => {
        return auth.onAuthStateChanged((user) => {
            if (loading) {
                setLoading(false);
            }
            setAuthenticatedUser(user);
        });
    }, []);

    useEffect(() => {
        if (authenticatedUser) {
            getClaims(authenticatedUser);
        }
    }, [authenticatedUser]);

    const context = useMemo(
        () => ({
            user: authenticatedUser,
            roles,
            plan,
            isAdmin: roles && roles.includes("admin"),
            loading,
            auth,
            getQuota: async () => {
                if (plan === undefined) {
                    return undefined;
                }
                if (quota) {
                    return quota;
                }
                const usagePeriod = await getUsagePeriod(authenticatedUser);

                // if (roles && roles.includes("admin")) {
                //     const adminQuota = {
                //         // projects: 100,
                //         // projectLifetimeMonths: 1,
                //         // wordsPerMonth: 2000,
                //         // documentsPerProject: 20,
                //         usagePeriod,
                //     };
                //     setQuota(adminQuota);
                //     return adminQuota;
                // }

                const planQuota = await getQuota(plan);
                const userQuota = {
                    ...planQuota,
                    usagePeriod,
                };
                setQuota(userQuota);
                return userQuota;
            },
            updateClaims: () => getClaims(authenticatedUser, true),
        }),
        [authenticatedUser, roles, plan, quota, loading, auth]
    );

    return (
        <AuthContext.Provider value={context}>{children}</AuthContext.Provider>
    );
}
